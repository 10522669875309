import React from 'react';
import styles from './PacksHomePage.module.scss';

import clsx from 'clsx';

import { NavControlService } from 'components/PrimaryNav';
import { ServerStore } from '../../utils/ServerStore';
import { useRemoteData } from '../../utils/useRemoteData';
import SimpleSpinner from '../../components/SimpleSpinner';
import history from 'utils/history';

import AddIcon from '@material-ui/icons/Add';
// import CardActions from '@material-ui/core/CardActions';
// import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import IconButton from '@material-ui/core/IconButton';

import PackHomeCard from './PackHomeCard';

import Button from '@material-ui/core/Button';
import { InputLabel, FormHelperText, ButtonBase } from '@material-ui/core';
import VerseProgressDialog from '../../components/VerseProgressDialog';
import { randomEncouragement, randomAffirmation } from 'utils/randomAffirmation';
import { ICONS } from '../../components/PrimaryNav';
import ContinuePracticeCard from './ContinuePracticeCard';
import VotdCard from './VotdCard';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';
import CircleProgress from 'components/CircleProgress';
import AddPackDialog from 'components/AddPackDialog';
import { numberWithCommas } from 'utils/numberWithCommas';

export default function PacksHomePage({ ...props }) {
	React.useEffect(() => {
		NavControlService.showPrimaryNav(true);
		NavControlService.setCurrentNav(NavControlService.NAV_PACKS);
	});

	React.useEffect(() => {
		// setStatusBarColor('#7951A9');
		setStatusBarColor('#000000'); // set to black because bar text is often white
	});

	const trainingShown = ServerStore.currentUser.trainingShown;
	if(!trainingShown) {
		ServerStore.GetTrainingPack().then(pack => {
			console.log("Got pack:", pack);
			history.push(`/pack/${pack.id}`);
		});
	}

	// Hackish hook to scroll to the top once data loads
	const scrollTop = data => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 1);
		return data;
	}
	
	const pointData = useRemoteData(() => ServerStore.GetUserPoints());
	const packs     = useRemoteData(() => ServerStore.GetUserPacks()); 
	const summary   = useRemoteData(() => ServerStore.GetSummary(new Date(), {
		includeMultiDayHighs:  true,
		includeMultiDayLows:   true,
		includeOldestTested:   true,
		includeLatestTested:   true,
		includeFavoriatePacks: true,
		includeStreak:         true,
		includeGoalProgress:   true,
		includeVotd:           true,
	}).then(scrollTop)); 

	const [ addPack, setAddPack ] = React.useState();
	const onAddPack = () => {
		setAddPack(true);
	}

	const [ selectedRow, setSelectedRow ] = React.useState();

	function OverflowWrap({ children }) {
		// This effect toggles the '.overflows' class on our question box.
		// By default, questions are `justify-content: center`
		// which works great for small verses - centers them vertically in the space.
		// However, long verses or small spaces would overflow, requiring scrolling.
		// Problem is, that when scrolling with 'center' alignment,
		// the flexbox never shows the start of the content, so content goes above start,
		// and you can't scroll up to it. So here, we just toggle a class in our SCSS
		// that if it's .overflows, then it sets `justify-content: flex-start` to fix that.
		const refWrap = React.useRef();
		const [ overflows, setOverflows ] = React.useState(true);
		React.useEffect(() => {
			const onResize = () => {
				if(!refWrap.current) {
					return;
				}
				const el = refWrap.current,
					overflows = el.scrollWidth > el.clientWidth;
				
				setOverflows(overflows);
			}
			// Run right away after render
			onResize();
			setTimeout(onResize, 1000); // make sure up-to-date

			// Run again if window resizes / device rotates / whatever
			window.addEventListener('resize', onResize);
			return () => window.removeEventListener('resize', onResize);
		});

		return <div ref={refWrap} className={clsx(
			styles.wrap,
			overflows && styles.overflows,
		)}>
			{children}
		</div>
	}


	const hours = new Date().getHours(),
		dayPart = hours < 12 ? 'morning' : 
				  hours < 18 ? 'afternoon' : 
				  'evening',
		name = ServerStore.currentUser.name,
		heys = ['Hey','Hi','Hello','Hey there'],
		// Use state so greeting doesn't change on re-render when loaded
		[ hey ] = React.useState(heys[Math.floor(Math.random() * heys.length)]),
		greeting = <>
			{hey} 
			{name ? ' ' + name.split(/\s/)[0] : ''},
			<br/>
			good {dayPart}!
		</>;
	
	const latestPacks = Array.from(summary.newTestedPacks || []),
		mostRecentPack = latestPacks[0],
		mrpCursor = (mostRecentPack || {}).cursor || {},
		goalProgress = summary.goalProgress || {};
		

	return (<>
		<AddPackDialog 
			open={addPack} 
			onCancel={() => setAddPack(false)}
		/>

		<VerseProgressDialog
			row={selectedRow}
			onCancel={() => setSelectedRow(null)}
		/>

		<div className={styles.root}>

			<div className={styles.greeting}>
				<span className={styles.text}>
					<Tooltip
						title={`Today: ${Math.ceil(goalProgress.timeDay)} minutes / ${Math.ceil(goalProgress.versesDay)} verses`}
					>
						<ButtonBase 
							className={styles.greetingCircles}
							onClick={() => {
								ServerStore.metric('app.home.greeting_circles.clicked');
								history.push('/progress');
							}}
						>
							<CircleProgress 
								value={Math.ceil(goalProgress.timeDayPercent || 0)}
								className={styles.timeCircle}
								size={20}
							/>
							<CircleProgress 
								value={Math.ceil(goalProgress.versesDayPercent || 0)}
								className={styles.verseCircle}
								size={28}
							/>
						</ButtonBase>
					</Tooltip>

					{greeting}
				</span>
				
				{/* {goalProgress && goalProgress.timeDayPercent ? <>
					<ButtonBase 
						className={styles.circles}
						onClick={() => history.push('/progress')}
					>
						<CircleProgress 
							value={goalProgress.timeDayPercent}
							className={styles.timeCircle}
							size={40}
						/>
						<CircleProgress 
							value={goalProgress.versesDayPercent}
							className={styles.verseCircle}
							size={50}
						/>

						<div className={styles.letter}>
							{ServerStore.currentUser.name.toUpperCase().substring(0,1)}
						</div>

						<div className={styles.date}>
							{new Date().toLocaleDateString()}
						</div>

						<div className={styles.data}>
							<div className={clsx(styles.val, styles.minutes)}>
								<div className={styles.num}>
									{Math.ceil(goalProgress.timeDay)}
								</div>
								<div className={styles.label}>
									minutes
								</div>
							</div>
							<div className={clsx(styles.val, styles.verses)}>
								<div className={styles.num}>
									{Math.ceil(goalProgress.versesDay)}
								</div>
								<div className={styles.label}>
									verses
								</div>
							</div>
						</div>
					</ButtonBase>
				</> : ""} */}

				<IconButton
					className={clsx(
						styles.settingsLink,
						// (InferenceDisplay.visible || hoursSinceLastLog > MIN_HOUR_SIZE) && styles.alertPresent,
					)}
					onClick={() => {
						ServerStore.metric('app.home.settings.clicked');
						history.push('/settings');
					}}
				>
					<ICONS.SettingsIcon/>
				</IconButton>
			</div>

			{pointData.loadDone && pointData.points > 0 ? <>
				<Tooltip
					title="Points are earned as you practice verses and progress in memorization levels"
				>
					<ButtonBase 
						className={styles.mainPoints}
						onClick={() => {
							ServerStore.metric('app.home.points.clicked');
							history.push('/progress');
						}}
					>
						<div className={styles.data}>
							<span className={styles.value}>
								{numberWithCommas(pointData.points)}
							</span>
						</div>
						<div className={styles.dataSubtext}>
							Points
						</div>
						<div className={styles.pointsUp}>
							(<span className={styles.points}>+{pointData.pointsUp}</span> today)
						</div>
					</ButtonBase>
				</Tooltip>
			
			</> : ""}

			{summary.streak ? 
				<div className={styles.streak}>
					{summary.streak > 1 ?
						<>You've memorized verses <b>{summary.streak} days in a row</b>! {randomAffirmation()}</>
						:
						<>First day of the your new streak! {randomEncouragement()}</>
					}
				</div>
			: ""}
			
			{!summary || !summary.date ?
				<SimpleSpinner fullpage={true}/>
			:  <>

				{mrpCursor.progressPercent ? <>
					<InputLabel>Continue where you left off</InputLabel>
					<OverflowWrap>
						<ContinuePracticeCard
							pack={mostRecentPack}
						/>
					</OverflowWrap>
				</> : ""}

				{summary.votd && summary.votd.cachedRef ? <>
					<InputLabel>Verse of the Day</InputLabel>
					<OverflowWrap>
						<VotdCard
							verse={summary.votd}
						/>
					</OverflowWrap>
				</> : ""}
			
				{summary.favoriatePacks && summary.favoriatePacks.length > 1 ? <>
					<InputLabel>Favoriate Packs</InputLabel>
					<FormHelperText>Packs you've practiced the most over the past {summary.args.multiDayHighDays} days</FormHelperText>
					<OverflowWrap>
						{Array.from(summary.favoriatePacks || []).map(pack => <PackHomeCard
							key={pack.id}
							metricKey='favoriate_packs'
							pack={pack}
							className={styles.item}
							badgeText={pack.tests && `${pack.tests}×`}
						/>)}
					</OverflowWrap>
				</>:""}

				{summary.newTestedPacks && summary.newTestedPacks.length ? <>
					<InputLabel>Recently Practiced</InputLabel>
					<OverflowWrap>
						{latestPacks.map(pack => <PackHomeCard
							key={pack.id}
							metricKey='recents'
							pack={pack}
							className={styles.item}
						/>)}
					</OverflowWrap>
				</>:""}

				{summary.oldTestedPacks && summary.oldTestedPacks.length ? <>
					<InputLabel>Dive Back In</InputLabel>
					<FormHelperText>Packs you might have missed recently</FormHelperText>
					<OverflowWrap>
						{Array.from(summary.oldTestedPacks || []).map(pack => <PackHomeCard
							key={pack.id}
							metricKey='dive_back_in'
							pack={pack}
							className={styles.item}
						/>)}
					</OverflowWrap>
				</>:""}

				{summary.multiDayHighs && summary.multiDayHighs.length ? <>
					<InputLabel>Top Verses</InputLabel>
					{packs && packs.length > 1 ?
						<FormHelperText>Verses you've practiced the most over the past {summary.args.multiDayHighDays} days</FormHelperText>
					: ""}
					<OverflowWrap>
						{Array.from(summary.multiDayHighs || []).slice(0,8).map(pack => <PackHomeCard
							key={pack.verse.id}
							metricKey='top_verses'
							verse={pack.verse}
							className={styles.item}
							onVerseClick={() => setSelectedRow(pack)}
							badgeText={pack.tests && `${pack.tests}×`}
						/>)}
					</OverflowWrap>
				</>:""}
				
				{packs ?
					(packs.length > 1 ? <>
						<InputLabel>All Packs</InputLabel>
						<OverflowWrap>
							{Array.from(packs || []).map(pack => <PackHomeCard
								key={pack.id}
								metricKey='all_packs'
								pack={pack}
								className={styles.item}
							/>)}
						</OverflowWrap>
					</> :
					<>
						<div className={styles.emptyCta}>
							Find more packs of verses to memorize by searching or create your own pack!

							<div className={styles.ctaButtons}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										ServerStore.metric('app.home.empty_cta.search_clicked');
										history.push('/search');
									}}
								>
									<ICONS.SearchIcon style={{fontSize:'1rem'}}/>
									Search
								</Button>

								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										ServerStore.metric('app.home.empty_cta.add_pack_clicked');
										onAddPack();
									}} 
								>
									<AddIcon style={{fontSize:'1rem'}}/>
									Create
								</Button>
							</div>
						</div>
					</>
				) : ""}
			</>}

			{packs && packs.length > 1 ?
				<Zoom
					in={true}
					timeout={{
						enter: 300,
						exit: 300
					}}
					unmountOnExit
				>
					<Tooltip title="Create Pack">
						<Fab 
							size="medium" 
							onClick={() => {
								ServerStore.metric('app.home.fab.add_pack_clicked');
								onAddPack();
							}} 
							className={styles.newPackBtn}
						>
							<AddIcon/>
						</Fab>
					</Tooltip>
				</Zoom>
			: ""}
		</div>
	</>);
}
