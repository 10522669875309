import {
	ServerStore,
	APP_PAUSED_EVENT,
	APP_RESUMED_EVENT
} from 'utils/ServerStore';

import { GpsLogger } from './GpsLogger';
import { PushNotifyService } from './PushNotifyService';

import React from 'react';
import { pincodePrompt } from './PinCodePrompt';
import { DeferLock, defer } from './defer';
import { promiseMap } from './promise-map';
import { NavControlService } from '../components/PrimaryNav';
import history from './history';


export const MIN_SPINNER_MS = 333/2;

// Util to promise to sleep for `time` ms
export const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time));

/** 
 * Utility to only fire `pendingCb` if `asyncFn` takes longer than `timeout` ms
 * Calls `pendingCb(true)` when `asyncFn` time exceeds `timeout`.
 * Always calls `pendingCb(false)` after `asyncFn` completes, regardless of if `timeout` exceeded  
 * @returns result from `await asyncFn()`
 */
export const asyncPending = async (pendingCb, asyncFn, timeout=MIN_SPINNER_MS) => {
	// Only exec pendingCb if longer than timeout milliseconds
	const tid = setTimeout(() => pendingCb(true), timeout);
	
	const result = await asyncFn();
	
	clearTimeout(tid);
	pendingCb(false);

	return result;
} 

export class MyVersesCoreService {
	static callbacks = new Map();

	static unlockBoot() {
		if (this.bootLock)
			this.bootLock.resolve();
		this.bootUnlocked = true;
	}

	static async boot() {
		if(!this.bootUnlocked) {
			// bootLock is resolved on first render in a useEffect hook inside App.js
			this.bootLock = defer();
			await this.bootLock;
			this.bootUnlocked = true;
		}

		// This event is triggered via push notification, sent from server
		// in the `user` model in `sendLogReminder`
		// Triggered by URIs that look like:
		// 		#myverses:eyJldmVudCI6ImFsZXJ0LnJlbWluZGVyRXZlbnQifQ%3D%3D
		// When clicked in the background, the URL in the address bar to trigger this is:
		//		/#/alert:#myverses:eyJldmVudCI6ImFsZXJ0LnJlbWluZGVyRXZlbnQifQ%3D%3D
		ServerStore.on('alert.reminderEvent', () => {
			// This will work even if app not all the way loaded yet
			NavControlService.setCurrentNav(NavControlService.NAV_PACKS, true);
			ServerStore.metric('app.myverses.reminderEventClicked');
		})

		// Check for version updates, etc
		ServerStore.internalBoot();

		// Attempt to authenticate if token stored right away
		// But don't create new user if not authenticated already
		// Wait to auth so when we enable background click, we have stuff ready
		const auth = await ServerStore.attemptAutoLogin().catch(ex => {
			console.warn("Exception received trying to login from Core Service: ", ex);
		});

		if(!auth &&
			// If not logged in, regardless of PROD or devel,
			// our alert click would not get handled, so make sure login
			// then let the login handle
			PushNotifyService.hashIsAlert(window.location.hash)) {

			history.preLoginUrl = window.location.hash.replace(/^#/,'');
			console.log("Not logged in, detected alert pending, sending to login with redirect after to ", history.preLoginUrl);
			
			history.push('/login');
		} else {
			// In PROD, our FirebasePushPlugin handles the window hash,
			// but in devel, we just simulate it here
			if(PushNotifyService.hashIsAlert(window.location.hash) &&
				process.env.NODE_ENV !== 'production'
			) {
				console.log("[devel] Found alert in hash:", window.location.hash);
				PushNotifyService.instance.handleAlertHash(window.location.hash);
			}
		}
		
		// Setup internal Map. We use a Map so we can use Functions as keys
		this.callbacks = new Map();

		// Setup permission request callbacks
		// Updated to do this manually in HomePage
		// FIXME until onboarding done in homepage, allowing this here
		// this.allowPermissionRequests();
		
		// Indicate that we are online and can process any pending notification
		// clicks that happend while app was closed.
		PushNotifyService.instance.enableBackgroundClickAcceptance();

		// Normalize pause/resume events (e.g. from browser PageVisibility API and cordova pause/resume events)
		// We'll normalize a metric here into ServerStore and use ServerStore to emit an appros event so 
		// KittyFlySleep can pause things if needed.
		// We'll also auto-pause/resume matter and PIXI ticker here
		// Delay with setTimeout so listeners can be setup for APP_PAUSED_EVENT if fired from here immediately on setup
		setTimeout(() => {
			this.setupPauseResumeDetection();
		}, 100);

		this.setupOpenWith();
	}

	static setupOpenWith() {

		const setup = () => {

			if(!window.isPhoneGap) {
				return;
			}

			const cordova = window.cordova;
			if(!cordova) {
				console.warn("No cordova on window");
			}

			if(!cordova.openwith) {
				console.warn("No .openwith plugin on cordova, cannot setup that plugin");
				return;
			}

			// Increase verbosity if you need more logs
			// cordova.openwith.setVerbosity(cordova.openwith.DEBUG);
			
			
			function initSuccess()  { console.log('init success!'); }
			function initError(err) { console.log('init failed: ' + err); }
			
			// Initialize the plugin
			cordova.openwith.init(initSuccess, initError);
			
			async function myHandler(intent) {
				console.log('OpenWith intent received');
			
				console.log('  action: ' + intent.action); // type of action requested by the user
				console.log('  exit: ' + intent.exit); // if true, you should exit the app after processing

				console.log('intent:', intent);
				console.log('intent.items:', intent.items);
			
				// items[0] is:
				// data: "At that time Herod the tetrarch heard about the fame of Jesus,↵Matthew 14:1 ESV↵https://bible.com/bible/59/mat.14.1.ESV"
				// type: "text/plain"
				// post it to server for processing

				if(intent.items && intent.items[0]) {
					const { data /*, type*/ } = intent.items[0];
					
					MyVersesCoreService.processUserSharedText(data);
				}

				// for (var i = 0; i < intent.items.length; ++i) {
				// 	var item = intent.items[i];
				// 	if(item) {
				// 		console.log('  type: ', item.type);   // mime type
				// 		console.log('  uri:  ', item.uri);     // uri to the file, probably NOT a web uri
					
				// 		// some optional additional info
				// 		console.log('  text: ', item.text);   // text to share alongside the item, iOS only
				// 		console.log('  name: ', item.name);   // suggested name of the image, iOS 11+ only
				// 		console.log('  utis: ', item.utis);
				// 		console.log('  path: ', item.path);   // path on the device, generally undefined
				// 	}
				// }
			
				// // ...
				// // Here, you probably want to do something useful with the data
				// // ...
				// // An example...
			
				// if (intent.items.length > 0 && intent.items[0]) {
				// 	cordova.openwith.load(intent.items[0], function(data, item) {
				
				// 		// data is a long base64 string with the content of the file
				// 		console.log("the item weights " + data.length + " bytes");
				// 		// uploadToServer(item);
				// 		console.log("actual data:" + JSON.stringify({item, data}));
				
				// 		// "exit" when done.
				// 		// Note that there is no need to wait for the upload to finish,
				// 		// the app can continue while in background.
				// 		if (intent.exit) { cordova.openwith.exit(); }
				// 	});
				// }
				// else {
					if (intent.exit) { cordova.openwith.exit(); }
				// }
			}

			// Define your file handler
			cordova.openwith.addHandler(myHandler);
			
		};

		if(!window.isPhoneGap) {
			// console.log("Not native app, not setting up openWith");
		} else {
			if(window._pgDeviceReady) {
				setup();
			} else {
				document.addEventListener('deviceready', setup);
			}
		}
	}

	static async processUserSharedText(data, type) {
		// TODO: check type for valid types

		console.warn("Processing incoming shared data:", data);

		ServerStore.metric("app.user_shared_text.received", null, {
			data
		});

		const cb = MyVersesCoreService.onVerseShareReceived;
		if(cb) {
			cb(null, true);
		}

		const result = await ServerStore.ProcessUserSharedText(data);
		if(result.error) {
			if(cb) {
				cb(null, false);
			}
			console.error("Received error processing from server:", result);
			window.AlertManager.fire({
				type: 'error',
				title: 'Can\'t Handle Data',
				text:  result.error
			});

			ServerStore.metric("app.user_shared_text.error", null, {
				error: result.error
			});
			return;
		}

		if(cb) {
			ServerStore.metric("app.user_shared_text.success", null, {
				result
			});
			
			// Go home. Why? Because if they click practice and they are ALREADY
			// on any pack page, the singlepracticepack thing wont work
			history.push('/home');

			console.log("Received successful processing from server:", result);
			cb(result);
		}
	}

	static async showWhatsNew() {
		// const ver = await ServerStore.appVersion();
		// if(ver.needsUpdated) {
		// 	console.log("[showWhatsNew] app needs updated, not showing any info popup");
		// 	return;
		// }

		// const VER_CACHE_KEY = '@myverses/whatsNew-verShown',
		// 	lastShownFor = window.localStorage.getItem(VER_CACHE_KEY);

		// if(lastShownFor === ver.runningVer) {
		// 	// console.log("[showWhatsNew] user already has seen whats new for ver ", ver.runningVer);
		// 	return;
		// }

		// window.localStorage.setItem(VER_CACHE_KEY, ver.runningVer);

		// // Only show this popup for commit 4dcc6b5
		// if(ver.runningVer === "4dcc6b5") {
		// 	window.Alert({
		// 		showCloseButton: true,
		// 		type: 'success',
		// 		title: '🎉 Two New Features 🎉',
		// 		html: <>
		// 			<p><b>1.</b> MyVerses now sends text messages to your contacts when you start/stop a timer. You can turn that off in the 'Settings' area if you don't want those texts sent.</p>
		// 			<p><b>2.</b> MyVerses now sends both texts <b>and telephone calls</b> to your emergency contacts when an SOS is triggered. (Only texts are sent when you start/stop a timer.) You can change what type of notification (voice, text, or both) your contacts get in Settings. They get <i>both voice and text</i> by default.</p>
		// 		</>,
		// 	});
		// }
	}



	static async setupPauseResumeDetection() {
		// const defaultDocumentTitle = 'MyVerses';

		// When the app pauses, set the title.
		// This shows the paused
		const pauseApp = () => {
			// Notify the rest of the app
			ServerStore.emit(APP_PAUSED_EVENT)

			// Simple paused indicator
			// document.title = '[Paused] ' + defaultDocumentTitle;

			// Post normalized metric
			ServerStore.metric("app.paused");
			
			// Dump any pending metrics to server
			// Note: This will use keepalive:true on fetch to ensure delivery
			ServerStore.postMetrics(true);
		};
			
		// When the app resumes, set the title.
		const resumeApp = () => {
			// Post normalized metric
			ServerStore.metric("app.resumed");
		
			// Reset document title for browsers
			// document.title = defaultDocumentTitle;
			
			// Check for updates that were published while app was in background
			ServerStore.checkForUpdates();

			// Notify the rest of the app
			ServerStore.emit(APP_RESUMED_EVENT);
		};

		window.addEventListener("unload", function (e) {
			
			// Notify the rest of the app
			// just incase anybody is listening that needs to dump metrics
			// (e.g. KittyFlySleep -> KittyActor)
			ServerStore.emit(APP_PAUSED_EVENT)

			// Log metric
			ServerStore.metric("app.exited");

			// Dump any pending metrics to server
			// Note: This will use keepalive:true on fetch to ensure delivery
			// Per https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch:
			// 		The keepalive option can be used to allow the request to outlive the page. 
			// 		Fetch with the keepalive flag is a replacement for the Navigator.sendBeacon() API. 
			ServerStore.postMetrics(true);
			
		}, false);
		
		// The pause/resume events only apply to PhoneGap (e.g. only native apps, not the webpage)
		if(window.isPhoneGap) {
			document.addEventListener('pause', this._pgPaused = async () => {
				pauseApp();
			});
			document.addEventListener('resume', this._pgResume = () => {
				resumeApp();
			});
		} else {
			// Most of the feature-specific code below copied from https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#Example
			// Set the name of the hidden property and the change event for visibility
			let hidden, visibilityChange; 
			if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
				hidden = "hidden";
				visibilityChange = "visibilitychange";
			} else if (typeof document.msHidden !== "undefined") {
				hidden = "msHidden";
				visibilityChange = "msvisibilitychange";
			} else if (typeof document.webkitHidden !== "undefined") {
				hidden = "webkitHidden";
				visibilityChange = "webkitvisibilitychange";
			}
			

			// If the page is hidden, pause the video;
			// if the page is shown, play the video
			function handleVisibilityChange() {
				if (document[hidden]) {
					pauseApp();
				} else {
					resumeApp();
				}
			}

			// Warn if the browser doesn't support addEventListener or the Page Visibility API
			if (typeof document.addEventListener === "undefined" || hidden === undefined) {
				// console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
				// console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
				console.warn("Page Visibility API not supported on this browser.");
			} else {
				// Handle page visibility change   
				document.addEventListener(visibilityChange, handleVisibilityChange, false);
				
				// detect initial visibility (e.g. loaded in background)
				handleVisibilityChange();
			}

			// Intercept blur/focus events to ensure complete coverage
			if(process.env.NODE_ENV === 'production') {
				// We use console a lot during development and we still want the app to run
				// while in the console, but clicking console blurs, so we only run these
				// listeners during production.
				window.addEventListener('focus', resumeApp)
				window.addEventListener('blur',  pauseApp);
			}
		}
	}

	static allowPermissionRequests() {
		if (this.allowPermissionRequests.setupDone)
			return;

		this.allowPermissionRequests.setupDone = true;

		// Use a shared guard to only show one permission request at a time, below.
		const guard = new DeferLock();

		// This is triggered the first time our app boots to inform the user as to why we want to use
		// their location so they can make an informed decision, rather than their OS / browser just throwing
		// a "Allow location?" dialog at them. 
		GpsLogger.instance.ifPermissionNeeded(async () => {
			// Wait for the guard to unlock if already locked
			await guard.lock();

			// Give the user a second...
			await new Promise(resolve => setTimeout(() => resolve(), 2500));

			// Now show the UI
			ServerStore.metric("app.gps_logger.perm_request_game_ui.requested");
			if((await window.AlertManager.fire({
				type:  'question',
				title: 'Your Location is Important',
				html:  (<>
					<p>We need to use your location in order to send it to your emergency contacts when you're in danger. We also use your location to prompt you if it looks like you might be in danger.</p>
					<p>If you click Allow below, you'll be prompted by your device to grant MyVerses location access. On some devices, you may need to choose "Allow All the Time" or a similar option.</p>
					<p>We promise we don't do anything with your location data other than try to keep you safe. No one sees it other than your emergency contacts, and then ONLY if they get an SOS from you.</p>
				</>),
				showCancelButton:  true,
				confirmButtonText: `Allow GPS`,
				cancelButtonText:  `Don't allow`,
				// reverseButtons:    true
			})).value) {
				guard.unlock();
				ServerStore.metric("app.gps_logger.perm_request_game_ui.success");
				return true;
			} else {
				ServerStore.metric("app.gps_logger.perm_request_game_ui.rejected");
				guard.unlock();
				return false;
			}
		});

		// This is trigered when one of our push plugins needs to request permission, 
		// in order to inform the user why we want to notify them.
		// On some platforms, such as android, this is not used/needed. Presently,
		// used on the web and on iOS
		PushNotifyService.instance.ifPermissionNeeded(async () => {

			// Wait for any logging by GPS to finish before we prompt for permission
			await guard.lock();

			// Defer another few seconds
			// await new Promise(resolve => setTimeout(() => resolve(), 2500));

			// Now run the UI interaction
			ServerStore.metric("app.push_service.perm_request_game_ui.requested");
			
			if((await window.AlertManager.fire({
				type:  'question',
				// title: 'MyVerses',
				text:  `When it's time to make a log entry, we want to send you a push notification. Is that okay?`,
				showCancelButton:  true,
				confirmButtonText: `Allow Notifications`,
				cancelButtonText:  'No thanks',
				// reverseButtons:    true
			})).value) {
				guard.unlock();
				ServerStore.metric("app.push_service.perm_request_game_ui.success");
				return true;
			} else {
				guard.unlock();
				ServerStore.metric("app.push_service.perm_request_game_ui.rejected");
				return false;
			}
		});
	}

	/**
	 * React hook for external consumers to use like this:
	 * 
	 * const flag = MyVersesCoreService.usePendingFlag(MyVersesCoreService.clearSOS);
	 * 
	 * And later: 
	 * <button onClick={MyVersesCoreService.clearSOS}>
	 * 	{flag? "Waiting for SOS to clear...": "Clear SOS"}
	 * </button>
	 * 
	 * @param {Function} callbackType - must be a valid function from MyVersesCoreService 
	 */
	static usePendingFlag(callbackType) {
		if(!callbackType)
			throw new Error("Invalid callback type");

		const [ pending, setPending ] = React.useState(false);

		this.useServiceCallback(callbackType, setPending);

		return pending;
	}

	/**
	 * React hook to execute a callback when something internal in MyVersesCoreService
	 * triggers a callback, such as `restartTimerHook`.
	 * 
	 * Also sed internally by usePendingFlag() hook, but can also be used externally
	 * if you need more complex "pending" logic.
	 * 
	 * @param {Function} callbackType - must be a valid function from MyVersesCoreService 
	 * @param {Function} callback - your own callback to execute when MyVersesCoreService needs it
	 * 
	 */
	static useServiceCallback(callbackType, callback) {
		if(!callbackType)
			throw new Error("Invalid callback type");

		return React.useEffect(() => {
			this.setCallback(callbackType, callback);

			return () => this.setCallback(callbackType, null);
		});
	}

	/**
	 * Set/delete the callback to use for `callbackType`. Only one callback per `callbackType`
	 * is ever saved, and repeated calls will overwrite previous callbacks. Calling with a 
	 * `null` `cb` arg will delete the callback (unregister it).
	 * 
	 * You really should consider using `useServiceCallback` if using this in React,
	 * which uses `React.useEffect` internally to call setCallback(..., cb) on
	 * render completion, and properly call setCallback(..., null) when the component unmounts.
	 * 
	 * @param {Function} callbackType - must be a valid function from MyVersesCoreService 
	 * @param {Function} cb - your own callback to execute when MyVersesCoreService needs it
	 */
	static setCallback(callbackType, cb) {
		if(!callbackType)
			throw new Error("Invalid callback type")
		
		if(!cb)
			return this.callbacks.delete(callbackType);

		this.callbacks.set(callbackType, cb);
	}

	/**
	 * Note: Guaranteed to always return a function.
	 * Therefore, we don't have to check if a callback is set when using the return value, we can just use it.
	 * 
	 * @param {Function} callbackType - must be a valid function from MyVersesCoreService 
	 */
	static getCallback(callbackType) {
		const cb = this.callbacks.get(callbackType);
		if(!cb)
			return () => {};
		return cb;
	}

	/**
	 * Simple utility to call asyncPending with one of the 
	 * registered "callbacks" if registered.
	 * This just saves repeating calls to "getCallback" followed by "asyncPending"
	 * in all the functions below.
	 * 
	 * @return Returns the value of `await asyncFn()`
	 * 
	 * @param {Function} callbackType - must be a valid function from MyVersesCoreService
	 * @param {Function} asyncFn - awaitable function  
	 */
	// 
	static async execPending(callbackType, asyncFn) {
		return asyncPending(this.getCallback(callbackType), asyncFn)
	}

	static addVerseToPack = async (verse, pack) => {

		let splitRange = false;
		if(verse.isRange) {
			// const { cancel, split } = await AskUserToSplit.show(verse);
			// if(cancel) {
			// 	return;
			// }

			const text = `You've selected ${verse.cachedRef}, which consists of multiple verses in sequence. Do you want to add it all at once as a single card, or do you want to add each verse in this range individually to the pack? (Click OK to add individually, CANCEL to add as a single card.)`;

			const split = window.confirm(text);
			splitRange = split ? true : false;
		}

		const res = await this.execPending(this.addVerseToPack,
			() => ServerStore.AddPackVerse({
				packId: pack.id,
				splitRange,
				...(verse.isRange ? {
					verseId:    verse.verse.startId,
					endVerseId: verse.verse.endId,
				} : {
					verseId: verse.id,
				})
			})
		);

		if(!res.id) {
			alert("Error adding verse, sorry, try again");
			return;
		}

		return res;
	}

	static createTimer = async timerDraft => {
		if(!(timerDraft.hours || timerDraft.minutes)) {
			window.Alert({
				type: 'warning',
				title: 'Time required',
				text: 'Please specify a time to start the timer',
			});

			return null;
		}

		// Ask for GPS when timer is started for the first time
		GpsLogger.instance.start();

		// Create timer on server
		const timerData = await this.execPending(this.createTimer,
			() => ServerStore.StartTimer(timerDraft)
		);

		if(!timerData || timerData.error) {			
			window.Alert({
				type: 'warning',
				title: 'Trouble Starting Timer',
				text: timerData.error || "No response from server",
			});

			return null;
		} else {
			console.log("[MyVersesCoreService.createTimer] timerData from server:", timerData);
		}

		return timerData;
	}

	/**
	 * Add time to the active timer.
	 * Wrapper for `ServerStore.AddTimeToTimer` with pin code prompt and pending flag callback handling
	 */
	static addTimeToTimer = async () => {

		const sosPasscode = await pincodePrompt('add 5 minutes');
		if(!sosPasscode)
			return;
		
		const result = await this.execPending(this.addTimeToTimer,
			() => ServerStore.AddTimeToTimer({ sosPasscode, minutes: 5 }));

		if(sosPasscode && !result.modified) {
			window.Alert({
				type: 'error',
				title: 'Invalid Safety PIN',
				text: 'Sorry, that Safety PIN was incorrect. Timer was not updated.',
			})
		} else
		// Only cancel if passcode - if we got here, server confirmed passcode valid
		if(sosPasscode) {
			this.restartTimerHook(result);
		}
	}

	/**
	 * Calls the `restartTimerHook` callback with `data`.
	 * This is called when `MyVersesCoreService.addTimeToTimer` completes successfully
	 * with the results from the server of the `AddTimeToTimer` call, containing the updated
	 * `expireDate`
	 * 
	 * @param {Timer} data  
	 */
	static async restartTimerHook(data) {
		this.getCallback(this.restartTimerHook)(data);
	}

	/**
	 * Cancels the active timer.
	 * Wrapper for `ServerStore.CancelActiveTimer` with pin code prompt and pending flag callback handling
	 */
	static cancelTimer = async () => {
		const sosPasscode = await pincodePrompt('cancel the Timer');
		if(!sosPasscode)
			return;

		const result = await this.execPending(this.cancelTimer,
			() => ServerStore.CancelActiveTimer(sosPasscode).catch(error => error));

		console.log("[cancelTimer] result=", result, typeof(result), result instanceof Error, result.message);

		if(result instanceof Error) {
			window.Alert({
				type: 'error',
				title: 'Server Error',
				text:  result.message,
			})
		} else
		if(result) {
			if(result.invalidPin) {
				window.Alert({
					type: 'error',
					title: 'Invalid PIN Code',
					text: 'Sorry, that PIN Code was incorrect. Timer was not canceled.',
				})
			} else {
				if(result.noActiveTimer) {
					console.warn("No active timer was on the server, pretending we canceled anyway");
				}

				// Stop GPS tracking if timer stopped
				GpsLogger.instance.stop();

				this.stopTimerHook();
			}
		} else {
			await window.Alert({
				type: 'error',
				title: 'No Response from Server',
				text:  "Sorry, the server didn't say anything, I'll try reloading the app automatically to fix it.",
			});
			window.location.reload();
		}
	}

	/**
	 * Calls any `stopTimerHook` callbacks when the server `CancelActiveTimer` call completes successfully.
	 */
	static stopTimerHook() {
		this.getCallback(this.stopTimerHook)();
	}

	/**
	 * Wrapper for `ServerStore.TriggerSOS` which calls appropriate callbacks on our service when pending
	 */
	static sendSOS = async () => {
		return this.execPending(this.sendSOS, async () => {
			// Start GPS tracking
			// 'await' means .start won't return until the first location (or error) is logged on the server
			// Does nothing if GPS already started
			await GpsLogger.instance.start();
			// Now that the server has our users GPS location, send the SOS
			ServerStore.TriggerSOS({ isManual: true })
		});
	}

	/**
	 * Wrapper for `ServerStore.ClearSOS` with pin code prompt and pending flag callback handling
	 */
	static clearSOS = async () => {
		const sosPasscode = await pincodePrompt('clear the SOS');
		if(!sosPasscode)
			return;
		
		const result = await this.execPending(this.clearSOS, 
			() => ServerStore.ClearSOS(sosPasscode).catch(error => error));
		
		// if(!result.cleared && sosPasscode) {
		// 	window.Alert({
		// 		type: 'error',
		// 		title: 'Invalid PIN Code',
		// 		text: 'Sorry, that PIN Code was incorrect. SOS was not cleared.',
		// 	})
		// } else
		// if(sosPasscode) {
		// 	// Stop GPS tracking if SOS cleared
		// 	GpsLogger.instance.stop();
		// }

		if(result instanceof Error) {
			window.Alert({
				type: 'error',
				title: 'Server Error',
				text:  result.message,
			})
		} else
		if(result) {
			if(result.invalidPin) {
				window.Alert({
					type: 'error',
					title: 'Invalid PIN Code',
					text: 'Sorry, that PIN Code was incorrect. SOS was not canceled.',
				})
			} else {
				if(result.noActiveSOS) {
					console.warn("No active timer was on the server, pretending we canceled anyway");
				}

				// Stop GPS tracking if timer stopped
				GpsLogger.instance.stop();
			}
		} else {
			await window.Alert({
				type: 'error',
				title: 'No Response from Server',
				text:  "Sorry, the server didn't say anything, I'll try reloading the app automatically to fix it.",
			});
			window.location.reload();
		}
	}

	static startFlashingSOS = () => {
		if(!window.isPhoneGap) {
			return console.warn("[startFlashingSOS] cannot flash sos, not phonegap");
		}
		if(!window.plugins || !window.plugins.flashlight) {
			return console.warn("[flashSOS] no window.plugins.flashlight, can't run flashing sequence");
		}

		// morse code for SOS
		// 0 = dot
		// 1 = dash
		// 2 = break 
		const morseCodeSOS = [0,0,0, 2, 1,1,1, 2, 0,0,0, 2];

		// Process a single bit of morse code (0, 1, or 2)
		// and flash or wait accordingly
		// Note: dash = 3 dots, ref https://en.wikipedia.org/wiki/Morse_code
		const processBit = async x => {
			const dot = 150;
			if(x===2) { return await sleep(dot) } // break between letters
			window.plugins.flashlight.switchOn();
			await sleep(x ? dot * 3  : dot); // dash = 3 * dot
			window.plugins.flashlight.switchOff();  
			await sleep(dot); // break between bits 
		};

		// Process the morseCodeSOS array a single time
		const loop = () => promiseMap(morseCodeSOS, processBit);

		// Repeatedly execute the morseCodeSOS array every 3.33 seconds
		this._sosIntervalTid = setInterval(loop, 3333);
	}

	static stopFlashingSOS = () => {
		clearInterval(this._sosIntervalTid);
	}

	// Simple hook to start/stop flashing SOS when component using it renders or unmounts
	static useFlashingSOS = () => {
		React.useEffect(() => {
			MyVersesCoreService.startFlashingSOS();
			return () => MyVersesCoreService.stopFlashingSOS();
		});
	}
}

window.MyVersesCoreService = MyVersesCoreService;

// just for debugging
function wrapPushAction(pushActionName, fn) {
	window.pushActions[pushActionName] = () => {
		console.log(`[${pushActionName}] triggered push action ${pushActionName}`);
		fn();
	}
}

// Used by NativePushPlugin
window.popuplatePushActions = () => {
	wrapPushAction('clearTimer', MyVersesCoreService.clearTimer);
	wrapPushAction('clearSOS',   MyVersesCoreService.clearSOS);
	wrapPushAction('sendSOS',    MyVersesCoreService.sendSOS);
};