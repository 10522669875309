import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const closeStyles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
		position: 'relative'
	},
	h6: {
		paddingLeft: '8px',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitleWithClose = withStyles(closeStyles)(props => {
	const { children, classes, onClose, className, ...other } = props;
	return (
		<DialogTitle disableTypography className={clsx(classes.root, className)} {...other}>
			<Typography variant="h6" className={classes.h6}>{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
});

export default DialogTitleWithClose;