import React from 'react';
import clsx from 'clsx';

import styles from './ChoosePackDialog.module.scss';

import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import { ServerStore } from 'utils/ServerStore';
import { defer } from 'utils/defer';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import AddPackDialog from 'components/AddPackDialog';

import { 
	Input,
	List,
	ListItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	CircularProgress
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

class PackQuery {
	static setLoadingCallback(cb) {
		this.loadingCallback = cb;
	}

	static stop() {
		this.stopped = true;
		if (this.loadingCallback) {
			this.loadingCallback(false);
		}

	}

	static async _search() {
		const query = {
			search: this.searchString,
			onlyUserEditablePacks: true
		};

		const list = await ServerStore.SearchPacks(query);

		// console.log("Serach got list: ", list);

		if(this.searchString !== query.search) {
			// console.warn("Search changed while waiting for results, not resolving");
			return;
		}

		if(this.stopped) {
			// console.warn("Stopped while waiting for results, not resolving");
			return;
		}
		
		if (this.loadingCallback) {
			this.loadingCallback(false);
		}

		// console.log("For query ", this.searchString, ", got:", list);

		if (this.searchPromise) {
			this.searchPromise.resolve(list);
			this.searchPromise = null;
		}

	}

	static async search(string="") {
		if (this.loadingCallback) {
			this.loadingCallback(true);
		}

		this.stopped = false;
		this.searchString = string;

		if(!this.searchPromise) {
			this.searchPromise = defer();
		}

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._search();
		}, this.delay || 500);

		return this.searchPromise;
	}

	static async reload() {
		this.search(this.searchString);
	}
}


export default function ChoosePackDialog({
	open,
	onCancel,
	onSave,
}) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const [ results, setResults ] = React.useState([]);
	const [ loading, setLoading ] = React.useState(false);
	const [ activeRow, setActiveRow ] = React.useState(null);
	const listRef = React.useRef();

	const onCloseDialog = () => {
		PackQuery.searchString = '';
		if (onCancel) {
			onCancel();
		}
	}

	const choosePack = pack => {
		PackQuery.searchString = '';
		if (onSave) {
			onSave(pack);
		}
	}

	const [ addPack, setAddPack ] = React.useState();
	const onAddPack = () => {
		setAddPack(true);
	}

	const newPackAdded = pack => {
		PackQuery.reload();
		choosePack(pack);
	}


	if(open) {
		PackQuery.setLoadingCallback(setLoading);
	}

	const gotResults = (results, forceFirstRow) => {
		// Display results with changes
		setResults(results);
		if(!results) {
			setActiveRow(null);
			return;
		}

		// Enter pressed, so select first result
		if (PackQuery.saveOnLoad) {
			// clear flag for next usage
			PackQuery.saveOnLoad = false;
		
			if(results[0]) {
				choosePack(results[0]);
				return;
			}
		}

		if(forceFirstRow && results[0]) {
			setActiveRow(results[0]);
			return;
		}

		const { book, chapter, pack } = activeRow || {}; 
		if(book && chapter && pack) {
			const row = results.find(row => {
				return (
					row.pack === pack &&
					row.chapter === chapter &&
					row.book.id === book.id
				);
			});
			if(row) {
				setActiveRow(row);
			}
		} else {
			setActiveRow(results[0])
		}
	}

	
	const search = async (text) => {
		// if(!text) {
		// 	PackQuery.searchString = "";
		// 	setResults([]);
		// 	return;
		// }

		if(text && text === PackQuery.searchString) {
			return;
		}

		const res = await PackQuery.search(text);
		gotResults(res, true);
	}


	const [ isFirstLoad, setIsFirstLoad ] = React.useState(true);
	if(isFirstLoad) {
		setIsFirstLoad(false);
		search("");
	}

	const keyPressed = ev => {
		const KEY_DOWN = 40,
			KEY_UP = 38,
			KEY_ENTER = 13,
			key = ev.which;
		
		if(key === KEY_ENTER) {
			if(loading) {
				// Will be reset in gotResults()
				PackQuery.saveOnLoad = true;
			} else {
				ev.stopPropagation();
				ev.nativeEvent.stopImmediatePropagation();
				choosePack(activeRow);
			}
		} else
		if(key === KEY_DOWN) {
			ev.stopPropagation();
			ev.nativeEvent.stopImmediatePropagation();
	
			let idx = results.indexOf(activeRow) + 1;
			if (idx >= results.length) {
				idx = results.length - 1;
			}
			setActiveRow(results[idx]);
		} else
		if(key === KEY_UP) {
			ev.stopPropagation();
			ev.nativeEvent.stopImmediatePropagation();
	
			let idx = results.indexOf(activeRow) - 1;
			if (idx < 0) {
				idx = 0;
			}
			setActiveRow(results[idx]);
		} else {
			// console.log("unknown key ", key);
		}
	}

	function HighlightHelper({ text }) {
		const matches = match(text, PackQuery.searchString);
		const parts = parse(text, matches);
		return (<>
			{parts.map((part, index) => (
				<span key={index} 
					className={clsx(
						part.highlight && styles.partHighlight
					)}
					style={{ fontWeight: part.highlight ? 700 : 400 }}
				>
					{part.text}
				</span>
			))}
		</>);	
	}

	return (<>
		<AddPackDialog 
			open={addPack} 
			onCancel={() => setAddPack(false)}
			onSave={newPackAdded}
		/>


		<Dialog 
			className={styles.ChoosePackDialog}
			open={!!open} 
			onClose={onCloseDialog}
			fullScreen={fullScreen}
		>
			<DialogTitle className={clsx(
				styles.searchBar,
				results && results.length && styles.hasResults,
			)}>
				<Input
					className={styles.search}
					disableUnderline
					type="search"
					placeholder="Search Packs"
					autoFocus
					onChange={ev => search(ev.target.value)}
					onKeyDown={ev => keyPressed(ev)}
				/>

				<Button
					variant="contained"
					color="primary"
					className={styles.newBtn}
					disableElevation
					onClick={onAddPack}
				>
					<AddIcon/>
					New…
				</Button>
			</DialogTitle>

			{results && results.length ?
				<DialogContent className={styles.results}>
					<List dense className={styles.resultsList} ref={listRef}>
						{results.map(row => (
							<ScrollIntoViewIfNeeded 
								key={row.id}
								active={activeRow === row}
							>
								<ListItem 
									button
									onClick={() => choosePack(row)}
									className={clsx(
										styles.searchResult,
										activeRow === row && styles.active,
									)}
								>
									{/* TODO: Rewrite this to use pack color, styling, 
									whatever */}
									<div className={styles.resultWrap}>
										{/* <span className={styles.ref}>
											<HighlightHelper text={row.cachedRef}/>
										</span> */}
										<span className={styles.text}>
											<HighlightHelper text={row.name}/>
										</span>
									</div>
								</ListItem>
							</ScrollIntoViewIfNeeded>
						))}
					</List>
				</DialogContent>
			: !loading && PackQuery.searchString ? <>
				<DialogContent className={styles.noMatch}>
					<p>Sorry, we couldn't find a match for <b>{PackQuery.searchString}</b></p>
				</DialogContent>
			</> 
			: ""}
			<DialogActions>
				<div className={styles.summary}>
					{results && results.length > 1 ? <>
						{results.length >= 10 ? 'Top ':''}
						<b>{results.length}</b> packs{" "}
					</> :""}
					{loading ? 
						<CircularProgress color="inherit" size={20} />
					: ""}
				</div>
				<Button 
					onClick={onCloseDialog}
					color="default"
					data-testtarget='cancel'
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	</>);
}

