import React from 'react';
import styles from './ColorEditButton.module.scss';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { 
	// SwatchesPicker
	ChromePicker
} from 'react-color';
import { computeTextColor, DEFAULT_COLOR } from 'utils/itemColorHelper';

export default function ColorEditButton({
	label = "Color",
	value,
	defaultValue,
	onChange = () => {},
	className = ""
}) {
	const [ open, setOpen ] = React.useState(false);
	const [ hexColor, setHexColor ] = React.useState(defaultValue);
	const [ startingColor ] = React.useState(value);

	if(value !== undefined && value !== hexColor) {
		setHexColor(value);
	}

	const colorChanged = color => {
		setHexColor(color);
		if(value) {
			onChange(color);
		}
	}

	return (
		<div className={styles.ColorEditButton}>
			<Button 
				className={styles.button}
				style={{
					'--item-color': hexColor || DEFAULT_COLOR,
					'--text-color': computeTextColor(hexColor || DEFAULT_COLOR),
				}}
				onClick={
					() => setOpen(true)
				}
			>
				{label}
			</Button>
			
			{/* NOTE: This will be moved to document root,
			so must target separately with a class  */}
			<Dialog 
				className={styles.ColorEditButtonDialog}
				open={open || false} 
				aria-labelledby="color-edit-dialog-title"
			>
				<DialogTitle id="color-edit-dialog-title">
					Choose Color
				</DialogTitle>
				<DialogContent >
					<ChromePicker
					//SwatchesPicker
						width={275}
						color={hexColor}
						onChange={color => colorChanged(color.hex)}
					/>
				</DialogContent>
				<DialogActions>
					<Button 
						onClick={() => {
							setHexColor(value ? startingColor : defaultValue);
							setOpen(false);
						}}
						color="secondary"
					>
						Cancel
					</Button>
					<Button 
						onClick={() => {
							setOpen(false);
							if(!value) {
								onChange(hexColor);
							}
						}}
						color="primary"
					>
						Save Color
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
