import React from 'react';
import clsx from 'clsx';

import styles from './ChooseVerseDialog.module.scss';

import VersionInput from './VersionInput';

import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import { ServerStore } from 'utils/ServerStore';
import { defer } from 'utils/defer';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { 
	Input,
	List,
	ListItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	CircularProgress
} from '@material-ui/core';


class VerseQuery {
	static setLoadingCallback(cb) {
		this.loadingCallback = cb;
	}

	static async setVersionId(versionId = "") {
		const changed = this.versionId !== versionId;
		
		this.versionId = versionId;
		
		// For notifying server of new version
		this.oldVersionId = this.lastSearchedVersionId;
		
		if(this.searchPromise) {
			return this.searchPromise;
		}

		if (this.searchString && changed) {
			return this.search(this.searchString);
		} else {
			return { list: [] }
		}
	}

	static stop() {
		this.stopped = true;
		if (this.loadingCallback) {
			this.loadingCallback(false);
		}

	}

	static async _search() {
		const query = {
			search: this.searchString,
			version: this.versionId,
		};

		if(this.oldVersionId !== this.versionId) {
			query.oldVersion   = this.oldVersionId;
		}

		// console.log("[_search] oldVersionId is ", this.oldVersionId, ", this.lastSearchedVersionId is ", this.lastSearchedVersionId,", and query is:", query);

		this.lastSearchedVersionId = this.versionId;


		const list = await ServerStore.GetVerses(query);

		// console.log("Serach got list: ", list);

		if(this.searchString !== query.search) {
			// console.warn("Search changed while waiting for results, not resolving");
			return;
		}

		if(this.stopped) {
			// console.warn("Stopped while waiting for results, not resolving");
			return;
		}
		
		if (this.loadingCallback) {
			this.loadingCallback(false);
		}

		// console.log("For query ", this.searchString, ", got:", list);

		ServerStore.metric("app.choose_verse.searched", null, {
			query:      query.searchString,
			version:    query.versionId,
			numResults: list.length,
		});

		if (this.searchPromise) {
			this.searchPromise.resolve(list);
			this.searchPromise = null;
		}

	}

	static async search(string="") {
		if (this.loadingCallback) {
			this.loadingCallback(true);
		}

		this.stopped = false;
		this.searchString = string;

		if(!this.searchPromise) {
			this.searchPromise = defer();
		}

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._search();
		}, this.delay || 500);

		return this.searchPromise;
	}
}


export default function ChooseVerseDialog({
	open,
	onCancel,
	onSave,
}) {
	const [ results, setResults ] = React.useState([]);
	const [ loading, setLoading ] = React.useState(false);
	const [ activeRow, setActiveRow ] = React.useState(null);
	const listRef = React.useRef();

	const onCloseDialog = () => {
		VerseQuery.searchString = '';
		if (onCancel) {
			onCancel();
		}
	}

	const chooseVerse = verse => {
		VerseQuery.searchString = '';
		if (onSave) {
			onSave(verse);

			ServerStore.metric("app.choose_verse.chose", null, {
				verse
			});
		}
	}

	const [ version, setVersion ] = React.useState(
		ServerStore.currentUser.preferredVersion || {
			id: 2,
			abbrv: 'ASV',
			name: 'ASV'
		});

	VerseQuery.setLoadingCallback(setLoading);
	VerseQuery.setVersionId(version.id);

	const gotResults = (results, forceFirstRow) => {
		// Display results with changes
		setResults(results);
		if(!results) {
			setActiveRow(null);
			return;
		}

		// Enter pressed, so select first result
		if (VerseQuery.saveOnLoad) {
			// clear flag for next usage
			VerseQuery.saveOnLoad = false;
		
			if(results[0]) {
				chooseVerse(results[0]);
				return;
			}
		}

		if(forceFirstRow && results[0]) {
			setActiveRow(results[0]);
			return;
		}

		const { book, chapter, verse } = activeRow || {}; 
		if(book && chapter && verse) {
			const row = results.find(row => {
				return (
					row.verse === verse &&
					row.chapter === chapter &&
					row.book.id === book.id
				);
			});
			if(row) {
				setActiveRow(row);
			}
		} else {
			setActiveRow(results[0])
		}
	}

	const changeVersion = ver => {
		setVersion(ver);
		VerseQuery.setVersionId(ver.id).then(gotResults);
	}

	const search = async (text) => {
		if(!text) {
			VerseQuery.searchString = "";
			setResults([]);
			return;
		}

		if(text === VerseQuery.searchString) {
			return;
		}

		const res = await VerseQuery.search(text);
		gotResults(res, true);
	}

	const keyPressed = ev => {
		const KEY_DOWN = 40,
			KEY_UP = 38,
			KEY_ENTER = 13,
			key = ev.which;
		
		if(key === KEY_ENTER) {
			if(loading) {
				// Will be reset in gotResults()
				VerseQuery.saveOnLoad = true;
			} else {
				ev.stopPropagation();
				ev.nativeEvent.stopImmediatePropagation();
				chooseVerse(activeRow);
			}
		} else
		if(key === KEY_DOWN) {
			ev.stopPropagation();
			ev.nativeEvent.stopImmediatePropagation();
	
			let idx = results.indexOf(activeRow) + 1;
			if (idx >= results.length) {
				idx = results.length - 1;
			}
			setActiveRow(results[idx]);
		} else
		if(key === KEY_UP) {
			ev.stopPropagation();
			ev.nativeEvent.stopImmediatePropagation();
	
			let idx = results.indexOf(activeRow) - 1;
			if (idx < 0) {
				idx = 0;
			}
			setActiveRow(results[idx]);
		} else {
			// console.log("unknown key ", key);
		}
	}

	function HighlightHelper({ text }) {
		const matches = match(text, VerseQuery.searchString);
		const parts = parse(text, matches);
		return (<>
			{parts.map((part, index) => (
				<span key={index} 
					className={clsx(
						part.highlight && styles.partHighlight
					)}
					style={{ fontWeight: part.highlight ? 700 : 400 }}
				>
					{part.text}
				</span>
			))}
		</>);	
	}

	return (
		<Dialog 
			className={styles.ChooseVerseDialog}
			open={!!open} 
			onClose={onCloseDialog}
		>
			<DialogTitle className={clsx(
				styles.searchBar,
				results && results.length && styles.hasResults,
			)}>
				<Input
					className={styles.search}
					disableUnderline
					type="search"
					placeholder="Search Verses"
					autoFocus
					onChange={ev => search(ev.target.value)}
					onKeyDown={ev => keyPressed(ev)}
				/>

				<VersionInput
					className={styles.versionSelect}
					defaultValue={version}
					onChange={changeVersion}
				/>
			</DialogTitle>

			{results && results.length ?
				<DialogContent className={styles.results}>
					<List dense className={styles.resultsList} ref={listRef}>
						{results.map(row => (
							<ScrollIntoViewIfNeeded 
								key={row.id}
								active={activeRow === row}
							>
								<ListItem 
									button
									onClick={() => chooseVerse(row)}
									className={clsx(
										styles.searchResult,
										activeRow === row && styles.active,
									)}
								>
									<div className={styles.resultWrap}>
										<span className={styles.ref}>
											<HighlightHelper text={row.cachedRef}/>
										</span>
										<span className={styles.text}>
											<HighlightHelper text={row.text}/>
										</span>
									</div>
								</ListItem>
							</ScrollIntoViewIfNeeded>
						))}
					</List>
				</DialogContent>
			: !loading && VerseQuery.searchString ? <>
				<DialogContent className={styles.noMatch}>
					<p>Sorry, we couldn't find a match for <b>{VerseQuery.searchString}</b> in our database under <b>{version.name}</b>.</p>
				</DialogContent>
			</> 
			: !loading ? <>
				<DialogContent className={styles.bibleAppHint}>
					<p><i>Hint:</i> Don't see Bible version you want? Open the <a
						href='http://bible.com/bible'
						rel='noopener noreferrer'
						target='_blank'
					>Bible App</a>, find the verse and version, then click the <b>SHARE</b> button and select <b>MyVerses</b> from the sharing menu.</p>
				</DialogContent>
			</>
			: ""}
			<DialogActions>
				<div className={styles.summary}>
					{results && results.length > 1 ? <>
						{results.length >= 10 ? 'Top ':''}
						<b>{results.length}</b> verses{" "}
					</> :""}
					{loading ? 
						<CircularProgress color="inherit" size={20} />
					: ""}
				</div>
				<Button 
					onClick={onCloseDialog}
					color="default"
					data-testtarget='cancel'
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}

