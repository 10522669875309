import React from 'react';

import styles from './PracticePack.module.scss'
import Proctor from 'utils/proctor/Proctor';
import history from 'utils/history';

import { randomEncouragement, randomAffirmation } from 'utils/randomAffirmation';

import VerseBadgeLevel from 'components/VerseBadgeLevel';

import { ServerStore } from 'utils/ServerStore';
// import SimpleSpinner from '../../components/SimpleSpinner';

// import CloseIcon from '@material-ui/icons/Close';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import CancelIcon from '@material-ui/icons/Cancel';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Button from '@material-ui/core/Button';
import clsx from 'clsx';
// import history from 'utils/history';
import VerseBox from './VerseBox';
import SimpleSpinner from 'components/SimpleSpinner';
import { AppBar, IconButton } from '@material-ui/core';
import { ICONS } from './PrimaryNav';
import Sparkles from './Sparkles';
import { NavControlService } from 'components/PrimaryNav';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';

import CircularProgress from '@material-ui/core/CircularProgress';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';

import ShuffleIcon from '@material-ui/icons/Shuffle';
import FlareIcon from '@material-ui/icons/Flare';
import LinearScaleIcon from '@material-ui/icons/LinearScale';

import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

// const EMOJIS = {
// 	smile: '😃',
// 	smile2: '🤗',
// 	sad: '😥',
// 	sad2: '😢',
// 	party: '🎉',
// 	party2: '😍',
// }

// function SuccessOrFailWidget({ info: {
// 	verseId,
// 	success, 
// 	verse, 
// } = {}}) {
// 	const [ hidden, setHidden ] = React.useState();
// 	const [ qid, setQid ] = React.useState();

// 	if(!verse) {
// 		verse = {};
// 	}

// 	if(!verseId && !hidden) {
// 		// console.log("SuccessOrFailWidget: setting hidden to true in first case");
// 		setHidden(true);
// 		success = true;
// 	} else
// 	if(verseId !== qid && hidden) {
// 		if(verseId) {
// 			setHidden(false);
// 			// console.log("SuccessOrFailWidget: setting hidden to FALSE in second  case");
// 			setQid(verseId);
// 			clearTimeout(SuccessOrFailWidget.tid);

// 			if(success) {
// 				SuccessOrFailWidget.tid = setTimeout(() => {
// 					setHidden(true);
// 					// console.log("SuccessOrFailWidget: setting hidden to true in TIMEOUT");
// 				}, success ? 300 : 2000)
// 			}
// 		}
// 	}

// 	const processKey = event => {
// 		const keyCode = event.which || event.keyCode || 0;
// 		if(keyCode === 13) {
// 			setHidden(true);
// 		}
// 	}

// 	React.useEffect(() => {
// 		document.addEventListener("keypress", processKey, false);
// 		return () => document.removeEventListener("keypress", processKey, false);
// 	});

// 	return (<>
// 		<div className={clsx(
// 			styles.SuccessOrFailWidget,
// 			hidden && styles.hidden,
// 			!hidden && !success && styles.fail,
// 		)}
// 			onClick={() => setHidden(true)} 
// 		>
// 			{/* <div className={styles.emoji}>
// 				{!success ? EMOJIS.sad : EMOJIS.smile}
// 			</div> */}
// 			{!success ? <>
// 				<div className={styles.emoji}>
// 					{Math.random() > 0.5 ? EMOJIS.sad : EMOJIS.sad2}
// 				</div>
// 				<div className={styles.failInfo}>
// 					<div className={styles.ref}>
// 						<b>{verse.ref}</b> is actually:
// 					</div>
					
// 					<div className={styles.text}>
// 						{verse.text}
// 					</div>
					
// 				</div>
// 				<Button 
// 					onClick={() => setHidden(true)} 
// 					className={styles.closeWidget}
// 				>
// 					<CloseIcon />
// 					<span>Thanks</span>
// 				</Button>
// 			</> : ""}
// 		</div>
// 	</>);

// }

export default function PracticePack({ trainingMode, pack, onPracticeStopped }) {
	// if(!pack.loadDone) {
	// 	return <SimpleSpinner fullpage={true}/>
	// }

	const DEFAULT_SEQUENCE_MODE = 'complete';
	
	const [ proctor,  setProctor  ] = React.useState();
	const [ verse,    setVerse    ] = React.useState({});
	const [ success,  setSuccess  ] = React.useState({});
	const [ summary,  setSummary  ] = React.useState({});
	const [ progress, setProgress ] = React.useState({});
	const [ loading,  setLoading  ] = React.useState(true);
	
	const [ trainingPopupOpen, setTrainingPopupOpen ] = React.useState(true);

	React.useEffect(() => {
		if(!loading && !summary.isFinished) {
			NavControlService.showPrimaryNav(false);
		}
		// NavControlService.setCurrentNav(NavControlService.NAV_PACKS);
		return () => NavControlService.showPrimaryNav(true);
	});

	React.useEffect(() => {
		if(!proctor && pack && pack.id) {
			Proctor.instance(pack).then(proctor => {
				setProctor(proctor);
				
				proctor.startTest(DEFAULT_SEQUENCE_MODE).then(() => {
					const {
						firstVerse,
						verseNum,
						totalVerses,
						progressPercent
					} = proctor.getFirstVerse();
					
					setVerse(firstVerse);

					setProgress({
						verseNum,
						totalVerses,
						progressPercent
					});

					setLoading(false);
				});
			});
		}

		// Stop timer so we don't change state after unmounted
		return () => {
			Proctor.destroyInstance(pack);
	
			clearTimeout(success.tid);
		}
	});

	const [ playPauseDisabled, setPlayPauseDisabled ] = React.useState(false);
	const [ attemptLoading, setAttemptLoading ] = React.useState(false);
	const [ attemptResults, setAttemptResults ] = React.useState({});

	const onAttemptComplete = async () => {
		setPlayPauseDisabled(true);

		setAttemptLoading(true);

		// Stop the timer for the results
		const result = await proctor.stopAttempt();
		// console.log("Attempt result:", result);
		// alert(JSON.stringify(result));
		setAttemptResults(result);

		setAttemptLoading(false);
	}

	const gotoVerseIndex = idx => {
		setPlayPauseDisabled(false);
			
		// discard current attempt
		proctor.stopAttempt(true);

		const res = proctor.gotoVerseIndex(idx);

		const {
			nextVerse: qref,
			verseNum,
			totalVerses,
			progressPercent
		} = res;
		
		setProgress({
			verseNum,
			totalVerses,
			progressPercent
		});

		// const summary = proctor.testManager.computeSummary();
		// setSummary(summary);
		
		setVerse(qref);
		setTrainingPopupOpen(true);
	}

	const prevVerse = async () => {
		setPlayPauseDisabled(false);
		
		// discard current attempt
		proctor.stopAttempt(true);

		ServerStore.metric('app.practice.previous_verse');

		const res = proctor.getPrevVerse();

		const {
			prevVerse: qref,
			verseNum,
			totalVerses,
			progressPercent
		} = res;
		
		setProgress({
			verseNum,
			totalVerses,
			progressPercent
		});

		// const summary = proctor.testManager.computeSummary();
		// setSummary(summary);
		
		setVerse(qref);
		setTrainingPopupOpen(true);
	}

	const [ seqPaused, setSeqPaused ] = React.useState();
	const pauseSeq = (flag=true) => {
		setSeqPaused(!!flag);
		if(flag) {
			ServerStore.metric('app.practice.pause_test');

			// discard current attempt
			proctor.stopAttempt(true);

			const summary = proctor.testManager.computeSummary(false);
			setSummary(summary);
		}
	};

	const exitPracticeMode = async () => {
		if(seqPaused) {
			setLoading(true);
			// const { pointsUp } = 
			await proctor.finishTest(summary);
			setLoading(false);
			// setPointsUp(pointsUp);
		}

		ServerStore.metric('app.practice.exit_practice');

		if (onPracticeStopped) {
			onPracticeStopped();
		}
	}

	const skipVerse = () => {
		ServerStore.metric('app.practice.skip_verse');
		
		nextVerse(null, null, true); // mark1
	}

	const nextVerse = async (success, verse, discardAttempt=false) => {
		if(discardAttempt) {
			proctor.stopAttempt(true);
		} else {
			ServerStore.metric('app.practice.next_verse');
		}

		setPlayPauseDisabled(false);

		const res = proctor.getNextVerse();

		const {
			nextVerse: qref,
			verseNum,
			totalVerses,
			progressPercent
		} = res;

		setProgress({
			verseNum,
			totalVerses,
			progressPercent
		});

		// const qref = proctor.getNextVerse();

		// console.warn("nextVerse qref=", qref, ", !!qref=", !!qref);
		
		const summary = proctor.testManager.computeSummary(!qref);
		setSummary(summary);

		// console.warn("nextVerse res=", res,", summary=", summary);

		if(!qref) {
			NavControlService.showPrimaryNav(true);
			
			setLoading(true);
			await proctor.finishTest(summary);
			setLoading(false);
			return;
		}
		
		setVerse(qref);
		setTrainingPopupOpen(true);
		setAttemptLoading(false);
		setAttemptResults({});

		// const tid = setTimeout(() => {
		// 	setSuccess({ ...success, visible: false, tid: null });
		// }, 3300);

		// console.trace({ success, correctAnswer });
		if(verse) {
			setSuccess({
				verseId: qref.id,
				success, 
				verse,
				visible: true, 
				// tid
			});
		}
	}

	function TrainingPopup({ open, onClose, success, progress, verse, trainingDone }) {
	
		if(!trainingDone && (!progress || progress.verseNum === undefined)) {
			return <></>;
		}

		const num = progress.verseNum,
			isFirst = num === 0,
			isMid   = num === 1,
			// isLast  = num === 2,
			failedLast = !isFirst && success && !success.success,
			goSearch = () => history.push('/search');

		return (<div 
			className={clsx(
				styles.trainingPopup,
				open && styles.visible,
			)}
			onClick={() => trainingDone ? goSearch() : onClose()}
		>
			<div className={styles.inner}>
				{trainingDone ? <>
					<p><Sparkles type='emoji'><b>Excellent!</b></Sparkles> Next, we'll take you to the <ICONS.SearchIcon/> <b>Search</b> page where you can find collections of verses (we call them "Packs") to memorize.</p>
					<p>You can always make your own packs on the <ICONS.PacksIcon/> <b>Packs</b> page and see packs you follow there, too!</p>

					<Button 
						fullWidth
						variant="contained"
						color="primary"
						onClick={goSearch}
						style={{ marginTop: '.75rem' }}
					>
						<KeyboardArrowRight/> Go Search
					</Button>
				</> : isFirst ? <>
					<p><b>Welcome</b>! I'd like to show you how you can practice your memory of verses using this "fill in the blanks" screen.</p>
					<p>To <b>fill in the blanks</b>, 
						click a word you think might fit in the first open blank from the list below. If the word shows <span className={clsx(
							styles.trainingAnswerDemo, styles.trainingBad
						)}>Red</span>, it means the answer you chose was incorrect,
						and <span className={clsx(
							styles.trainingAnswerDemo, styles.trainingGood
						)}>Green</span> means you were correct!
					</p>

					<p>Ready to try <b>{verse.ref}</b>? Click this message to close it.</p> 
				</>  :
				isMid ? <>
					{failedLast ? 
						<p>
							Hey, that's okay! That was a tough one. You'll have plenty of time to learn it later. <b>Let's try {verse.ref}!</b>
						</p>
					:
						<p>
							<Sparkles><b>Great job!</b></Sparkles> Let's try <b>{verse.ref}</b> since you did so well on the last one!
						</p>
					}
				</> :
				<>
					{failedLast ? 
						<p>
							No worries! That was a tough verse. <b>One more verse to try out</b>, then you can see your results and choose your own sets of verses!
						</p>
					:
						<p>
							<Sparkles><b>Great job!</b></Sparkles> One more verse to try out, then you can see your results and choose your own sets of verses!
						</p>
					}
				</>}
			</div>
		</div>);
	}

	const [ trainingDone, setTrainingDone ] = React.useState(false);
	const [ shuffleType, setShuffleType ] = React.useState(DEFAULT_SEQUENCE_MODE);

	const sequenceManager = (
		proctor && 
		proctor.sequenceManager &&
		proctor.sequenceManager
	) || {};

	const hasNextVerse = () =>
		sequenceManager.hasNextVerse &&
		sequenceManager.hasNextVerse();

	const hasPrevVerse = () =>
		sequenceManager.hasPrevVerse &&
		sequenceManager.hasPrevVerse();

	const changeShuffleType = (type, state) => {
		return ev => {
			setShuffleType(type);

			ServerStore.metric('app.practice.shuffle_type.' + type);
			
			sequenceManager.changeSequenceMode(type);
			gotoVerseIndex(sequenceManager.currentVerseIdx || 0);

			if(state && state.close) {
				state.close(ev);
			}
		}
	}

	return (<div className={styles.root}>
		{loading && <SimpleSpinner fullpage={true}/>}

		{trainingMode && <TrainingPopup 
			open={trainingPopupOpen}
			onClose={() => setTrainingPopupOpen(false)}
			success={success}
			progress={progress}
			verse={verse}
			trainingDone={trainingDone}
		/>}

		<AppBar
			position="static"
			className={styles.packName}
			// onClick={nextVerse}
		>
			<div className={styles.wrap}>
				{/* {!summary.isFinished ?
					<IconButton
						className={styles.stopBtn}
						onClick={onPracticeStopped || (() => {})}
					>
						<ChevronLeftIcon/>
					</IconButton>
				:""} */}

				<span className={styles.name}>
					{/* {!verse || !verse.ref || summary.isFinished ?
						pack.name
						:
						verse.ref
					} */}
					{pack.name}
				</span>

				{progress.totalVerses ? <>
					{" "}
					<span className={styles.progressLabel}>
						(Verse {progress.verseNum + 1} / {progress.totalVerses})
					</span>
				</> : ""}
			</div>
		</AppBar>

		{!summary.isFinished ?
			<AppBar
				position="static"
				className={clsx(
					styles.practiceBar,
					attemptResults && attemptResults.id && styles.hasAttemptResults
				)}
			>
				<div className={styles.wrap}>
					<PopupState variant="popover">
						{(popupState) => <>
							<IconButton 
								disabled={seqPaused || !hasNextVerse() || playPauseDisabled}
								className={styles.modeBtn}
								{...bindTrigger(popupState)}
							>
								{shuffleType === 'shuffled' ?
									<ShuffleIcon/>
								: shuffleType === 'difficulty' ?
									<FlareIcon/>
								: <LinearScaleIcon/>}
							</IconButton>

							<Menu {...bindMenu(popupState)}
								className={styles.practiceMenu}
							>
								<MenuItem 
									onClick={changeShuffleType('shuffled', popupState)}
									selected={shuffleType === 'shuffled'}
								>
									<ListItemIcon>
										<ShuffleIcon fontSize="small"/>
									</ListItemIcon>
									<Typography variant="inherit">
										Random Shuffle
									</Typography>
								</MenuItem>
								<MenuItem 
									onClick={changeShuffleType('difficulty', popupState)}
									selected={shuffleType === 'difficulty'}
								>
									<ListItemIcon>
										<FlareIcon fontSize="small"/>
									</ListItemIcon>
									<Typography variant="inherit">
										Intelligent Shuffle
									</Typography>
								</MenuItem>
								<MenuItem 
									onClick={changeShuffleType('complete', popupState)}
									selected={shuffleType === 'complete'}
								>
									<ListItemIcon>
										<LinearScaleIcon fontSize="small"/>
									</ListItemIcon>
									<Typography variant="inherit">
										No shuffle
									</Typography>
								</MenuItem>
							</Menu>
						</>}
					</PopupState>
					

					<div className={styles.sequenceWrap}>
						<IconButton 
							className={styles.skipPrevBtn}
							onClick={() => prevVerse()}
							disabled={seqPaused || !hasPrevVerse()}
						>
							<SkipPreviousIcon/>
						</IconButton>

						<IconButton
							className={styles.playBtn}
							onClick={() => pauseSeq(!seqPaused)}
							disabled={playPauseDisabled}
						>
							{attemptLoading ?
								<CircularProgress/>
							: seqPaused ? 
								<PlayCircleFilledIcon/>
							:
								<PauseCircleFilledIcon/>
							}
						</IconButton>

						<IconButton 
							className={styles.skipNextBtn}
							onClick={() => skipVerse()}
							disabled={seqPaused || (!hasNextVerse())}
						>
							<SkipNextIcon/>
						</IconButton>
					</div>
					
					<PopupState variant="popover">
						{(popupState) => <>
							<IconButton 
								className={styles.listBtn}
								{...bindTrigger(popupState)}
								disabled={seqPaused || playPauseDisabled}
							>
								{proctor ? 
									<PlaylistPlayIcon/>
								: ""}
							</IconButton>

							{sequenceManager.verseList ?
								<Menu {...bindMenu(popupState)}
									className={styles.practiceMenu}
								>
									{sequenceManager.verseList.map((v, idx) => 
										<MenuItem
											onClick={ev => {
												gotoVerseIndex(idx);
												popupState.close(ev);
											}}
											key={v.id}
											selected={verse && verse.id === v.id}
										>
											<span className={styles.numHint}>{idx + 1}</span>
											<VerseBadgeLevel 
												verse={v}
												className={styles.verseLevel}
											/>
											{sequenceManager.currentVerseIdx > idx ? 
												<span className={styles.done}>✓</span>
											: ''}
											{v.cachedRef}
										</MenuItem>
									)}
								</Menu>
							:""}
						</>}
					</PopupState>
				</div>
			</AppBar>
		: ""}
		
		<div className={styles.pack}>
			{summary.isFinished || seqPaused ?
				<div className={styles.finalSummary}>
					{seqPaused ? <>
						<h4>Paused</h4> 

						{!summary.avgComprehension ?
							<p className={styles.affirm}>
								{Math.random() > 0.625 ? randomEncouragement() : randomAffirmation()}
							</p>
						: ""}
						
					</>: ""}

					{summary.avgComprehension ? <>
						<h1>{summary.avgComprehension}% Comprehension</h1>
						<h2>{summary.avgAccuracy}% accuracy</h2>
						<h3>{summary.avgDifficulty}% difficult</h3>
						<h3>{summary.avgTime} seconds/verse</h3>
						<h3 className={styles.pointsUp}>
							<span className={styles.value}>+{summary.pointsUp}</span> points earned
						</h3>
					</> : ""}

					{trainingMode ?
						<Button 
							onClick={() => {
								setTrainingPopupOpen(true);
								setTrainingDone(true);
							}}
							className={styles.exit}
						>
							<KeyboardArrowRight />
							<span>{" "}Next</span>
						</Button>
					:
						<Button 
							onClick={exitPracticeMode}
							className={styles.exit}
						>
							<span>{seqPaused ? "Stop Practice" : "Finish"}</span>
							<KeyboardArrowRight />
						</Button>
					}
				</div>
			: <>
				{!proctor ?
					<SimpleSpinner fullpage={true}/>
				: <>
					<VerseBox 
						proctor={proctor}
						verse={verse}
						onNext={nextVerse}
						onAttemptComplete={onAttemptComplete}
						resultsLoading={attemptLoading}
						attemptResults={attemptResults}
					/>
					{/* <div className={styles.stage}>
						<div className={styles.stageCard}>
							<div className={clsx(styles.cardFace, styles.front)}>
								<VerseBox 
									proctor={proctor}
									verse={verse}
									onNext={nextVerse}
								/>
							</div>
							<div className={clsx(styles.cardFace, styles.back)}>
								<h1>Hi, I'm on the Back</h1>
							</div>
						</div>
					</div> */}
				</>}

				{summary.total ?
					<div className={styles.rollingSummary}>
						<h1>{summary.avgComprehension}% Comprehension</h1>
						<h2>{summary.avgAccuracy}% accuracy</h2>
						<h3>{summary.avgDifficulty}% difficulty</h3>
						<h3>{summary.avgTime} seconds/question</h3>
					</div>
				: ""}

				{/* <SuccessOrFailWidget info={success}/> */}
			</>}

		</div>
	</div>)
}
