// src/components/PrivateRoute.js

import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import history from 'utils/history';
import { ServerStore } from 'utils/ServerStore';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
	const [ loading, setLoading ] = React.useState();
	const [ isAuthenticated, setIsAuthenticated ] = React.useState();

	useEffect(() => {
		if(!loading) {
			setLoading(true);
			ServerStore.loginIfNeeded().then(user => {
				if(!user) {
					// console.log("PrivateRoute: not authenticated, going to login");
					history.push('/login');
				} else {
					// console.log("PrivateRoute: authenticated, allowing render");
					setIsAuthenticated(true);
				}
			});
		}
	}, [ setLoading, setIsAuthenticated, loading ]);

	const render = props =>
		isAuthenticated === true ? <Component {...props} /> : null;

	return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;