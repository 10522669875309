import React from 'react';
import styles from './PackPage.module.scss';
import history from 'utils/history';
import { ServerStore } from 'utils/ServerStore';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import SearchIcon from '@material-ui/icons/Search';
// import LoopIcon from '@material-ui/icons/Loop';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import VersionInput from 'components/VersionInput';
import ColorEditButton from 'components/ColorEditButton';
// import SearchUnsplashDialog from 'components/SearchUnsplashDialog';

export default function EditPackDialog({
	pack: defaultValue = {},
	open,
	onClose = () => {},
}) {
	const [ pack, setPack ] = React.useState(defaultValue || {});
	if(pack.id !== defaultValue.id) {
		setPack({ ...defaultValue });
	}
	
	const closeDialog = () => {
		setPack({});
		onClose();
	}
	
	const deletePack = async () => {
		if(!window.confirm("Are you SURE you want to delete this pack of verses?")) {
			return;
		}

		await ServerStore.DeletePack(pack.id);
		history.push('/');
	}

	// const [ imageChanged, setImageChanged ] = React.useState();
	const saveChanges = async () => {
		// if(imageChanged) {
		// 	// No need to await the metric pingback
		// 	ServerStore.SendUnsplashMetrics(pack.imageMeta)
		// }

		await ServerStore.UpdatePack(pack);
		closeDialog();
	}

	const fieldChanged = (field, value) => {
		if(['isEditable','isPublic'].includes(field)) {
			value = !!value;
		}

		setPack({ ...pack, [field]: value });
	}

	const handleChange = ev => {
		fieldChanged(ev.target.name, 
			ev.target.type === 'checkbox' ? 
				ev.target.checked : 
				ev.target.value
		);
	}

	// const [ randomLoading, setRandomLoading ] = React.useState();
	// const randomPhoto = async () => {
	// 	setRandomLoading(true);
	// 	const meta = await ServerStore.RandomUnsplash(pack.name);
	// 	setRandomLoading(false);
	// 	setPack({
	// 		...pack,
	// 		color: meta.color,
	// 		imageUrl: meta.coverPhoto,
	// 		imageMeta: meta
	// 	});
	// 	setImageChanged(true);
	// }

	// const [ unsplashDialogOpen, setUnsplashDialogOpen ] = React.useState();
	// const unsplashClose = meta => {
	// 	if(meta) {
	// 		setPack({
	// 			...pack,
	// 			color: meta.color,
	// 			imageUrl: meta.coverPhoto,
	// 			imageMeta: meta,
	// 		});
	// 		setImageChanged(true);
	// 	}
	// 	setUnsplashDialogOpen(false);
	// };
	// const searchUnsplash = () => {
	// 	setUnsplashDialogOpen(true);
	// }

	// console.log("Current pack value:", pack);

	return (
		<Dialog 
			className={styles.EditPackDialog}
			open={open || false} 
			aria-labelledby="edit-pack-dialog-title"
			onClose={onClose}
		>
			<DialogTitle id="edit-pack-dialog-title">
				Pack Settings
			</DialogTitle>
			<DialogContent className={styles.fields}>
				<TextField
					type="text"
					label="Pack Name"
					autoFocus={!pack.name? true : false}
					defaultValue={pack.name}
					onChange={handleChange}
					name="name"
				/>

				{/* <FormControl>
					<InputLabel>Role</InputLabel>
					<Select
						value={user.role || 'client'}
						onChange={ev => setRole(ev.target.value)}
					>
						<MenuItem value={'admin'}>Admin</MenuItem>
						<MenuItem value={'realtor'}>Realtor</MenuItem>
						<MenuItem value={'client'}>Client</MenuItem>
					</Select>
				</FormControl> */}

				<TextField
					label="Description"
					multiline
					rowsMax={3}
					value={pack.description}
					onChange={handleChange}
					name="description"
				/>

				{/* <VersionInput
					label="Version"
					className={styles.versionSelect}
					defaultValue={pack.version}
					onChange={ver => fieldChanged('version', ver)}
				/> */}

				<ColorEditButton
					label="Color"
					value={pack.color}
					onChange={hexColor => fieldChanged('color', hexColor)}
					className={styles.textField}
				/>

				{/* <FormControl className={styles.coverPhotoEditControl}>
					<InputLabel shrink>Cover Photo</InputLabel>
					<div className={styles.wrap}>
						<div 
							className={styles.coverPhotoPreview}
							style={{
								backgroundImage: `url("${pack.imageUrl}")`,
							}}
						>
							{!pack.imageUrl ? <span>?</span> : ""}
						</div>
						<div className={styles.icons}>
							<SearchUnsplashDialog
								open={unsplashDialogOpen}
								onSave={unsplashClose}
								onCancel={unsplashClose}
								startingQuery={pack.name}
							/>
							<Button onClick={searchUnsplash}>
								<SearchIcon/> Search Unsplash
							</Button>
							{randomLoading ? 
								<CircularProgress color="inherit" size={20} />	
								:
								<Button onClick={randomPhoto}>
									<LoopIcon/> Random Photo
								</Button>
							}
						</div>
					</div>
					{pack.imageMeta && pack.imageMeta.attribUser ?
						<div className={styles.photoAttrib}>
							Photo by <a href={pack.imageMeta.attribUser} target='_blank' rel='noopener noreferrer'>{pack.imageMeta.authorName}</a> on <a href={pack.imageMeta.attribUnsplash} target='_blank' rel='noopener noreferrer'>Unsplash</a>
						</div>
					:""}
				</FormControl> */}

				<FormControl component="fieldset" className={styles.sharingOpts}>
					<FormLabel component="legend">Sharing</FormLabel>
					<FormGroup>
						<FormControlLabel
							control={<Checkbox checked={!!pack.isPublic} onChange={handleChange} name="isPublic" />}
							label="Make this verse pack public"
						/>
						<FormControlLabel
							disabled={!pack.isPublic}
							control={<Checkbox checked={!!pack.isEditable} onChange={handleChange} name="isEditable" disabled={!pack.isPublic}/>}
							label="Allow others to add/remove verses"
						/>
					</FormGroup>
					<FormHelperText>Publically editable packs only allow others to add/remove verses, not change name or description.</FormHelperText>
				</FormControl>

			</DialogContent>
			<DialogActions className={styles.actions}>
				<Button 
					onClick={deletePack}
					color="secondary"
				>
					Delete
				</Button>
				<div>
					<Button 
						onClick={closeDialog}
						color="default"
						data-testtarget='cancel'
					>
						Cancel
					</Button>
					<Button 
						onClick={saveChanges}
						color="primary"
					>
						Save
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}

