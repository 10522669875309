// Import this first so it can start anything it wants and is guaranteed first priority
import { MyVersesCoreService } from 'utils/MyVersesCoreService';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

window.Sentry = Sentry;
if(process.env.NODE_ENV === 'production') {
	Sentry.init({ dsn: 'https://84dcf751a46c46338f293146841edfef@o385886.ingest.sentry.io/5219307' });
}

// Boot our core service once UI has a chance to render
// Blocks until deffered promise resolved in App.js by unlockBoot();
MyVersesCoreService.boot();

ReactDOM.render(
	<App />,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if(!window._pgStarted)
	serviceWorker.register();