import { mean } from 'mathjs/number';
import nlp from 'compromise';
import stopwords from './stopwords';

export const BLANK_TEXT_FILLER = '_____';

export const round2digits = num => Math.round((num + Number.EPSILON) * 100) / 100;
export const avgHelper = (list, key, lastX=null) => {
	if(!list || !list.length || !key) {
		return 0;
	}

	const mapped = list
		.map(item => item && parseFloat(item[key]))
		.filter(value => !isNaN(value));

	return mapped.length ? 
		mean(
			lastX ? 
				  mapped.reverse().slice(0, lastX)
				: mapped
		): 
		0;
}

export const percentHelper = percent => Math.round(percent * 100);

/**
 * Randomly shuffle an array
 * https://stackoverflow.com/a/2450976/1293256
 * @param  {Array} array The array to shuffle
 * @return {Array}       The same array
 */
export function shuffle(array) {
	if(process.env.NODE_ENV === 'test') {
		return array;
	}
	
	var currentIndex = array.length;
	var temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;

};

export function tokWordsToText(source) {
	return source
		.map(({ text, pre, post, blank, blankText }) => 
			`${pre}${blank ? (blankText || BLANK_TEXT_FILLER) : text}${post}`
		)
		.join('')
}

export function tokenize(text) {
	const tok = [];
	const doc = nlp.tokenize(text);
	for(let phraseNum=0; 
			phraseNum<doc.length;
			phraseNum++) {
		const phrase = doc.json(phraseNum).terms;
		tok.push(...phrase);
	}

	return tok;
}

export function createFillInBlankText(text, options={
	maxBlanks: 5,
	// If maxBlanksPercent set, maxBlanks is calculated from percent of words
	// (any explicit maxBlanks is ignored and overridden)
	maxBlanksPercent: undefined,
}) {
	const tok = tokenize(text);

	// ex row: { text: 'Son', tags: [], pre: '', post: ', ' },

	let blankCount = 0, 
		maxBlanks = options.maxBlanks || 5,
		maxBlanksPercent = options.maxBlanksPercent || null;

	if(maxBlanksPercent) {
		maxBlanksPercent = Math.max(0, Math.min(1, maxBlanksPercent));
		if(!isNaN(maxBlanksPercent)) {
			maxBlanks = tok.length * maxBlanksPercent;
			console.log(`[Proctor.utils.createFillInBlankText] maxBlanksPercent=${maxBlanksPercent}, total tokenized words: ${tok.length}, calculated maxBlanks=${maxBlanks}`);
		}
	}
	
	const shouldBlank = (stop, idx) => {
		if(process.env.NODE_ENV === 'test') {
			// For testing, remove randomness
			// and just blank first two words, regardless of stop words,
			// because our tests expect exactly two blanks per question
			return idx < 2;
		}

		// If asking for large percent, don't stop for stop words
		// if(maxBlanksPercent > 0.5) {
		// 	stop = false;
		// }

		if(stop ||
			blankCount >= maxBlanks) {
			return false;
		}

		if(Math.random() > 0.5 - (maxBlanksPercent || 0)) {
			blankCount ++;
			return true;
		}

		return false;
	}

	const isStop = text => stopwords[text.toLowerCase()];

	const source = tok.map(({ text, pre, post }, idx) => {
		const ref = {
			text,
			pre,
			post,
			isStopword: isStop(text),
		}
		if(shouldBlank(isStop(text), idx)) {
			ref.blank = true;
		}
		return ref;
	});

	// Make sure there at least ONE fill in the blank,
	// and at most somewhere around maxBlanks
	if(process.env.NODE_ENV !== 'test') {
		// if(blankCount < Math.max(1,
		// 	maxBlanks - Math.ceil(Math.random() * maxBlanks)
		// )) {
		if(blankCount < maxBlanks) {
			source
				.filter(x => !x.blank)
				.forEach(row => {
					if(!row.blank &&
						// !isStop(row.text) && 
						++ blankCount < maxBlanks) {
						row.blank = true;
					}
				});
		}
	}

	// console.log("", source, "\n " + sourceToText(source));

	return { 
		words: source,
		text: tokWordsToText(source)
	}
}

// async function main() {
// 	const text = `He has shown you, O mortal, what is good. And what does the Lord require of you? To act justly and to love mercy and to walk humbly with your God.`;
// 	const out = createFillInBlankText(text);
// 	console.dir(out, { depth: 4 });
// }
// main().then(_ => process.exit()).catch(e => console.error(e));