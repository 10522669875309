import React from 'react';
import styles from './LoginPage.module.scss';
import clsx from 'clsx';
import { NavControlService } from 'components/PrimaryNav';
import { ServerStore, FB_APP_ID } from '../../utils/ServerStore';
// import { useRemoteData } from '../../utils/useRemoteData';
import SimpleSpinner from '../../components/SimpleSpinner';
import { SimpleBanner } from '../HomePage/SimpleBanner';
import history from 'utils/history';

// Login logic (loads FB SDK automatically)
import FacebookLogin from 'components/FacebookLogin';
import { mobileDetect } from 'utils/mobile-detect';

import { withStyles, Grid, TextField, Button } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons'
import { PushNotifyService } from '../../utils/PushNotifyService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faFacebookF } from '@fortawesome/free-brands-svg-icons';

const theme = theme => ({
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(1),
    }
});

export default withStyles(theme)(function LoginPage({ classes }) {
	React.useEffect(() => {
		NavControlService.showPrimaryNav(false);
	});

	const [ email, setEmail ] = React.useState();
	const [ password, setPassword ] = React.useState();
	const [ loginPending, setLoginPending ] = React.useState();
	const [ loginError, setLoginError ] = React.useState();


	const loginSuccess = () => {
		// console.log("+ SUCCESS");
		// alert("Success");
		if (history.preLoginUrl) {
			if(PushNotifyService.hashIsAlert(history.preLoginUrl)) {
				console.log("Login success, found ALERT in preLoginUrl, passing to PushNotifyService ", history.preLoginUrl);
				PushNotifyService.instance.handleAlertHash(history.preLoginUrl);
			} else {
				console.log("Login success, found preLoginUrl, going to ", history.preLoginUrl);
				history.push(history.preLoginUrl);
			}
		} else {
			console.log("Login success, no preLoginUrl, sending to /home");
			history.push('/home');
		}
	}

	const loginAction = async () => {
		setLoginPending(true);
		setLoginError(false);
		const result = await ServerStore.loginEmailPass({ email, password })
		setLoginPending(false);
		if(!result) {
			setLoginError({ message: "" });
		} else {
			loginSuccess();
		}
	}

	const facebookConnected = (response) => {
		setLoginPending(true);
		if(!response.accessToken) {
			return;
		}
		ServerStore.login(response.accessToken).then(store => {
			setLoginPending(false);
			if(store) {
				loginSuccess();
			} else {
				// window.Alert("Could not authenticate with server");
				setLoginError(true);
			}
		})
	}

	// If user goes Logout > Login, FB seems to hang...WHY??
	function LoadingHack() {
		clearTimeout(LoadingHack.tid);
		LoadingHack.tid = setTimeout(() => {
			console.log("FB loading timed out, reloading page")
			window.location.reload();
		}, 3000);

		React.useEffect(() => {
			return () => clearTimeout(LoadingHack.tid);
		})

		return <SimpleSpinner/>;
	}

	// Re-enable once FB re-approves which is waiting on Google to reapprove
	const showFbLogin = false;

	const showSigninWithApple = //process.env.NODE_ENV !== 'production' ||
		(
			window.isPhoneGap &&
			window.cordova &&
			window.cordova.plugins &&
			window.cordova.plugins.SignInWithApple &&
			// Sign in with apple only compat with 13+
			mobileDetect.md.version('iOS') >= 13.0
		);

	const signinWithApple = () => {

		// const fakeSuccessForTestingServer = {
		// 	user: 66,
		// 	identityToken: 'fakeSuccessForTestingServer',
		// 	email: '66'
		// };

		// return new Promise(resolve => {
		// 	setTimeout(() => {
		// 		ServerStore.loginApple(fakeSuccessForTestingServer).then(store => {
		// 			setLoginPending(false);
		// 			if(store) {
		// 				loginSuccess();
		// 			} else {
		// 				setLoginError({ message: "Server rejected login" });
		// 			}

		// 			resolve();
		// 		})
		// 	}, 1000);
		// })

		setLoginError(null);

		// https://www.npmjs.com/package/cordova-plugin-sign-in-with-apple
		window.cordova.plugins.SignInWithApple.signin(
			{ requestedScopes: [0, 1] },
			apple => {
				console.log("Success from apple plugin, sending to server:" + JSON.stringify(apple));

				setLoginPending(true);
				ServerStore.loginApple(apple).then(store => {
					setLoginPending(false);
					if(store) {
						loginSuccess();
					} else {
						setLoginError({ message: "Server rejected login" });
					}
				})
			},
			err => {
				setLoginPending(false);
				console.error(err);
				console.log(JSON.stringify(err));
				setLoginError({ message: err.localizedDescription || "Sign in with Apple failed" });
			}
		);
	}

	return (<div className={styles.root}>
			<SimpleBanner/>
			{loginPending && <SimpleSpinner fullPage={true}/>}
		{/* <Paper className={classes.padding}> */}
			<div className={classes.margin}>

				<div 
					className={styles.connectFb}
				>
					{showFbLogin ?
						<FacebookLogin
							appId={FB_APP_ID}
							callback={facebookConnected}
							render={renderProps => <>{
								renderProps.isLoading ?
									<div className="fb-state-pending">
										<LoadingHack/>
									</div>
									:
								renderProps.hasError ? 
									<div className="fb-state-error">
										Sorry, {renderProps.error.message}
									</div>
									:
								renderProps.hasAuthResponse ? 
									<div className="fb-state-pending">
										<SimpleSpinner/>
									</div>
								:
								<>
									<Button
										className={clsx(styles.btnConnect, styles.fb)}
										onClick={renderProps.onClick}
									>
										<FontAwesomeIcon icon={faFacebookF} fixedWidth/>
										Sign in with Facebook
									</Button>

								</>
							}</>}
						/>
					: ""}

					{showSigninWithApple ? 
						<Button
							className={clsx(styles.btnConnect, styles.apple)}
							onClick={signinWithApple}
						>
							<FontAwesomeIcon icon={faApple} fixedWidth/>
							Sign in with Apple
						</Button>
					: ""}

					{showSigninWithApple || showFbLogin ?
						<p className={styles.orBox}>
							or login below
						</p>
					: ""}

				</div>


				{loginError &&
					<div className={styles.loginError}>
						Sorry, that didn't work.
						{loginError.message ? <>({loginError.message})</> : ""}
					</div>
				}
				
				<Grid container spacing={8} alignItems="flex-end">
					<Grid item>
						<Face />
					</Grid>
					<Grid item md={true} sm={true} xs={true}>
						<TextField 
							id="email" 
							label="E-mail" 
							type="email" 
							fullWidth autoFocus required
							onChange={e => setEmail(e.target.value)}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={8} alignItems="flex-end">
					<Grid item>
						<Fingerprint />
					</Grid>
					<Grid item md={true} sm={true} xs={true}>
						<TextField 
							id="username"
							label="Password"
							type="password"
							fullWidth required
							onChange={e => setPassword(e.target.value)}
						/>
					</Grid>
				</Grid>
				{!loginPending && <Grid 
					container 
					justify="center" 
					style={{ marginTop: '1rem' }}
				>
					<Button 
						variant="contained" 
						color="primary" 
						style={{ textTransform: "none" }}
						onClick={loginAction}
					>
						Login
					</Button>
				</Grid>}
			</div>
		{/* </Paper> */}
	</div>);
})
