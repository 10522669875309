import React from 'react';
import styles from './OnboardPage.module.scss';
import clsx from 'clsx';
import { MyVersesCoreService } from 'utils/MyVersesCoreService';
import { NavControlService, ICONS } from 'components/PrimaryNav';
import SimpleSpinner from 'components/SimpleSpinner';
// import { ServerStore } from '../../utils/ServerStore';
// import { useRemoteData } from '../../utils/useRemoteData';
// import SimpleSpinner from '../../components/SimpleSpinner';
import { SimpleBanner } from '../HomePage/SimpleBanner';
import history from 'utils/history';

// Login logic (loads FB SDK automatically)
import FacebookLogin from 'components/FacebookLogin';
import VersionInput from 'components/VersionInput';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import { mobileDetect } from 'utils/mobile-detect';

// import OverviewIcon from '@material-ui/icons/CalendarToday';
// import LogIcon from '@material-ui/icons/Done';
// import SettingsIcon from '@material-ui/icons/Settings';
// import PlayIcon from '@material-ui/icons/PlayArrow';
import { Button, TextField } from '@material-ui/core';
// import InputAdornment from '@material-ui/core/InputAdornment';
import { ServerStore, FB_APP_ID } from '../../utils/ServerStore';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// import Input from '@material-ui/core/Input';
// import FormHelperText from '@material-ui/core/FormHelperText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faFacebookF } from '@fortawesome/free-brands-svg-icons';


export default function OnboardPage({ classes }) {
	React.useEffect(() => {
		NavControlService.showPrimaryNav(false);
	});

	const [ showFinalPage, setShowFinalPage ] = React.useState(false);

	const [ bootName,   setBootName   ] = React.useState("");
	const [ preferredVersion, setPreferredVersion ] = React.useState({
		id: process.env.NODE_ENV === 'production' ? 1 : 4,
		abbrv: 'niv',
		name: 'NIV'
	});
	const [ goalVersesDay/*, setGoalVersesDay */] = React.useState(5);

	// const [ bootLabel1, setBootLabel1 ] = React.useState("");
	// const [ bootLabel2, setBootLabel2 ] = React.useState("");
	// const [ bootLabel3, setBootLabel3 ] = React.useState("");

	const [ pending, setPending ] = React.useState(false);

	const saveAndBoot = async () => {
		const bootPacket = {
			name: (ServerStore.currentUser && ServerStore.currentUser.name) || bootName,
			preferredVersion,
			goalVersesDay,
			// label1: bootLabel1,
			// label2: bootLabel2,
			// label3: bootLabel3,
		};

		console.log({ bootPacket });

		setPending(true);

		await ServerStore.WelcomeDone(bootPacket);

		setPending(false);

		// For push notifications
		MyVersesCoreService.allowPermissionRequests();
		
		// Redirect to start page
		history.push('/home');
	}

	const [ fbIsConnected, setFbIsConnected ] = React.useState(ServerStore.currentUser && ServerStore.currentUser.isFacebookUser);

	const facebookConnected = (response) => {
		if(!response.accessToken) {
			return;
		}
		ServerStore.login(response.accessToken).then(store => {
			if(store) {
				setFbIsConnected(true);
			} else {
				window.Alert("Could not authenticate with server");
			}
		})
	}

	// Re-enable once FB re-approves which is waiting on Google to reapprove
	const showFbLogin = false;

	const showSigninWithApple = process.env.NODE_ENV !== 'production' ||
		(
			window.isPhoneGap &&
			window.cordova &&
			window.cordova.plugins &&
			window.cordova.plugins.SignInWithApple &&
			// Sign in with apple only compat with 13+
			mobileDetect.md.version('iOS') >= 13.0
		);

	const [ noFacebook, setNoFacebook ] = React.useState((!showSigninWithApple && !showFbLogin) || false);
	
	const signinWithApple = () => {
		setPending(true);

		// const fakeSuccessForTestingServer = {
		// 	user: 66,
		// 	identityToken: 'fakeSuccessForTestingServer',
		// 	email: '66'
		// };

		// return new Promise(resolve => {
		// 	setTimeout(() => {
		// 		ServerStore.loginApple(fakeSuccessForTestingServer).then(store => {
		// 			setPending(false);
		// 			if(store) {
		// 				setFbIsConnected(true);
		// 			} else {
		// 				alert("Server rejected login");
		// 			}

		// 			resolve();
		// 		})
		// 	}, 1000);
		// })

		// https://www.npmjs.com/package/cordova-plugin-sign-in-with-apple
		window.cordova.plugins.SignInWithApple.signin(
			{ requestedScopes: [0, 1] },
			apple => {
				console.log("Success from apple plugin, sending to server:" + JSON.stringify(apple));

				ServerStore.loginApple(apple).then(store => {
					setPending(false);
					if(store) {
						setFbIsConnected(true);
					} else {
						alert("Unable to authenticate");
					}
				})
			},
			err => {
				setPending(false);
				console.error(err);
				console.log(JSON.stringify(err));
				alert("Sign in with Apple failed" );
			}
		);
	}
	
	return (<div className={styles.root}>
		
		<div className={styles.bannerWrap}>
			<SimpleBanner/>
		</div>

		{pending ? 
			<SimpleSpinner fullpage={true}/>
			:
		<>

			{!showFinalPage ? <>

				<Button 
					className={styles.btnSkip}
					onClick={() => {
						setShowFinalPage(true);
					}}
				>
					Skip
				</Button>

				<AwesomeSlider 
					infinite={false}
					style={{
						'--content-background-color': 'transparent'
					}}
					fillParent={true}
				>

					<div className={styles.myCard}>
						<ICONS.SearchIcon/>
						<h1>Choose Verses</h1>
						<p>
							Choose from curated collections of verses or make your own verse packs
						</p>
					</div>

					<div className={styles.myCard}>
						<ICONS.PacksIcon/>
						<h1>Practice Regularly</h1>
						<p>
							MyVerses makes it easy and fun to practice verses wherever you are, and reminds you with push notifications to practice regularly
						</p>
					</div>

					<div className={styles.myCard}>
						<ICONS.ProgressIcon/>
						<h1>Track Progress</h1>
						<p>
							See how well you did meeting your goals and improve every day!
						</p>

						<Button 
							className={styles.btnGo}
							onClick={() => setShowFinalPage(true)}
						>
							Let's go!
						</Button>
					</div>

					{/* <div className={styles.myCard}>
			
						<p>
							We each only have 24 hours to use each day. How are you spending your time? Start learning verses now!
						</p>
						
						<Button 
							className={styles.btnGo}
							onClick={() => setShowFinalPage(true)}
						>
							Let's go!
						</Button>
					</div> */}
				</AwesomeSlider>
			</>
			:
			<div className={styles.finalPage}>

				{!noFacebook && !fbIsConnected ? <div 
					className={styles.connectFb}
				>
					{showFbLogin ?
						<FacebookLogin
							appId={FB_APP_ID}
							callback={facebookConnected}
							render={renderProps => <>{
								renderProps.isLoading ?
									<div className="fb-state-pending">
										<SimpleSpinner/>
									</div>
									:
								renderProps.hasError ? 
									<div className="fb-state-error">
										Sorry, {renderProps.error.message}
									</div>
									:
								renderProps.hasAuthResponse ? 
									<div className="fb-state-pending">
										<SimpleSpinner/>
									</div>
								:
								<>
									<Button
										className={clsx(styles.btnConnect, styles.fb)}
										onClick={renderProps.onClick}
									>
										<FontAwesomeIcon icon={faFacebookF} fixedWidth/>
										Connect with Facebook
									</Button>

								</>
							}</>}
						/>
					: ""}

					{showSigninWithApple ? 
						<Button
							className={clsx(styles.btnConnect, styles.apple)}
							onClick={signinWithApple}
						>
							<FontAwesomeIcon icon={faApple} fixedWidth/>
							Sign in with Apple
						</Button>
					: ""}

					<p>Sign up so you can access your verses on any device. <span 
						className={styles.noThanks}
						onClick={() => 
							setNoFacebook(true)
						}>
							(Click here for other sign up options.)
						</span>
					</p>
				</div> : ""}

				{noFacebook ?
					<TextField 
						label="Your Name"
						type="text"
						fullWidth
						required
						autoFocus
						onChange={e => setBootName(e.target.value)}
						className={styles.yourName}
					/>
				:""}

				{!noFacebook && ServerStore.currentUser && ServerStore.currentUser.name ? <p>
					Welcome, <b>{ServerStore.currentUser.name}</b>!
				</p> : ""}


				{noFacebook || (ServerStore.currentUser && ServerStore.currentUser.name) ?
				<>
					<FormControl 
						className={styles.preferredVersion}
						fullWidth
					>
						<InputLabel shrink={true}>
							What version of the Bible do you use?
						</InputLabel>

						<VersionInput
							className={styles.versionSelect}
							defaultValue={preferredVersion}
							allowUnderline={true}
							onChange={ver => setPreferredVersion(ver)}
						/>
					</FormControl>

					{/* <h5>How many verses a day do you want to aim for?</h5> */}
					{/* <InputLabel>
						How many verses a day do you want to aim for?
					</InputLabel> */}

					{/* <FormControl className={styles.goalVersesDay}>
						<InputLabel>
							How many verses a day do you want to aim for?
						</InputLabel>
						<Input
							margin="dense"
							// label="How many verses a day do you want to aim for?"
							type="number"
							step={1}
							min={1}
							max={10000}
							fullWidth
							defaultValue={goalVersesDay}
							onChange={ev => setGoalVersesDay(
								Math.min(1000, Math.max(1, parseFloat(ev.target.value)))
							)}
							className={styles.goalVersesDay}
							endAdornment={<InputAdornment position="end">Verses</InputAdornment>}
						/>
						<FormHelperText>
							This will help you measure if you're hitting your goals. Many people start out at 3 verses day and work up from there. You can always change this later in Settings.
						</FormHelperText>
					</FormControl>
					 */}

					{/* <TextField
						margin="dense"
						// label="How many verses a day do you want to aim for?"
						type="number"
						step={1}
						min={1}
						max={10000}
						fullWidth
						defaultValue={goalVersesDay}
						onChange={ev => setGoalVersesDay(parseFloat(ev.target.value))}
						className={styles.goalVersesDay}
						helperText="This will help you measure if you're hitting your goals. Most people start out at 3 verses day and work up from there. You can always change this later in Settings."
						InputProps={{
							endAdornment: <InputAdornment position="end">Verses/Day</InputAdornment>,
						}}
					/> */}
					
					

					{((ServerStore.currentUser && ServerStore.currentUser.name) || bootName) 
						&& preferredVersion
						// && goalVersesDay
						? <>
						<h4>Great, now let's try some verses!</h4>
						<Button 
							className={styles.btnGo}
							onClick={() => saveAndBoot()}
						>
							Start Practicing
						</Button>
					</> : ""}
				</> : ""}

			</div>}
		</>}
	</div>)

};
