import React from 'react';
import styles from './AddPackDialog.module.scss';
import { ServerStore } from '../utils/ServerStore';
import history from 'utils/history';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';


export default function AddPackDialog({
	open,
	onCancel,
	onSave
}) {
	const onClose = () => onCancel && onCancel();

	const [ name, setName ] = React.useState("");
	const [ loading, setLoading ] = React.useState();

	const saveChanges = async () => {
		setLoading(true);
		const pack = await ServerStore.CreatePack({ name });
		setLoading(false);

		ServerStore.metric("app.add_pack.saved", null, {
			name,
			pack: pack.id
		});

		if(onSave) {
			onSave(pack);
		} else {
			history.push(`/pack/${pack.id}/new`);
		}
	}

	return (<Dialog 
		className={styles.AddPackDialog}
		open={open || false} 
		onClose={onClose}
	>
		<DialogTitle>
			New Verse Pack
		</DialogTitle>
		<DialogContent className={styles.fields}>
			{loading ? 
				<div className={styles.loadingSpinner}>
					<CircularProgress color="inherit" size={20} />
				</div>
			:
				<TextField
					type="text"
					label="Pack Name"
					autoFocus
					onChange={ev => setName(ev.target.value)}
				/>
			}
		</DialogContent>
		{!loading ? 
			<DialogActions className={styles.actions}>
				<Button 
					onClick={onClose}
					color="default"
					data-testtarget='cancel'
				>
					Cancel
				</Button>
				<Button 
					onClick={saveChanges}
					color="primary"
					disabled={!name}
				>
					Create Pack
				</Button>
			</DialogActions>
		:""}
	</Dialog>);
}