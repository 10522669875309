// import React from 'react';
import HomePage      from 'pages/HomePage/HomePage';
import PacksHomePage from 'pages/PacksHomePage/PacksHomePage';
// import ReportsPage   from 'pages/ReportsPage/ReportsPage';
import SettingsPage  from 'pages/SettingsPage/SettingsPage';
import SearchPage    from 'pages/SearchPage/SearchPage';
import LoginPage     from 'pages/LoginPage/LoginPage';
import OnboardPage   from 'pages/OnboardPage/OnboardPage';
import PackPage      from 'pages/PackPage/PackPage';
import ProgressPage  from 'pages/ProgressPage/ProgressPage';
import PrivacyPage   from 'pages/PrivacyPage/PrivacyPage';
import DeleteMyData  from 'pages/DeleteMyData/DeleteMyData';

// import { NavControlService } from '../components/PrimaryNav';

// // Placeholders so the app compiles before we create these pages
// const fake = (name) => () => {
// 	React.useEffect(() => {
// 		NavControlService.showPrimaryNav(true);
// 	});
// 	return <h1>{name}</h1>
// };

// // const TrainingPage = fake("TrainingPage");
// // const SearchPage = fake("SearchPage");
// // const PacksHomePage = fake("PacksPage");
// const ProgressPage = fake("ProgressPage");

const Routes = {
	'/':          { component: HomePage,      exact: true },
	'/privacy':   { component: PrivacyPage,   exact: true },
	'/deletemydata': { component: DeleteMyData, exact: true },
	'/login':     { component: LoginPage,     exact: true },
	'/onboard':   { component: OnboardPage,   exact: true },

	'/search':    { component: SearchPage,    private: true },
 
	'/home':      { component: PacksHomePage, private: true },
	
	'/pack/:id':  { component: PackPage,      private: true },
	// catch manual typos
	'/packs/:id': { component: PackPage,      private: true },
	
	'/progress':  { component: ProgressPage,  private: true },

	'/settings':  { component: SettingsPage,  private: true },

};

export default Routes;