import React from 'react';
import styles from './VerseBadgeLevel.module.scss';
import clsx from 'clsx';
import CircleProgress from './CircleProgress';
import StarIcon from '@material-ui/icons/CheckCircle';

export default function VerseBadgeLevel({ verse, className }) {
	const stats = (verse && verse.stats) || {},
		badgeLevelText = stats.badgeLevel + "",
		hasStar        = stats.badgeLevel >= 5,
		badgeLevel     = (stats.badgeLevel / 5) * 100,
		badgeProgress  = (
			(stats.vlAvgAccuracy || stats.avgAccuracy)
			*
			(stats.windowCount / 2)
		) * 100; 

	// console.log("VerseBadgeLevel: got verse:", verse);

	return stats.badgeLevel ? <div className={clsx(
		styles.circles,
		className,
		hasStar && styles.hasStar,
	)}>
		{!hasStar ? <>
			<CircleProgress
				value={badgeLevel}
				className={styles.badgeCircle}
				size={20}
			/>
			<CircleProgress
				value={badgeProgress}
				className={styles.progressCircle}
				size={28}
			/>
		</> : ""}
		<div className={clsx(styles.text, 'VerseBadgeLevel-text')}>
			{hasStar ? <StarIcon/> : badgeLevelText}
		</div>
	</div> : <></>;
}