import React from 'react';
import styles from './HomePage.module.scss';
import Head from 'components/Head';
import clsx from 'clsx';

import { ServerStore } from 'utils/ServerStore';
import { MyVersesCoreService } from 'utils/MyVersesCoreService';
// import { useRemoteData } from 'utils/useRemoteData';
// import { isPhoneGap } from 'utils/isPhoneGap';
import { NavControlService } from 'components/PrimaryNav';
import history from 'utils/history';

import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import SimpleSpinner from 'components/SimpleSpinner';
import { SimpleBanner } from './SimpleBanner';


export default withRouter(function HomePage({ history, ...props }) {
	const [ loading, setLoading ] = React.useState(true);
	
	(async () => {
		const user = await ServerStore.attemptAutoLogin();
		if(user) {
			MyVersesCoreService.allowPermissionRequests();
			MyVersesCoreService.showWhatsNew();
			
			history.push('/home');
		} else {
			NavControlService.showPrimaryNav(false);
			setLoading(false);
		}
	})();

	if(loading) {
		return <SimpleSpinner fullpage={true}/>
	}

	return (<>
		<Head title="MyVerses"/>
		<div className={clsx(
				styles.root,
				styles.rootWelcomeMode,
			)}>
			<div className={styles.centeringBlock}>
				<WelcomePanel/>
			</div>
		</div>
	</>);
});

function WelcomePanel({ ...props }) {
	return (<>
		<div className={styles.WelcomePanel}>
			<SimpleBanner/>

			<div className={styles.intro}>
				{/* <p>
					<b>Hey, just so you know, this project is in serious development, and there are some pages which aren't done yet! This site is live for beta testers, and we'll update this as soon as it's ready for general public use!
					</b>
				</p> */}
				<p>
					You're amazing, you've taken the first step toward memorizing Bible Verses!
				</p>
				<p>
					Choose verses, memorize, and stay accountable with MyVerses!
				</p>
			</div>

			<ButtonBase className={clsx(styles.smallButton, styles.greenBackground)}
				onClick={() => history.push('/onboard')}
			>
				<FontAwesomeIcon icon={faChevronRight} fixedWidth/> Get Started
			</ButtonBase>

			<Button onClick={() => history.push('/login')}>
				Login
			</Button>

			{/* {!isPhoneGap ? <>
				<AppStoreLinkWidget/>
			</> : ""} */}
		</div>
	</>);
}