import React from 'react';
import clsx from 'clsx';
import history from 'utils/history';
import moment from 'moment';
import cx from 'clsx';
import styles from './PackHomeCard.module.scss';
import { getItemColor, computeTextColor } from 'utils/itemColorHelper';
import { Card, CardActionArea, CardContent, ButtonBase, Tooltip } from '@material-ui/core';
import SimpleSpinner from 'components/SimpleSpinner';
import CircleProgress from 'components/CircleProgress';
import { ServerStore } from 'utils/ServerStore';
// import StatsBar from 'components/StatsBar';

export default function PackHomeCard({
	pack,
	verse,
	metricKey,
	// showDescription=false,
	// showCta=false,
	className,
	variant = "small",
	onVerseClick,
	badgeText,
	badgeClass,
	badgeTitle = ''
}) {
	// const mediaStyles = useCoverCardMediaStyles();
	// const shadowStyles = useLightTopShadowStyles();

	const [ loading ] = React.useState(false);

	const onClick = async () => {
		ServerStore.metric('app.pack_card.' + metricKey + '.clicked', null, {
			pack: pack && pack.id,
			verse: verse && verse.cachedRef
		});

		if(verse) {
			onVerseClick();
		} else {

			setTimeout(
				() => history.push(`/pack/${pack.id}`),
				0
			);
		}
	}

	if(variant === 'small') {
		const cursor = (pack || {}).cursor || {};
		const stats = (pack || {}).userStats;


		return <ButtonBase 
			className={cx(styles.root, className, styles.variantSmall, verse && styles.verse)}
			onClick={onClick}
		>
			{loading && <SimpleSpinner fullpage={true} />}
			<Card
				data-hasprogress={cursor.progressPercent ? "true" : "false"}
				style={ pack ? {
					'--pack-color': getItemColor(pack, pack.imageMeta),
					'--cover-color': getItemColor(pack.imageMeta),
					'--cover-text': computeTextColor(getItemColor(pack.imageMeta)),
					'--cover-shade': computeTextColor(getItemColor(pack.imageMeta), true),
					'--progress-percent': `${cursor.progressPercent || 0}%`
				} : {} }
			>
				{pack && pack.imageUrl ?
					<div 
						style={{
							// backgroundImage: `url("${pack.imageUrl}")`
						}}
						className={styles.media}
					/>
				: ""}
				{cursor.progressPercent ? 
					<div className={styles.progressPercent}/>
				: ""}
				{verse ? <div className={styles.ref}>
						{verse.cachedRef}
				</div> : ""}
				{badgeText ? 
					<Tooltip title={badgeTitle || badgeText}>
						<div className={clsx(styles.badgeText, badgeClass)}>
							<label>
								{badgeText}
							</label>
						</div>
					</Tooltip>
				: ""}
				{pack && pack.name ? 
					<div className={styles.letter}>
						<div className={styles.text}>
							{(pack.name+"").substring(0,1)}

							<CircleProgress 
								value={(((stats || {}).badgeLevel || 0) / 5) * 100}
								className={styles.badgeCircle}
								size={40}
							/>
							<CircleProgress 
								value={cursor.progressPercent || 0}
								className={styles.progressCircle}
								size={50}
							/>
							
						{/* <CircleProgress 
								value={cursor.progressPercent || 0}
								size={40}
							/>
							{stats && stats.avgAccuracy > 0 ? 
								<CircleProgress 
									value={stats.avgAccuracy * 100}
									size={50}
									className={styles.outerCircle}
								/>
							:""} */}
						</div>
					</div>
				:""}
			</Card>
			{/* {verse ? */}
			{pack ?
				<div className={styles.name}>
					{(pack && pack.name) || ' '}
				</div>
			:""}
				{/* {verse ? verse.text : pack.name} */}

		</ButtonBase>;
	}

	const stats = pack.userStats;

	return (<>
		<Card
			className={cx(styles.root, className, styles.variantLage)}
			style={{
				'--cover-color': getItemColor(pack.imageMeta),
				'--cover-text': computeTextColor(getItemColor(pack.imageMeta)),
				'--cover-shade': computeTextColor(getItemColor(pack.imageMeta), true),
			}}
		>
			<CardActionArea
				onClick={onClick}
				className={styles.actionArea}
			>
				{pack.imageUrl ?
					<div 
						style={{
							backgroundImage: `url("${pack.imageUrl}")`
						}}
						className={styles.media}
					/>
				: ""}

				<CardContent className={styles.content}>
					
					<div className={styles.textWrap}>
						<h1 className={styles.title}>{pack.name}</h1>
						{/* {pack.description && <div
							className={styles.diz}>
							<div>{pack.description}</div>
						</div>} */}
					</div>

					{/* <div className={clsx(styles.stats, styles.statsBottom)}>
						<div data-stat="numVerses">
							<span>{pack.numVerses}</span>
							<label>verses</label>
						</div>
						{stats ? <>
							<div data-stat="avgComprehension">
								<span>
									{Math.round(stats.avgComprehension * 100)}%
								</span>
								<label>score</label>
							</div>
							
						</> : ""}
					</div>

					{stats ? 
						<div className={clsx(styles.stats, styles.statsTop)}>
							<div data-stat="lastTested">
								<span>
									{moment(stats.lastTested).fromNow().replace('minutes','min')}
								</span>
								<label>last practiced</label>
							</div>
							<div data-stat="numTests">
								<span>
									{stats.numTests} x
								</span>
								<label>practices</label>
							</div>
						</div>
					: ""} */}

					{/* <StatsBar stats={pack.userStats} numVerses={pack.numVerses}/> */}

					<div className={clsx(styles.stats, styles.statsBottom)}>
						<div data-stat="numVerses">
							<span>{pack.numVerses}</span>
							<label>verses</label>
						</div>
						{stats ? <>
							<div data-stat="avgComprehension">
								<span>
									{Math.round(stats.avgComprehension * 100)}%
								</span>
								<label>score</label>
							</div>
							<div data-stat="lastTested">
								<span>
									{moment(stats.lastTested).fromNow()
										.replace('hours', 'hrs')
										.replace('minutes','min')}
								</span>
								<label>last practiced</label>
							</div>
							<div data-stat="numTests">
								<span>
									{stats.numTests} x
								</span>
								<label>practices</label>
							</div>
						</> : ""}
					</div>

				</CardContent>
			</CardActionArea>
		</Card>
	</>)

	// return (<>
	// 	<ButtonBase
	// 		className={cx(styles.root, className)}
	// 		style={{
	// 			'--cover-color': getItemColor(pack.imageMeta),
	// 			'--cover-text': computeTextColor(getItemColor(pack.imageMeta)),
	// 			'--cover-shade': computeTextColor(getItemColor(pack.imageMeta), true),
	// 		}}
	// 		onClick={onClick}
	// 	>
	// 		{pack.imageUrl ?
	// 			<div 
	// 				style={{
	// 					backgroundImage: `url("${pack.imageUrl}")`
	// 				}}
	// 				className={styles.media}
	// 			/>
	// 		: ""}
			
	// 		<div className={styles.textWrap}>
	// 			<h1 className={styles.title}>{pack.name}</h1>
	// 			{/* {pack.description && <div
	// 				className={styles.diz}>
	// 				<p>{pack.description}</p>
	// 			</div>} */}

	// 			<div className={styles.stats}>
	// 				<div data-stat="numVerses">
	// 					<span>{pack.numVerses}</span>
	// 					<label>verses</label>
	// 				</div>
	// 				{stats ? <>
	// 					<div data-stat="avgComprehension">
	// 						<span>
	// 							{Math.round(stats.avgComprehension * 100)}%
	// 						</span>
	// 						<label>score</label>
	// 					</div>
	// 					<div data-stat="lastTested">
	// 						<span>
	// 							{moment(stats.lastTested).fromNow().replace('minutes','min')}
	// 						</span>
	// 						<label>last practiced</label>
	// 					</div>
	// 					<div data-stat="numTests">
	// 						<span>
	// 							{stats.numTests} x
	// 						</span>
	// 						<label>practices</label>
	// 					</div>
						
	// 				</> : ""}
	// 			</div>
	// 		</div>
	// 	</ButtonBase>
	// </>)
	
	// return (<>
	// 	<Card 
	// 		className={cx(styles.root, shadowStyles.root)}
	// 		style={{
	// 			'--cover-color': getItemColor(pack.imageMeta),
	// 			'--cover-text': computeTextColor(getItemColor(pack.imageMeta)),
	// 		}}
	// 	>
	// 		{pack.imageUrl ?
	// 			<CardMedia 
	// 				classes={mediaStyles}
	// 				image={pack.imageUrl}
	// 				className={styles.media}
	// 			/>
	// 		: ""}
	// 		<CardActionArea onClick={() => history.push(`/pack/${pack.id}`)}>
	// 			<CardContent className={styles.content}>
	// 				<div className={styles.textWrap}>
	// 					<h1 className={styles.title}>{pack.name}</h1>
	// 					{showDescription ? <p>{pack.description}</p> : ""}
	// 				</div>
	// 				{showCta ? 
	// 					<Typography className={styles.cta} variant={'overline'}>
	// 						View Verses
	// 					</Typography>
	// 				: ""}
	// 			</CardContent>
	// 		</CardActionArea>
	// 	</Card>
	// </>)
}