// Import ServerStore here so we can send the token to the server
import { ServerStore }  from "./ServerStore";
import { mobileDetect } from './mobile-detect';

export const CHANNEL_LIST = {
	// see https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/API.md#channel-properties
	// for list of properties valid here

	reminders: {
		description: "Reminders",
		importance: 5, // 5 = highest
		visibility: 1,
	},

	tips: {
		description: "Tips from MyVerses",
		importance: 3,
		visibility: 1,
	},

	devel: {
		description: "Notifications from Developers",
		importance: 5,
		visibility: 1
	}
};

// Apply id to hash for completeness and compliance with requirements
Object.keys(CHANNEL_LIST).forEach(id => {
	CHANNEL_LIST[id].id = id;
})

export async function initNativePushPlugin({ onMessage, onMessageClick, onPermissionNeeded }) {
	const PushNotification = window.PushNotification;
	if(!PushNotification) {
		console.warn("[initNativePushPlugin] PushNotification not defined on window, not initalizing native push");
		return;
	}

	// On iOS, when we call PushNotification.init() the first time,
	// it prompts the user for permission. So, on iOS we want to re-use the
	// onPermissionNeeded deffer flow we built for FirebasePushPlugin 
	// to use our own custom UI to inform the user before the OS 
	// prompts the user out of the blue.
	if(mobileDetect.md.is('iPhone')) {
		
		// Check to see if we already have permission to push push notify
		// (only affects iOS - android has it by default)
		const hasPermission = await new Promise(resolve => {
			PushNotification.hasPermission(data => {
				resolve(data.isEnabled);
			})
		});

		// If we DON'T have permission yet, reuse the onPermissionNeeded flow
		// we built for FirebasePushPlugin to allow the main app to
		// defer permission requests until user granted.
		// (The .init method below is what triggers the permission request,
		// so we don't trigger .init on iOS until onPermissionNeeded resolves)
		if(!hasPermission && onPermissionNeeded)
			await onPermissionNeeded();
	}
	
	const push = PushNotification.init({
		android: {
			icon:      "push_icon", // defined in config.xml as a drawable
			iconColor: "#FFFAFF",

			// Per https://github.com/phonegap/phonegap-plugin-push/issues/1867
			senderID: "70273693140"
		},

		ios: {
			alert: "true",
			badge: "true",
			sound: "true",

			// Per https://github.com/phonegap/phonegap-plugin-push/issues/816#issuecomment-212449795
			senderID: "70273693140"
		},

		windows: {}
	});

	// const callback = (name) => {
	// 	return (data) => {
	// 		console.log("[PushNotification] (action:"+name+"): data:", data);
	// 	};
	// };

	window.pushActions = {
		click: (data) => {
			console.log("[PushNotification] Got [click] action with data:", data);

			onMessageClick && onMessageClick(data);
		},

		// workTrip: callback('workTrip'),
		// personlTrip: callback('personalTrip'),
		// dismiss: callback('dismiss'),
	};

	// Well-known hook for MyVersesCoreservice
	if (window.popuplatePushActions)
		window.popuplatePushActions();

	push.on('registration', data => {
		// data.registrationId
		console.log("[PushNotification] Sending to server: new registrationId: " + data.registrationId);
		
		ServerStore.storePushToken(data.registrationId);

		// _this.get('pushMessages').sendTokenToServer(data.registrationId, 'native');
	});

	push.on('notification', async data => {
		console.log("[PushNotification] notification event: " + JSON.stringify(data, null, 2));
		// data.message,
		// data.title,
		// data.count,
		// data.sound,
		// data.image,
		// data.additionalData

		// Example payload
		// {
		//   "from": "70273693140",
		//   "collapse_key": "do_not_collapse",
		//   "notification": {
		//     "title": "Portugal vs. Denmark",
		//     "body": "5 to 1",
		//     "icon": "firebase-logo.png",
		//     "click_action": "http://localhost:8081"
		//   }
		// }

		/*
		var data =
		{
			"image":"https://www.gravatar.com/avatar/787b1d296170b3a1e7ff05f61f46ad9b?s=192&d=mm",
			"sound":"default",
			"title":"Hello, World from pid 18751",
			"message":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus dignissim augue nec sem semper, a commodo nulla scelerisque.",
			"additionalData":{
				"surveyID":"ewtawgreg-gragrag-rgarhthgbad",
				"summaryText":"There are %n% notifications from app",
				"priority":"2",
				"icon":"secondary_icon",
				"notId":"3",
				"style":"inbox",
				"click_action":"https://app.ourredone.com/#/alerts",
				"google.message_id":"0:1501018399839560%db563c1df9fd7ecd",
				"appAlertImage":"https://www.gravatar.com/avatar/787b1d296170b3a1e7ff05f61f46ad9b?s=192&d=mm",
				"appAlertId":"3",
				"ledColor":[
					0,
					2,
					117,
					216
				],
				"coldstart":false,
				"foreground":false
			}
		}
		*/

		// if(data.additionalData.appUserId != this.get('feathers.user.id')) {
		// 	console.warn("Alert user not targeted at current logged-in user, not showing alert if possible");
		// 	return;
		// }

		// Create payload that emulates firebase's push payload in browser
		const fauxDataPayload = {
			title: data.title,
			body:  data.message,
			image: data.image,
			data:  data.additionalData,
			sound: data.sound,


			click_action:  data.additionalData.click_action,

			// // Used for in-page appConfirm alert 'ok' button text
			appClickActionText: data.additionalData.appClickActionText,

			appAlertImage: data.additionalData.appAlertImage,
			appAlertId:    data.additionalData.appAlertId,
			appUserId:     data.additionalData.appUserId,
		};


		// From docs: .foreground = Whether the notification was received while the app was in the foreground
		if(!data.additionalData.foreground) {
			console.log("Notification clicked while in background:", data);

			onMessageClick && onMessageClick({

				// Destructure payload
				...fauxDataPayload,

				// Used by PushNotifyService to handle properly
				clickedInBackground: true,

				// Add flag so our logging metrics know where this came from
				fromNativePushPluginBackground: true,

			});

		} else {
			// Since .foreground was true, we received the push while the app is
			// in the foreground (duh), so there would be NO notification in the native notification shade,
			// therefore, we will use pushMessages to show an in-app popup notification
			
			onMessage && onMessage(fauxDataPayload);
		}
	});

	push.on('error', function(e) {
		console.log("[PushNotification] error: ", e);
		// e.message
	});

	// Sync list of channels to the device
	// based on https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/API.md#example-4
	PushNotification.listChannels(channels => {
		// Check and remove any channels that we don't have defined in CHANNEL_LIST
		for (let channel of channels) { // eslint-disable-line no-unused-vars
			// PushPluginChannel autocreated by plugin
			// In the future, we MAY try to see if we can delete this channel, because it does look a bit unprofessional
			// to have that wierd channel name in the app notifications menu.
			// Further testing and development needed to see if we need this channel, because
			// this channel (PushPluginChannel) is used if an alert is sent with an empty channel, so it's basically
			// a wildcard channel. That wildcard behaviour is handled native plugin - we could fork the plugin to change,
			// but we're (I'm) lazy right now.
			if(channel.id !== 'PushPluginChannel') { 
				console.log(`[channel sync] found: ID: ${channel.id} Description: ${channel.description}`);

				const def = CHANNEL_LIST[channel.id];
				if(def) {
					// we're good
					def._exists = true;
				} else {
					// Not defined in our list, might be old name, remove from device, silently fail/succeed
					// PushNotification.deleteChannel(()=>{},()=>{}, channel.id);
					// Might cause bugs with native code so dont do this for now...
				}
			}
		}

		// Create any channels not already defined on the device from our CHANNEL_LIST
		Object.values(CHANNEL_LIST).forEach(def => {
			if(!def._exists) {
				// eslint-disable-next-line no-loop-func
				PushNotification.createChannel(() => {
					console.log("[channel sync] Created channel ", def);
				// eslint-disable-next-line no-loop-func
				}, error => {
					console.error("[channel sync] Error creating channel:", error, def);
				}, def);
			}
		});
	});

	return push;
}