const affirmations = `
	Good job!
	Wow, you did awesome!
	Who taught you so well?!
	Impressive...
	You should teach classes on this!
	You're smart and funny and ... yeah, you're pretty cool.
	You deserve a nap for what you just did!
	Brilliant! Keep up the good work!
`.split("\n").map(x => x.trim()).filter(x => x);

const affirmationsActiveTense = `
	You're beautiful and I care about you and I hope you have a great day.
	You're doing awesome, keep up the good work!
	You're doing awesome!
	You're smart and funny and ... yeah, you're pretty cool.
	You're doing amazingly well!
	You are the only you. (Ps 139)
	You’re perfectly made as you are. Be your best you...
	When in doubt follow the path that warms your heart and lights you up. (Ps 119)
	You were created for a purpose beyond your wildest imagination.
`.split("\n").map(x => x.trim()).filter(x => x);

const encouragements = `
	You can do it!
	Keep going, you got this!
	I believe in you, you go this!
	Don't worry about anything, just pause, pray (Psalm 33.20), and keep going!
`.split("\n").map(x => x.trim()).filter(x => x); // todo: Add info:bible support to encouragements array


export function randomAffirmation(opts = { activeTense: false }) {
	const list = opts.activeTense ? affirmationsActiveTense: affirmations; 	
	return list[Math.floor(Math.random() * list.length)];
}

export function randomEncouragement() {
	return encouragements[Math.floor(Math.random() * encouragements.length)];
}