import TestManager from './TestManager';
import AttemptManager from './AttemptManager';
import SequenceManager from './SequenceManager';
import AnswerManager from './AnswerManager';

const GuardSymbol = Symbol('@@ProctorInternalGuard@@');

// If running in a test,
// Mock server store for testing proctor from test/proctor.test.js
let ServerStore;
if(process.env.NODE_ENV === 'test') {
	ServerStore = class {
		static GetPackVerses(id) {
			return this.mockedVerseList;
		}
	};
	ServerStore.mockedVerseList = [];
} else {
	// Load the real ServerStore since not running in a test
	ServerStore = require('../ServerStore').ServerStore;
}

// Export so test framework can mock expected verses
// I would rather export this conditionally, but ES6 does not allow
// wrapping 'export' with 'if'
export { ServerStore };

export default class Proctor {

	static async instance(pack) {
		if(!this.instanceCache) {
			this.instanceCache = {};
		}

		if(!pack || !pack.id) {
			throw new Error("Invalid pack")
		}

		const key = [ pack.id ].join('');
		if(this.instanceCache[key]) {
			return this.instanceCache[key];
		}

		const inst = (
			this.instanceCache[key] = new Proctor(pack, GuardSymbol)
		);
		
		// Don't return until init completed so we know verses are ready
		await inst.init();

		// Just for debugging
		// window.proctors = this.instanceCache;
		
		return inst;
	}

	static destroyInstance(pack) {
		if(!pack || !pack.id) {
			return;
		}

		const key = [ pack.id ].join('');
		if (this.instanceCache &&
			this.instanceCache[key]) {
			delete this.instanceCache[key];
		}
	}

	constructor(pack, guardSymbol) {
		if(guardSymbol !== GuardSymbol) {
			throw new Error("You cannot create a Proctor directly, please use the static Proctor.instance(packId) method to get a Proctor instance so we can cache and re-use proctors internally");
		}

		this.pack = pack;
		this.packId = pack.id;
		this.packVerses = pack.verses;
		this.packCursor = pack.cursor;
		
		// We load all verses so managers can reference and not
		// have to load it themselves
		// console.log("Proctor init, got verses:", this.packVerses);

		// Async init taken care of in init() below
		this.sequenceManager = new SequenceManager(this);
		this.testManager     = new TestManager(this);
		this.attemptManager  = new AttemptManager(this);
		this.answerManager   = new AnswerManager(this);
	}	

	async init() {
		// Init all managers
		await this.sequenceManager.init();
		await this.testManager.init();
		await this.attemptManager.init();
		await this.answerManager.init();
	}

	async computeOverallSummary() {
		if(this.overallSummaryCache) {
			return this.overallSummaryCache;
		}

		return (this.overallSummaryCache = 
			await ServerStore.GetPackSummary(this.packId)
		);
	}

	// External API, delegates to managers //
	async startTest(testType=null) {
		if(this.attemptManager.currentAttempt()) {
			await this.stopAttempt();
		}

		ServerStore.metric("app.proctor.test.start", this.packId, {
			isSinglePracticePack: this.pack.systemIdent === "singlePracticePack"
		});
		
		testType = await this.sequenceManager.startSequence(testType);
		return await this.testManager.startTest(testType);
	}

	async numVerses() {
		return await this.sequenceManager.numVerses();
	}

	getFirstVerse() {
		const { firstVerse, ...data } = this.sequenceManager.getFirstVerse();
		this.startAttempt(firstVerse);

		return {
			firstVerse,
			...data
		};
	}

	startAttempt(verse) {
		ServerStore.metric("app.proctor.attempt.start", this.packId, {
			cachedRef: verse && verse.cachedRef
		});

		return this.attemptManager.startAttempt(verse);
	}

	getAnswerOptions(verse, options) {
		return this.answerManager.getAnswerOptions(
			verse, options
		);
	}

	tryAnswer(verse, possibleAnswer, answerIndex) {
		const {
			bad,
			good,
			incomplete,
			lastAttemptedWasCorrect,
			rawTextMode,
			accuracy,
			...data
		} = this.answerManager.tryAnswer(
			verse, possibleAnswer, answerIndex
		);
		
		if(rawTextMode) {
			// In raw text mode, answer manager tells us explicit
			// accuracy by counting blanks internally
			this.attemptManager.logAnswerAccuracy(accuracy);
		} else {
			// In non-rawText mode, we count the good/bad attempts
			// implicitly by counting calls to tryAnswer()
			if(!incomplete) {
				this.attemptManager.logAnswerAttempt({ 
					bad, good
				});
			} else
			if(lastAttemptedWasCorrect !== undefined) {
				this.attemptManager.logAnswerAttempt({ 
					bad: !lastAttemptedWasCorrect,
					good: lastAttemptedWasCorrect
				});
			}
		}

		return { 
			incomplete,
			bad, 
			good,
			lastAttemptedWasCorrect,
			...data
		};
	}

	undoLastTryAnswer(verse) {
		ServerStore.metric('app.proctor.answer.undo');
		return this.answerManager.undoLastTryAnswer(verse);
	}

	stopAttempt(discardAttempt = false) {
		const attempt = this.attemptManager.stopAttempt();


		ServerStore.metric("app.proctor.attempt.stop", this.packId, { discardAttempt });
		
		if(!discardAttempt && attempt) {
			// console.log("[Proctor.stopAttempt] logging attempt to internal list:", attempt);
			return this.testManager.addAttempt(attempt);
		}
	}

	getNextVerse(loop = false) {
		if(this.attemptManager.currentAttempt()) {
			this.stopAttempt();
		}

		const { end, nextVerse, ...data } = 
			this.sequenceManager.getNextVerse(loop);

		if(!end) {
			this.startAttempt(nextVerse);
		}
		
		return {
			end,
			nextVerse,
			...data
		};
	}

	gotoVerseIndex(idx) {
		if(this.attemptManager.currentAttempt()) {
			this.stopAttempt();
		}

		const { end, nextVerse, ...data } = 
			this.sequenceManager.gotoVerseIndex(idx);

		if(!end) {
			this.startAttempt(nextVerse);
		}
		
		return {
			end,
			nextVerse,
			...data
		};
	}

	getPrevVerse(loop = false) {
		if(this.attemptManager.currentAttempt()) {
			this.stopAttempt();
		}

		const { end, prevVerse, ...data } = 
			this.sequenceManager.getPrevVerse(loop);

		if(!end) {
			this.startAttempt(prevVerse);
		}
		
		return {
			end,
			prevVerse,
			...data
		};
	}

	async finishTest(summary = null) {
		if(this.attemptManager.currentAttempt()) {
			await this.stopAttempt();
		}

		ServerStore.metric("app.proctor.test.stop", this.packId);

		return await this.testManager.finishTest(summary);
	}
}

// Assign classes as members for external access
Proctor.SequenceManager = SequenceManager;
Proctor.TestManager = TestManager;
Proctor.AttemptManager = AttemptManager;
Proctor.AnswerManager = AnswerManager;