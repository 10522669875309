import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Backdrop } from '@material-ui/core';

export default function SimpleSpinner({ fullpage = false, icon = faCircleNotch, style = {}, className = "" }) {

	const fp = {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: "flex",
		flexDirection: "row",
		minHeight: "100%",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		color: "white",
		zIndex: 2999,
	};

	const WrapIcon = ({ children }) => {
		if(fullpage) {
			return (
				<Backdrop 
					open={true}
					style={{ zIndex: 2998 }}
				>
					{children}
				</Backdrop>
			);
		} else {
			return children;
		}
	}

	return (<div className={className} style={{
			color: 'black',
			...(style || {}),
			...(fullpage ? fp : {})
		}}>
		<WrapIcon>
			<FontAwesomeIcon icon={icon} spin/>
		</WrapIcon>
	</div>);
}
