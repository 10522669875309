import { mean, std } from 'mathjs/number';
import { round2digits } from './utils';

// Wrap timing in a dedicated accessor
// so we can mock it in testing to provide deterministic values
class TimingHelper {
	start() {
		return Date.now();
	}

	end() {
		return Date.now();
	}
}

/**
 * AttemptManager is responsible for data management about a single attempt
 * for a single verse in a single test.
 * 
 * - Marking the start of an attempt
 * - Marking the end of an attempt
 * - Recording the outcome of the attempt (pass/fail)
 * - Classifying the difficulty etc
 */
export default class AttemptManager {
	constructor(proctor) {
		this.proctor = proctor;

		// Wrap timing in a utility for optional mocking during tests
		this.timing = new TimingHelper();
	}

	async init() {

	}

	startAttempt(verse) {
		if(!verse || !verse.id) {
			console.log({ verse })
			throw new Error("No ID on first arg");
		}

		if(this.attempt) {
			if(this.attempt.verse === verse) {
				return this.attempt;
			}

			throw new Error("Must stop current attempt");
		}

		this.attempt = {
			timeStart: this.timing.start(),
			verse,
			wrongCount: 0,
			goodCount: 0,
			hintsCount: 0
		}
	}

	currentAttempt() {
		return this.attempt;
	}

	logAnswerAttempt({ bad, good }) {
		if(!this.attempt) {
			console.warn("AttemptManager: No attempt currently, what happened??");
			return;
		}

		if(good) {
			this.attempt.goodCount ++;
		} else {
			this.attempt.wrongCount ++;
		}

		this.calculateCoreStats();
	}

	stopAttempt() {
		if(!this.attempt) {
			return null;
		}

		this.finalizeCurentAttemptStats();	

		// console.warn("** stop attempt")
		
		const finalizedAttempt = this.attempt;
		this.attempt = null;
		
		return finalizedAttempt;
	}

	finalizeCurentAttemptStats() {
		// const { attempt } = this;

		this.calculateCoreStats();
	}

	calculateCoreStats() {
		const { attempt } = this;

		// timeLength MUST be set BEFORE classifyDifficulty
		this.attempt.timeEnd    = this.timing.end();
		this.attempt.timeLength = this.attempt.timeEnd - this.attempt.timeStart;

		this.classifyDifficulty(attempt);
		this.calculateAccuracy(attempt);

		// calculateComprehension must be done last, after difficulty and accuracy
		// because it combines both values into one
		this.calculateComprehension(attempt);

		console.log(`[AttemptManager.calculateCoreStats] (${attempt.goodCount} vs ${attempt.wrongCount}, hints: ${attempt.hintsCount}) difficulty=`, attempt.difficulty, 'accuracy=', attempt.accuracy, ', comprehension=', attempt.comprehension);
	}

	gaveHint() {
		if (this.attempt) {
			this.attempt.hintsCount ++;
		}
	}

	logAnswerAccuracy({ correct, total }) {
		if(!this.attempt) {
			console.warn("AttemptManager: No current attempt, what happened??");
			return;
		}
		
		this.attempt.goodCount  = correct - this.attempt.hintsCount;
		this.attempt.wrongCount = correct - total;

		this.calculateCoreStats();
	}


	calculateAccuracy(attempt) {
		
		// Accuracy is just number of correct answers over the number of total answers given,
		// if all answers were correct answers, accuracy = 100%,
		// if some where bad, the accuracy decreases accordingly
		attempt.accuracy = 
			(attempt.goodCount - attempt.hintsCount)
				/ 
			(attempt.goodCount + (attempt.wrongCount||0))
		;

		return attempt;
	}

	calculateComprehension(attempt) {
		attempt.comprehension = attempt.accuracy * (1 - attempt.difficulty);

		return attempt;

		/* What's going on here?

			"Comprehension" is basically my attempt to merge "accuracy" and "difficulty"

			When accuracy is 100% and difficulty is 0%, then comprehension will be 100%.
			However, if accuracy is reduced (more wrong answers), or difficulty
			increases (more spikes in taking a long time to answer), then 
			comprehension as a whole will reduce.
			
			The "1-difficulty" is necessary to get both accuracy and difficulty going
			in the same direction, as difficulty by default goes up as it gets more difficulty.
		*/
	}

	classifyDifficulty(attempt) {
		const attempts = this.proctor.testManager.getAttempts();

		if(!attempts.length) {
			attempt.difficulty = 0.1;
			return attempt;	
		}

		if(!attempt.goodCount) {
			attempt.difficulty = 1.0;

			console.log(`[classifyDifficulty] verse ${attempt.verse.verse} (= ${attempt.verse.answer}) ** BAD ATTEMPT`);

			return attempt;
		}

		const times = attempts.map(a => a.timeLength)
				// Reverse it so the newest is at the start,
				// then take the first 10
				// That way difficulty can adjust in longer runs automatically
				.reverse()
				.slice(0, 10)
				.reverse(),
			// Draw a line thru the middle...
			avg = mean(times),
			// Get standard deviations of times around the mean
			sd  = std(times),
			// Scale the first std dev a bit for using below
			sd2 = sd * 1.5,
			// Get this attempt's difference from the mean (above/below is fine)
			avgDelta         = attempt.timeLength - avg,
			// Base difficulty is the distance from the mean 
			// expressed as a percentage of the standard deviation
			difficulty       = sd2 === 0 ? 0 : (avgDelta / sd2),
			// Cap difficulty between 0-1
			normalDifficulty = Math.min(1, Math.max(0, difficulty)),
			// Round to 2 decimals at most
			cleanDifficulty  = round2digits(normalDifficulty);
		
		// Store...
		attempt.difficulty = cleanDifficulty;
		
		// Debug ...
		// console.log(`[classifyDifficulty] verse ${attempt.verse.verse} (= ${attempt.verse.answer}) cleanDifficulty=${cleanDifficulty} `, { avg, sd2, avgDelta, difficulty, normalDifficulty });

		return attempt;
	}

	async destroy() {
		// ...
	}
}