import React from 'react';
import { ServerStore } from './ServerStore';

/*
	Custom hook for use with React Hooks and async data loading.

	Example usage:

		const advice = useRemoteData(() => ServerStore.getUserAdviceList());

*/

export const remoteDataCache = {};

const DEBUG_FLAG = true;

export function useRemoteData(createPromiseCallback, eventList) {
	const [ remoteData, setRemoteData ] = React.useState({});

	// Reusable setter for below in order to handle array vs object datas
	const remoteDataLoaded = (data, reloadData, caughtError) => {
		// console.warn("[remoteDataLoaded]", data);
		if(Array.isArray(data)) {
			data.loadDone = true;
			data.reloadData = reloadData;
			data.error = caughtError;
			setRemoteData(data);
		} else
		if(data) {
			setRemoteData({ 
				loadDone: true,
				reloadData,
				error: caughtError || null,
				...data
			});
		} else {
			setRemoteData({
				reloadData, 
				error: caughtError || null,
				loadDone: true,
			});
		}
	}

	if(eventList && 
		remoteDataCache[eventList] &&
		remoteDataCache[eventList].cacheDirty &&
		remoteData.loadDone) {
		// Not using setRemoteData because the only reason we're
		// setting this flag is to cause it to enter the next block
		remoteData.loadDone = false;
		delete remoteDataCache[eventList];
	}

	if (!remoteData.loadStarted && 
		!remoteData.loadDone) {

		// Only cache data if socket event name is given,
		// uses ServerUtil.socket() to listen for updates
		if(eventList && 
			remoteDataCache[eventList]) {
			if(DEBUG_FLAG)
				console.log("[useRemoteData] cache hit:", eventList);
			const data = remoteDataCache[eventList];
			remoteDataLoaded(data);
		} else {
			// No cache, so execute createPromiseCallback() to get the data
			// and cache if event given

			const reloadData = async (newCallback) => {
				if(eventList && DEBUG_FLAG)
					console.log("[useRemoteData] cache miss:", eventList);

				// if(!useRemoteData.requestId) {
				// 	useRemoteData.requestId = 0;
				// }

				// const requestId = useRemoteData.requestId ++;

				setRemoteData({ ...remoteData, reloadData, loadStarted: true });

				let caughtError = null;
				const data = await (newCallback || createPromiseCallback)().catch(error => {
					// console.error("Error loading remote data:", error);
					// setRemoteData({
					// 	loadDone: true,
					// 	error,
					// 	reloadData
					// })
					caughtError = error;
				});

				// if(requestId !== useRemoteData.requestId) {
				// 	//
				// }

				remoteDataLoaded(data, reloadData, caughtError);

				// Cache data for subsequent use
				remoteDataCache[eventList] = data;

				if(eventList && DEBUG_FLAG)
					console.log("[useRemoteData] cache loaded from server:", eventList, data);

				// console.log("Got remote data:", data);

				return data;
			};

			reloadData();


		}
	}

	// Effect is necessary to connect socket on mounting the component using this
	// hook and disconnect from the socket event when component is unmounted
	React.useEffect(() => {
		let callback, localCallback;
		if(eventList) {

			const events = eventList.split(/\s/);
			events.forEach(eventName => {
				// Connect to the socket and listen for updates
				// Use .onSocketEvent instead of .socket().on() because
				// we could be calling this before the socket is connected,
				// so .onSocketEvent buffers our handlers until the socket is connected.
				ServerStore.onSocketEvent(eventName, callback = data => {
					// console.log("[useRemoteData] cache updated via socket:", eventName);

					// remoteDataLoaded(data);
					setRemoteData({});
					delete remoteDataCache[eventList];
					if(DEBUG_FLAG)
						console.log("[useRemoteData] cache dirtied via socket:", eventName);

				});

				ServerStore.on(eventName, localCallback = () => {
					setRemoteData({});
					delete remoteDataCache[eventList];
					if(DEBUG_FLAG)
						console.log("[useRemoteData] cach dirtied by local event:", eventName);
				});
			});
		}

		// Return callback to disconnect from socket event
		// when component that is using this effect unmounts
		return () => {
			if(eventList) {
				const events = eventList.split(/\s/);
				events.forEach(eventName => {
					// console.log("[useRemoteData] stopped listening on unmount:", eventList);

					ServerStore.offSocketEvent(eventName, callback);
					ServerStore.off(eventName, localCallback);
				});
			}
		}
	}, [ eventList ]);

	return remoteData;
};