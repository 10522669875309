import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { ServerStore } from 'utils/ServerStore';

// This input based on example from docs at 
// https://material-ui.com/components/autocomplete/#asynchronous-requests
export default function VersionInput({
	label = "",
	defaultValue = { id: undefined },
	onChange = (value) => {},
	className,
	allowUnderline
}) {
	if(!defaultValue) {
		defaultValue = {};
	}

	const [options, setOptions] = React.useState();
	
	React.useEffect(() => {
		let active = true;
	
		(async () => {
			if (active) {
				const versions = await ServerStore.GetVersions(),
					opts = Array.from(versions || []);

				const lookup = {};
				opts.forEach(opt => lookup[opt.id] = opt);
				setOptions(lookup);
			}
		})();
	
		return () => {
			active = false;
		};
	}, [ ]);

	const handleChange = ev => onChange(options[ev.target.value]);

	return (	
		<FormControl className={className}>
			{label ? <InputLabel>{label}</InputLabel> : ""}
			<Select
				disableUnderline={allowUnderline ? false : true}
				value={options && defaultValue.id ? defaultValue.id : ''}
				onChange={handleChange}
			>
				{Array.from(Object.values(options || {})).map(opt => 
					<MenuItem 
						key={opt.id}
						value={opt.id}
					>
						{opt.name}
					</MenuItem>
				)}
			</Select>
		</FormControl>
	);
}