
export const DEFAULT_COLOR = 'rgba(0,0,0,0.1)';; //'#7951A9';

export const getItemColor = (item, fallback, defaultColor = DEFAULT_COLOR) => 
	(item && item.color) || 
		(fallback ? fallback.color || defaultColor : defaultColor);

export const computeTextColor = (hexColor, invert=false, opacity=0.9) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
	const rgb = result ? [
		parseInt(result[1], 16),
		parseInt(result[2], 16),
		parseInt(result[3], 16)
	] : null;

	if(!rgb) {
		return 'rgba(0,0,0,0.9)';
	}

	const sum = Math.round((
		(parseInt(rgb[0]) * 299) + 
		(parseInt(rgb[1]) * 587) + 
		(parseInt(rgb[2]) * 114)
	) / 1000);
	// console.log("Input:", hexColor, rgb, ", sum=", sum);
	return (
		invert ? (sum <= 200) : (sum > 200)
	) ? `rgba(0,0,0,${opacity || 0.9})` : `rgba(255,255,255,${opacity || 0.9})`;
}
