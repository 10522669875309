import React from 'react';
import styles from './PackPage.module.scss';
// import clsx from 'clsx';
import copy from 'copy-to-clipboard';
// import history from 'utils/history';

// import { FixedSizeList } from 'react-window';
// import AutoSizer from 'react-virtualized-auto-sizer';

import { NavControlService } from 'components/PrimaryNav';
import { ServerStore } from '../../utils/ServerStore';
import { useRemoteData } from '../../utils/useRemoteData';
import SimpleSpinner from '../../components/SimpleSpinner';
// import { defer } from 'utils/defer';

import VersionInput from 'components/VersionInput';

import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';

import PracticePack from 'components/PracticePack';
import ChooseVerseDialog from 'components/ChooseVerseDialog';
import VerseListCard from './VerseListCard';
import HeaderCard from './HeaderCard';
import EditPackDialog from './EditPackDialog';
import ShareDialog from './ShareDialog';

// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Button from '@material-ui/core/Button';


import { getItemColor } from 'utils/itemColorHelper';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const START_NOW_KEY = '@myverses/pack-start-now';

export default function PackPage({ ...props }) {
	const match640 = useMediaQuery('(min-width:640px)');

	// console.log("PackPage: got props:", props);
	const { id } = props.match.params;
	if(!id) {
		throw new Error("How'd we get here with no ID?")
	}

	React.useEffect(() => {
		NavControlService.showPrimaryNav(true);
		NavControlService.setCurrentNav(NavControlService.NAV_PACKS);
	});

	// const versions = useRemoteData(() => ServerStore.GetVersions());
	// const [ selectedVersion, setSelectedVersion ] = React.useState('NIV');
	
	// Hackish hook to scroll to the top once data loads
	const scrollTop = data => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 1);
		return data;
	}
	
	const pack = useRemoteData(() => ServerStore.GetPack(id).then(scrollTop));

	const changeVersion = ver => {
		ServerStore.metric('app.pack_page.change_version', null, {
			pack: pack.id,
			version: ver
		});
		// This also persists the version in the pack-user link
		pack.reloadData(() => ServerStore.GetPack(id, ver));
	}

	// FIXME: Changing URL to diff pack id in browser does NOT
	// change pack. Why? Tried fixing with this code,
	// but caused render loop, will have to fix later
	// if(pack.id && pack.id !== id && !pack.loadStarted) {
	// 	pack.reloadData(() => ServerStore.GetPack(id));
	// }

	const verses = Array.from(pack.verses || []);

	const trainingShown = ServerStore.currentUser.trainingShown;
	const [ trainingMode ] = React.useState(!trainingShown);
	if(!trainingShown) {
		ServerStore.currentUser.trainingShown = true;
		ServerStore.UpdateSettings({ trainingShown: true });
	}
	
	// JUST FOR TESTING - FIXME
	// const [ practiceStarted, setPracticeStarted ] = React.useState(process.env.NODE_ENV !== 'production' && true);

	const startNowFlag = 
		// (process.env.NODE_ENV !== 'production' && true) ||
		window.localStorage.getItem(START_NOW_KEY) === 'true';
	if(startNowFlag) {
		window.localStorage.setItem(START_NOW_KEY, 'false')
	};
	const [ practiceStarted, setPracticeStarted ] = React.useState(startNowFlag || trainingMode);

	const startPractice = () => {
		ServerStore.metric('app.pack_page.start_practice', null, {
			pack: pack.id
		});
		setPracticeStarted(true);
	}

	if(!practiceStarted
		&& pack && pack.loadDone
		&& pack.systemIdent === "singlePracticePack") {
		startPractice();
	}

	React.useEffect(() => {
		if(practiceStarted) {
			// setStatusBarColor('#7951A9');
			setStatusBarColor('#000000'); // set to black because bar text is often white
		} else {
			if(!match640 && pack && (pack.color || (pack.imageMeta && pack.imageMeta.color))) {
				setStatusBarColor(getItemColor(pack, pack.imageMeta));
			}
		}
	});

	const onPracticeStopped = () => {
		ServerStore.metric('app.pack_page.stop_practice', null, {
			pack: pack.id
		});

		if(pack.systemIdent === 'singlePracticePack') {
			// Assuming they came from the progress page - TODO FIXME
			// history.push('/progress');
			window.history.go(-1);
		} else {
			setPracticeStarted(false);
			// Get new stats
			pack.reloadData();
		}
	}

	const [ loading, setLoading ] = React.useState(false);

	const followFab = async () => {
		setLoading(true);
		await ServerStore.FollowPack(pack);
		setLoading(false);

		ServerStore.metric('app.pack_page.followed', null, {
			pack: pack.id
		});
		
		pack.reloadData();
	}

	const onUnfollow = async () => {

		ServerStore.metric('app.pack_page.unfollowed', null, {
			pack: pack.id
		});

		setLoading(true);
		// Delete unfollows public packs but doesn't actually delete
		await ServerStore.DeletePack(pack);
		setLoading(false);
		pack.reloadData();
	}

	const [ shareUrl, setShareUrl ] = React.useState(null);
	const onShare = async () => {
		const urlBase = process.env.NODE_ENV === 'production' ?
			`https://myverses.app` : (
				window.location.protocol
				+ '//' + 
				window.location.host
			);
		
		const url = `${urlBase}/#/pack/${pack.id}`;

		ServerStore.metric('app.pack_page.shared', null, {
			pack: pack.id,
			url
		});

		setShareUrl(url);
		copy(url);
		console.log("Share URL:", url);
	}

	const onShareDialogClose = (clickedOkay) => {
		if(clickedOkay) {
			if(!pack.isPublic && pack.userIsOwner) {
				setLoading(true);
				ServerStore.UpdatePack({
					id: pack.id,
					isPublic: true
				}).then(() => {
					pack.reloadData();
					setLoading(false);
				});
			}
		}
		setShareUrl(null);
	}

	const [ editDialogOpen, setEditDialogOpen ] = React.useState(false);
	
	const onRemoveVerse = async verse => {
		if(!verse || !verse.link || !verse.link.id) {
			console.log(verse);
			alert("Invalid verse, can't remove, contact support.")
			throw new Error("Invalid verse");
		}

		ServerStore.metric('app.pack_page.remove_verse');

		setLoading(true);
		await ServerStore.RemovePackVerse({
			linkId: verse.link.id
		}).catch(err => {
			alert("Couldn't remove verse, sorry, try again");
		})

		pack.reloadData();
		setLoading(false);
	}

	// function AskUserToSplit() {
	// 	const [ open, setOpen ] = React.useState(true);
	// 	const [ verse, setVerse ] = React.useState();

	// 	AskUserToSplit.show = verse => {
	// 		AskUserToSplit.setVerse(verse);
	// 		AskUserToSplit.setOpen(true);
	// 		console.log("AskUserToSplit.show verse:", verse);
	// 		return AskUserToSplit.promise = defer();
	// 	}

	// 	AskUserToSplit.setOpen = setOpen;
	// 	AskUserToSplit.setVerse = setVerse;

	// 	const onClose = () => {
	// 		setOpen(false);
	// 		if (AskUserToSplit.promise) {
	// 			AskUserToSplit.promise.resolve({ cancel: true });
	// 			AskUserToSplit.promise = null;
	// 		}
	// 	}

	// 	const onAnswer = result => {
	// 		setOpen(false);
	// 		if (AskUserToSplit.promise) {
	// 			AskUserToSplit.promise.resolve({ [result]: true });
	// 			AskUserToSplit.promise = null;
	// 		}
	// 	}

	// 	if(!verse) {
	// 		return <></>;
	// 	}
	
	// 	return (<Dialog 
	// 		className={styles.AskUserToSplit}
	// 		open={open || false} 
	// 		onClose={() => onClose()}
	// 	>
	// 		<DialogTitle>
	// 			Add Multiple Verses?
	// 		</DialogTitle>
	// 		<DialogContent>
	// 			<DialogContentText>
	// 				You've selected {verse.cachedRef}, which consists of multiple verses in sequence. Do you want to add it all at once as a single card, or do you want to add each verse in this range individually to the pack?
	// 			</DialogContentText>
	// 		</DialogContent>
	// 		<DialogActions className={styles.actions}>
	// 		<Button 
	// 				onClick={() => onAnswer('cancel')}
	// 				color="default"
	// 				data-testtarget='cancel'
	// 			>
	// 				Cancel
	// 			</Button>
	// 			<Button 
	// 				onClick={() => onAnswer('single')}
	// 				color="default"
	// 				data-testtarget='cancel'
	// 			>
	// 				All at Once
	// 			</Button>
	// 			<Button 
	// 				onClick={() => onAnswer('split')}
	// 				color="primary"
	// 			>
	// 				Add Individual Verses
	// 			</Button>
	// 		</DialogActions>
	// 	</Dialog>);
	// }

	const addVersetoPack = async verse => {
		let splitRange = false;
		if(verse.isRange) {
			// const { cancel, split } = await AskUserToSplit.show(verse);
			// if(cancel) {
			// 	return;
			// }

			const text = `You've selected ${verse.cachedRef}, which consists of multiple verses in sequence. Do you want to add it all at once as a single card, or do you want to add each verse in this range individually to the pack? (Click OK to add individually, CANCEL to add as a single card.)`;

			const split = window.confirm(text);
			splitRange = split ? true : false;
		}

		ServerStore.metric('app.pack_page.add_verse', null, {
			pack: pack.id,
			...verse
		});

		setLoading(true);
		const res = await ServerStore.AddPackVerse({
			packId: pack.id,
			splitRange,
			...(verse.isRange ? {
				verseId:    verse.verse.startId,
				endVerseId: verse.verse.endId,
			} : {
				verseId: verse.id,
			})
		});

		if(!res.id) {
			alert("Error adding verse, sorry, try again");
			return;
		}

		// Reload entire list
		pack.reloadData();

		setLoading(false);
	};

	const restartPackCursor = async () => {
		setLoading(true);

		ServerStore.metric('app.pack_page.restart_clicked', null, {
			pack: pack.id
		});

		const res = await ServerStore.RestartCursor({
			packId: pack.id,
		});

		if(!res.id) {
			alert("Error restart, sorry, try again");
			return;
		}

		// Reload entire list
		await pack.reloadData();

		setTimeout(() => startPractice(), 100);

		setLoading(false);
	}

	const [ currentVerse, setCurrentVerse ] = React.useState({});
	const [ verseDialogOpen, setVerseDialogOpen ] = React.useState();

	const [ firstLoad, setFirstLoad ] = React.useState(true);
	if(pack.loadDone && 
		pack.verses && 
		!pack.verses.length && 
		!verseDialogOpen &&
		firstLoad) {
		// console.log("Opening because pack:", JSON.parse(JSON.stringify(pack)));
		setFirstLoad(false);
		setVerseDialogOpen(true);
	}

	// Use this to clear the focus off the button when we open the dialog
	const btnRef = React.useRef();

	const onVerseDialogSave = verse => {
		setVerseDialogOpen(false);
		setCurrentVerse(verse);
		addVersetoPack(verse);
	}

	const onVerseDialogCancel = () => {
		setVerseDialogOpen(false);
	}

	const openVerseDialog = () => {
		if (btnRef.current) {
			// If we don't clear the focus, then in the dialog,
			// if user presses enter to select highlighted row,
			// then the enter propogates and re-opens the dialog immediately
			btnRef.current.blur();
		}
		setVerseDialogOpen(true);
	}


	// const openChapter = () => {
	// 	const usfmRef = verse.usfmRef.replace(/\.\d(-\d)?$/, '');
	// 	const version = ServerStore.currentUser.preferredVersion
	// 	const urlBase = 'https://bible.com/bible' + version + '/' + usfmRef;
	// }
	
	return (<>
		<div className={styles.root}>
			{practiceStarted ? 
				<PracticePack 
					pack={pack}
					trainingMode={trainingMode}
					onPracticeStopped={onPracticeStopped}
				/>
			: !pack.loadDone ?
				<SimpleSpinner fullpage={true}/>
			: pack.error ?
				<div className={styles.wrap}>
					<Card className={styles.titleCard}>
						<CardContent>
							<div style={{color:'red'}}>
								{pack.error.message}
							</div>
						</CardContent>
					</Card>
				</div>
			:
			<div className={styles.wrap}>
				{/* We put a separate loading spinner here because
				the 'loading' flag is only used AFTER pack is already loaded,
				like adding verses, etc */}
				{loading ? <SimpleSpinner fullpage={true}/> : ""}

				<ShareDialog
					pack={pack}
					shareUrl={shareUrl}
					onSave={() => onShareDialogClose(true)}
					onCancel={() => onShareDialogClose(false)}
				/>

				<HeaderCard
					pack={pack}
					onStart={startPractice}
					followFab={followFab}
					onUnfollow={onUnfollow}
					onShare={onShare}
					onSettings={() => setEditDialogOpen(true)}
					onRestart={restartPackCursor}
				/>

				{pack.userCanEdit ? <>
					{/* <EditActionsFab
						addVerse={openVerseDialog}
						editPack={() => setEditDialogOpen(true)}
						btnRef={btnRef}
					/> */}

					{/* <AskUserToSplit/> */}

					<ChooseVerseDialog
						open={verseDialogOpen} 
						currentVerse={currentVerse}
						onSave={onVerseDialogSave}
						onCancel={onVerseDialogCancel}
					/>

					<EditPackDialog 
						pack={pack}
						open={editDialogOpen}
						onClose={() => {
							setEditDialogOpen(false);
							pack.reloadData();
						}}
					/>

					<Zoom
						in={true}
						timeout={{
							enter: 300,
							exit: 300
						}}
						unmountOnExit
					>
						<Tooltip title="Add verse">
							<Fab 
								size="medium" 
								onClick={openVerseDialog} 
								className={styles.addVerseFab}
								ref={btnRef}
							>
								<AddIcon/>
							</Fab>
						</Tooltip>
					</Zoom>

				</> : ""}


				{/* Single practice packs won't have a userLink */}
				{pack.userLink ?
					<VersionInput
						label={pack.userLink.version ? "" : "Bible Version"}
						className={styles.versionSelect}
						defaultValue={pack.userLink.version}
						onChange={changeVersion}
					/>
				:""}

				<div className={styles.versePreviews}>
					{/* <h3>
						<span>Verses in this Pack</span>
						<Select
							className={styles.versionSelect}
							value={selectedVersion}
							onChange={ev => setSelectedVersion(ev.target.value)}
							disableUnderline
						>
							{Array.from(versions).map(({ name } = {}) => <MenuItem 
								key={name}
								value={name}
							>
								{name}
							</MenuItem>)}
						</Select>
					</h3> */}
					
					
					{verses.map(verse => <VerseListCard
						verse={verse}
						key={verse.id}
						userCanEdit={pack.userCanEdit}
						onRemoveVerse={() => onRemoveVerse(verse)}
						// onOpenChapter={() => onOpenChapter(verse)}
						version={pack.userLink && pack.userLink.version}
					/>)}
				</div>

				{/* {pack.imageMeta && pack.imageMeta.attribUser ?
					<div className={styles.photoAttrib}>
						Photo by <a href={pack.imageMeta.attribUser} target='_blank' rel='noopener noreferrer'>{pack.imageMeta.authorName}</a> on <a href={pack.imageMeta.attribUnsplash} target='_blank' rel='noopener noreferrer'>Unsplash</a>
					</div>
				:""} */}
			</div>}
		</div>
	</>);
};