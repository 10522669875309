import { defer } from './defer';

const logger = {
	warn:  (...args) =>  console.warn("[cordova-fs-helpers]  [WARN]: ", ...args),
	log:   (...args) =>  console.warn("[cordova-fs-helpers]  [INFO]: ", ...args),
	error: (...args) => console.error("[cordova-fs-helpers] [ERROR]: ", ...args),
};

// cacheKey and writeUpgradeCache are included here because they 
// are re-used in the main app, not just bootloader
export const cacheKey = "app-boot-upgrades.json";
export async function writeUpgradeCache(cache) {
	const json = JSON.stringify(cache);
	return await writeFile(cacheKey, json, 'application/json');
}

export function writeFile(filename, data, contentType) {
	const blob = new Blob([ data ], { type: contentType });
		
	const writeFile = async (fileEntry, dataObj) => {

		logger.log(`[writeFile] writing blob to fileEntry`, { fileEntry, dataObj });

		const promise = defer();
		// Create a FileWriter object for our FileEntry (log.txt).
		fileEntry.createWriter(function (fileWriter) {
	
			fileWriter.onwriteend = function() {
				logger.log("" + filename + ": Successful file write");
				promise.resolve({ success: true });
			};
	
			fileWriter.onerror = function (e) {
				logger.log("" + filename + ": Failed file write: " + e.toString());
				promise.resolve({ failed: true });
			};

			fileWriter.write(dataObj);
		});

		return promise;
	}

	return new Promise(resolve => {
		const onErrorCreateFile = error => {
			resolve({ error, from: 'creating file - ' + filename })
		};

		const onErrorLoadFs = error => {
			resolve({ error, from: 'load fs' })
		};

		window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, fs => {
			fs.root.getFile(filename, { create: true, exclusive: false }, async fileEntry => {

				const { success } = await writeFile(fileEntry, blob);

				// This is the path we will load in future loads
				if(success) {
					const url = fileEntry.toInternalURL();

					logger.log(`[writeFile] success, wrote to: ${url}`);

					resolve({ success: true, url });
				} else {
					logger.warn("[writeFile] Failed to write file for some reason");

					resolve({ error: "Unknown" })
				}

			}, onErrorCreateFile);
		}, onErrorLoadFs);
	});
}

export function readFile(filename, { asBlob = false } = {}) {
	logger.log(`[readFile] going to attempt read from ${filename}, asBlob? ${asBlob ? 'true' : 'false'}`);

	return new Promise(resolve => {
		const onErrorCreateFile = error => {
			resolve({ error, from: 'creating file - ' + filename })
		};

		const onErrorReadFile = error => {
			resolve({ error, from: 'reading file - ' + filename })
		};

		const onErrorLoadFs = error => {
			resolve({ error, from: 'load fs' })
		};

		window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, fs => {
			fs.root.getFile(filename, { create: true, exclusive: false }, async fileEntry => {
		
				fileEntry.file(function (file) {
					const reader = new FileReader();

					reader.onloadend = function() {
						logger.log("Successful file read from " + fileEntry.fullPath + ": " + (this.result.length || this.result.size) + " bytes");

						const data = !asBlob ? this.result :
							// TODO: is the Uint8Array required?
							new Blob([ new Uint8Array(this.result) ]) //, { type: "image/png" })
						;

						resolve({ path: fileEntry.fullPath, data });
					};

					if(asBlob) {
						reader.readAsArrayBuffer(file);
					} else {
						reader.readAsText(file);
					}

				}, onErrorReadFile);
			}, onErrorCreateFile);
		}, onErrorLoadFs);
	});
}