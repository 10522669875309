import React from 'react';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// import { BrowserRouter as Router, Route } from "react-router-dom";
import { HashRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import history from 'utils/history';

import Routes from 'pages';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';
import { MyVersesCoreService } from 'utils/MyVersesCoreService';

import CssBaseline from '@material-ui/core/CssBaseline';


import PrimaryNav from 'components/PrimaryNav';
import PrivateRoute from 'components/PrivateRoute';
// import SimpleSpinner from 'components/SimpleSpinner';

import { mobileDetect } from 'utils/mobile-detect';
import VerseProgressDialog from 'components/VerseProgressDialog';
import SimpleSpinner from './components/SimpleSpinner';

// Used by utils/normalize-link
window._reactRoutePrefix = '#';

// Add custom Alert as a react-enabled swal instance
window.Swal = Swal;
window.AlertManager = withReactContent(Swal);
window.Alert = opts => window.AlertManager.fire(opts);

const HistoryGrabber = withRouter( ({ history: ref }) => {
	history.ref = ref;
	return <></>
});

const NotchHelper = () => {
	React.useEffect(() => {
		if(mobileDetect.hasNotch) {
			document.body.classList.add('notched');
		} else {
			document.body.classList.remove('notched');
		}

		if(window.isPhoneGap) {
			document.body.classList.add('native');
		}

		// // Just for testing
		if(process.env.NODE_ENV !== 'production') {
			// document.body.classList.add('notched');
			// document.body.classList.add('native');
		}

		return () => document.body.classList.remove('notched');
	});

	return <></>;
}

export default function App() {
	setStatusBarColor('#000000'); // set to black because bar text is often white

	// Allow boot of the core service to continue after first render
	// (Has no effect if already booted)
	React.useEffect(() => MyVersesCoreService.unlockBoot());

	const [ sharedVerse, setSharedVerse ] = React.useState();
	const [ sharedVerseLoading, setSharedVerseLoading ] = React.useState();
	// For the "Share with..." plugin functionality (cordova.openwith)
	MyVersesCoreService.onVerseShareReceived = (verse, isLoading) => {
		setSharedVerse(verse);
		setSharedVerseLoading(!!isLoading);
	}

	// Render our app
	return (<>
		<Router>
			<CssBaseline />
			<NotchHelper/>
			<HistoryGrabber/>
			<Switch>
				{Object.keys(Routes).map(path => {
					const Component = Routes[path].private ? PrivateRoute : Route;
					return <Component 
						key       = {path}
						path      = {path}
						component = {Routes[path].component}
						exact     = {Routes[path].exact}/>
				})}
			</Switch>
			<PrimaryNav/>
			
			{sharedVerseLoading ? <SimpleSpinner fullpage={true}/> :""}
			<VerseProgressDialog
				row={sharedVerse && sharedVerse.verse}
				onCancel={() => setSharedVerse(null)}
				shareMode={sharedVerse && sharedVerse.source}
			/>
		</Router>
	</>);
}
