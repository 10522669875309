import React from 'react';
import styles from './DeleteMyData.module.scss';
import SubPageLayout from '../layouts/SubPageLayout';
import { NavControlService } from 'components/PrimaryNav';

export default function PrivacyPage({ ...props }) {
	React.useEffect(() => {
		NavControlService.showPrimaryNav(false);
		// NavControlService.setCurrentNav(NavControlService.NAV_PACKS);
	});

	
	return (<SubPageLayout title="MyVerses Data Deletion Request">
		<div className={styles.root}>
			{/* <Link to="/"><FontAwesomeIcon icon={faArrowLeft}/></Link> */}
			<h1>MyVerses Data Deletion Request</h1>
			<div className="content content--withSidebar content--sidebarAsTrack ">
				<div className="content--main">
					<section className="helpArticle__section normalize" id="" data-behavior="highlight-section" data-module-id="articleText" data-entry-id="16D2FgpEpPkc7Vrif5L4K5">							
						
						<p>MyVerses takes your data seriously. You can request your data to be removed from our servers at any time.</p>
						<p>To request your data to be deleted, please email us at <a href='mailto:deletemydata@myverses.app?subject=Delete My Data'>deletemydata@myverses.app</a> and include a screenshot of the settings page in the app so we can locate your account on our servers. We'll respond within 48 hours to confirm that it has been deleted.</p>
					
					</section>
				</div>
			</div>
		</div>
	</SubPageLayout>);
}