import React from 'react';
import styles from './SearchPage.module.scss';

import { NavControlService } from 'components/PrimaryNav';
import { ServerStore } from 'utils/ServerStore';
import SimpleSpinner from 'components/SimpleSpinner';
import { defer } from 'utils/defer';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';

import { InputLabel } from '@material-ui/core';
// import SearchResultCard from './SearchResultCard';
import PackHomeCard from '../PacksHomePage/PackHomeCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VerseProgressDialog from '../../components/VerseProgressDialog';

class SearchQuery {
	static setLoadingCallback(cb) {
		this.loadingCallback = cb;
	}

	static async setVersionId(versionId = "") {
		const changed = this.versionId !== versionId;
		
		this.versionId = versionId;
		
		// For notifying server of new version
		this.oldVersionId = this.lastSearchedVersionId;
		
		if(this.searchPromise) {
			return this.searchPromise;
		}

		if (this.searchString && changed) {
			return this.search(this.searchString);
		} else {
			return { list: [] }
		}
	}

	static stop() {
		this.stopped = true;
		if (this.loadingCallback) {
			this.loadingCallback(false);
		}

	}

	static async _search() {
		const query = {
			search: this.searchString,
			version: this.versionId,
		};

		if(this.oldVersionId !== this.versionId) {
			query.oldVersion   = this.oldVersionId;
		}

		// console.log("[_search] oldVersionId is ", this.oldVersionId, ", this.lastSearchedVersionId is ", this.lastSearchedVersionId,", and query is:", query);

		this.lastSearchedVersionId = this.versionId;


		const list = await ServerStore.GeneralSearchQuery(query);

		// console.log("Serach got list: ", list);

		if(this.searchString !== query.search) {
			// console.warn("Search changed while waiting for results, not resolving");
			return;
		}

		if(this.stopped) {
			// console.warn("Stopped while waiting for results, not resolving");
			return;
		}
		
		if (this.loadingCallback) {
			this.loadingCallback(false);
		}

		ServerStore.metric("app.search_page.searched", null, {
			query: query.searchString
		});

		// console.log("For query ", this.searchString, ", got:", list);

		if (this.searchPromise) {
			this.searchPromise.resolve(list);
			this.searchPromise = null;
		}

	}

	static async search(string="") {
		if (this.loadingCallback) {
			this.loadingCallback(true);
		}

		this.stopped = false;
		this.searchString = string;

		if(!this.searchPromise) {
			this.searchPromise = defer();
		}

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._search();
		}, this.delay || 500);

		return this.searchPromise;
	}
}


export default function SearchPage({ ...props }) {
	React.useEffect(() => {
		NavControlService.showPrimaryNav(true);
		NavControlService.setCurrentNav(NavControlService.NAV_SEARCH);
	});


	React.useEffect(() => {
		// setStatusBarColor('#7951A9');
		setStatusBarColor('#000000'); // set to black because bar text is often white
	});

	const match640 = useMediaQuery('(min-width:640px)');

	// const packs = useRemoteData(() => ServerStore.SearchPacks()); 
	const [ results, setResults ] = React.useState([]);
	const [ loading, setLoading ] = React.useState();
	const [ firstLoad, setFirstLoad ] = React.useState(true);

	SearchQuery.setLoadingCallback(setLoading);

	const gotResults = (results) => {
		// Display results with changes
		setResults(results);
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 1);
	}

	// const changeVersion = ver => {
	// 	setVersion(ver);
	// 	SearchQuery.setVersionId(ver.id).then(gotResults);
	// }


	const execSearch = async (text) => {
		const res = await SearchQuery.search(text);
		gotResults(res, true);
	}

	const search = async (text) => {
		// if(!text) {
		// 	SearchQuery.searchString = "";
		// 	setResults([]);
		// 	return;
		// }

		if(text && text === SearchQuery.searchString) {
			return;
		}

		execSearch(text);
	}

	if(firstLoad) {
		search();
		setFirstLoad(false);
	}

	const verses = Array.from((results && results.verses) || []);
	const packs = Array.from((results && results.packs) || []);

	const [ selectedRow, setSelectedRow ] = React.useState();

	return (<>
		<div className={styles.root}>
			<AppBar position="fixed" className={styles.searchBar}>
				<div className={styles.searchWrap}>
					<IconButton
						onClick={() => execSearch(SearchQuery.searchString)}
						className={styles.searchButton}
					>
						<SearchIcon 
							style={{ fontSize: '1rem' }}
						/>
					</IconButton>
					<Input
						className={styles.search}
						disableUnderline
						defaultValue={""}
						type="search"
						placeholder="Search Verse Packs"
						onChange={ev => search(ev.target.value)}
						// onKeyDown={ev => keyPressed(ev)}
					/>
				</div>

				{/* </Toolbar> */}

			</AppBar>
		
			<div className={styles.contentWrap}>
				{loading ?
					<SimpleSpinner fullpage={true}/>
				: 
				<>
					{verses.length ? <>
						<InputLabel>Verses</InputLabel>

						<VerseProgressDialog
							row={selectedRow}
							onCancel={() => setSelectedRow(null)}
							searchMode={true}
						/>

						<div className={styles.searchResults}>
							{verses.slice(0, match640 ? 8:6).map(pack => <PackHomeCard
								metricKey='search_page'
								key={pack.id}
								verse={pack}
								onVerseClick={() => setSelectedRow(pack)}
								// badgeClass={styles.badgeOverride}
								// badgeText={
								// 	pack.userFollows.isFollowing ? 
								// 		'♥✓'
								// 	: pack.userFollows.numFollowers ?
								// 		`♥${pack.userFollows.numFollowers}`
								// 	: ''
								// }
								// badgeTitle={
								// 	pack.userFollows.isFollowing ? 
								// 		'You are following this pack'
								// 	: pack.userFollows.numFollowers ?
								// 		`This pack has ${pack.userFollows.numFollowers} followers`
								// 	: ''
								// }
							/>)}
						</div>
					</> : ""}
						
					{packs.length ? <>
						{verses.length ? 
							<InputLabel>Packs</InputLabel>
						: ""}
						<div className={styles.searchResults}>
							{packs.map(pack => <PackHomeCard
								metricKey='search_page'
								key={pack.id}
								pack={pack}
								className={styles.item}
								badgeClass={styles.badgeOverride}
								badgeText={
									pack.userFollows.isFollowing ? 
										'♥✓'
									: pack.userFollows.numFollowers ?
										`♥${pack.userFollows.numFollowers}`
									: ''
								}
								badgeTitle={
									pack.userFollows.isFollowing ? 
										'You are following this pack'
									: pack.userFollows.numFollowers ?
										`This pack has ${pack.userFollows.numFollowers} followers`
									: ''
								}
							/>)}
						</div>
					</> : ""}
				</>}
			</div>
		</div>
	</>);
}
