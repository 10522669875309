import React from 'react';
import clsx from 'clsx';
// import history from 'utils/history';
// import moment from 'moment';
import cx from 'clsx';
import styles from './VotdCard.module.scss';
import { Card, CardActionArea, Tooltip } from '@material-ui/core';
import { ServerStore } from 'utils/ServerStore';
import VerseBadgeLevel from 'components/VerseBadgeLevel';
import VerseProgressDialog from 'components/VerseProgressDialog';

export default function VotdCard({
	verse,
	className,
	badgeText,
	badgeClass,
	badgeTitle = ''
}) {
	// const onClick = async () => {
	// 	window.localStorage.setItem(START_NOW_KEY, 'true')
	// 	setTimeout(
	// 		() => history.push(`/pack/${pack.id}`),
	// 		0
	// 	);
	// }


	// TODO: Load stats like in progress page for just this verse

	const [ openDialog, setOpenDialog ] = React.useState(),
		closeDialog = _ => setOpenDialog(null);


	const practice = async () => {
		// // setLoading(true);
		// const pack = await ServerStore.GetSinglePracticePack({
		// 	idOnly: true,
		// 	ref: verse.cachedRef
		// });
		// // setLoading(false);
		// // PackPage will auto-detect singlePracticePack mode and start practice right away
		// history.push(`/pack/${pack.id}`);
		ServerStore.metric("app.votd.clicked");

		setOpenDialog(verse);
	}

	// const cursor = (pack || {}).cursor || {};

	// const bl = verse.badgeLevel || {};
	return <div 
		className={cx(styles.root, className, styles.verse, styles.variantSmall)}
	>
		<VerseProgressDialog
			row={openDialog}
			searchMode={true}
			onCancel={closeDialog}
		/>
		<Card
			// data-hasprogress={cursor.progressPercent ? "true" : "false"}
			// style={ pack ? {
			// 	'--cover-color': getItemColor(pack.imageMeta),
			// 	'--cover-text': computeTextColor(getItemColor(pack.imageMeta)),
			// 	'--cover-shade': computeTextColor(getItemColor(pack.imageMeta), true),
			// 	'--progress-percent': `${cursor.progressPercent || 0}%`
			// } : {} }
		>
			<CardActionArea
				onClick={practice}
			>
				{(verse.badgeLevel || {}).windowCount > 0 ? 
					<VerseBadgeLevel
						className={styles.badgeLevel}
						verse={{ stats: verse.badgeLevel || {} }}
					/>
				: ""}
				<div 
					className={styles.refBox}
				>
					<div className={styles.ref}>{verse.cachedRef}</div>
					<div className={styles.text}>{verse.text}</div>
				</div>
				{/* {cursor.progressPercent ? 
					<div className={styles.progressPercent}/>
				: ""} */}
				{badgeText ? 
					<Tooltip title={badgeTitle || badgeText}>
						<div className={clsx(styles.badgeText, badgeClass)}>
							<label>
								{badgeText}
							</label>
						</div>
					</Tooltip>
				: ""}
			</CardActionArea>
		</Card>
	</div>;
	
}