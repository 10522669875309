import React from 'react';

import styles from './VerseProgressDialog.module.scss';
import clsx from 'clsx';
// import { round2digits } from 'utils/proctor/utils';
// import moment from 'moment';
import CircleProgress from 'components/CircleProgress';
import nameForLevel from 'utils/nameForBadgeLevel';

import history from 'utils/history';
import { ServerStore } from 'utils/ServerStore';
import seconds2duration from 'utils/seconds2duration';
import { randomEncouragement, randomAffirmation } from 'utils/randomAffirmation';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitleWithClose from './DialogTitleWithClose';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { ICONS } from './PrimaryNav';
import AddIcon from '@material-ui/icons/Add';
import ChoosePackDialog from './ChoosePackDialog';
// import SimpleSpinner from './SimpleSpinner';
import { MyVersesCoreService } from 'utils/MyVersesCoreService';

import VerseBadgeLevel from 'components/VerseBadgeLevel';

export default function VerseProgressDialog({
	row = null,
	onCancel,
	searchMode,
	shareMode
}) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	// console.log("VerseProgressDialog show:", JSON.parse(JSON.stringify(row)));

	// if(searchMode || shareMode) {
	// 	row = { ...(row || {}), verse: {},  searchMode, shareMode };
	// } else
	// if(!row || !row.verse) {
	// 	row = { verse: {} };
	// }

	const verse = searchMode || shareMode ? row : row && row.verse;

	const onClose = () => onCancel && onCancel();

	const [ loading, setLoading ] = React.useState();

	const [ packDialogOpen, setPackDialogOpen ] = React.useState(false);
	// const [ packSaveLoading, setPackSaveLoading ] = React.useState(false);

	// Sync pending state to vars
	MyVersesCoreService.useServiceCallback(MyVersesCoreService.addVerseToPack, setLoading);	

	if(!row || !verse) {
		return null;
	}

	const onPractice = async () => {
		setLoading(true);
		const pack = await ServerStore.GetSinglePracticePack({
			idOnly: true,
			ref: verse.cachedRef,
			version: verse.version && verse.version.id
		});
		setLoading(false);

		ServerStore.metric("app.verse_dialog.practice.clicked", null, {
			cachedRef: verse && verse.cachedRef
		});

		// PackPage will auto-detect singlePracticePack mode and start practice right away
		history.push(`/pack/${pack.id}`);

		// Needed to close the dialog for shares
		onCancel();
	}

	const choosePack = () => {
		ServerStore.metric("app.verse_dialog.add_to_pack.clicked", null, {
			cachedRef: verse && verse.cachedRef
		});
		setPackDialogOpen(true);
	}

	const onPackCancel = () => {
		setPackDialogOpen(false);
	}

	const onPackSave = async pack => {
		setPackDialogOpen(false);
		// setPackSaveLoading(true);

		console.log("On pack save, have:", JSON.parse(JSON.stringify({ pack, verse, row })));
		await MyVersesCoreService.addVerseToPack(verse, pack);

		onClose();

		ServerStore.metric("app.verse_dialog.add_to_pack.saved", null, {
			cachedRef: verse && verse.cachedRef,
			pack: pack && pack.id
		});
		
		// Send to the pack
		history.push(`/pack/${pack.id}`);
	}

	const isBibleAppShare = shareMode === 'com.bible.share'/*,
		  isRawTextShare  = shareMode === 'unknown.share'*/;

	const stats = (row.avgAccuracy || row.vlAvgAccuracy ? row : (verse.stats || verse.badgeLevel)) || {};
	// console.log("vpd:", { row, verse, stats });

	const badgeLevel = (stats.badgeLevel / 5) * 100,
		badgeProgress = ((stats.vlAvgAccuracy || stats.avgAccuracy) * (stats.windowCount / 2)) * 100; 

	return (<>

		<ChoosePackDialog
			onCancel={onPackCancel}
			onSave={onPackSave}
			open={packDialogOpen}
		/>

		<Dialog 
			className={styles.VerseProgressDialog}
			open={!!row.cachedRef || false} 
			onClose={onClose}
			fullScreen={fullScreen}
		>
			<DialogTitleWithClose 
				onClose={onClose}
				className={styles.title}
			>
				{verse.cachedRef} 
				{row.badgeLevel && <VerseBadgeLevel 
					verse={{ stats }}
					className={styles.badgeLevel}
				/>}
			</DialogTitleWithClose>
			<DialogContent className={styles.fields}>
				{loading ? 
					<div className={styles.loadingSpinner}>
						<center><CircularProgress color="inherit" size={20} /></center>
					</div>
				:<>
					<div className={styles.verseText}>
						{verse.text}
					</div>

					<div className={styles.buttons}>
						<Button
							className={styles.addToPackBtn}
							onClick={choosePack}
							color="primary"
							// fullWidth
							variant="contained"
						>
							<AddIcon/>
							Add to…
						</Button>

						<Button
							className={styles.practiceBtn}
							onClick={onPractice}
							color="primary"
							// fullWidth
							variant="contained"
						>
							<ICONS.PacksIcon/>
							Practice
						</Button>
					</div>

					{shareMode ? <>
						<div className={styles.shareHeader}>
							<p>You've shared <b>{row.cachedRef}</b> from {
								isBibleAppShare ? 
									"the YouVersion Bible App" :
									"another app"
							}! 
								You can practice it right away, or you save it in pack of verses to practice later.
							</p>
						</div>
					</> : ""}

					{stats.avgComprehension ? <>
						{row.totalTime ? 
							<div className={styles.info}>
								<p>
									You've spent <b>{seconds2duration(row.totalTime)} minutes</b> on <b>{row.cachedRef}</b> over <b>{row.tests || row.numTests} practices</b>, and scored an average of <b>{Math.round(row.avgComprehension * 100)}%</b>. {
										Math.random() >= 0.5 ?
											randomAffirmation() : 
											randomEncouragement()
									} 
								</p>
								{/* <p>
									<RenderContext context={row.contextualized}/>
								</p> */}
							</div>
						: ""}

						<div className={styles.headerInfo}>
							
							<div className={styles.circles}>
								<div className={clsx(styles.block, styles.badgeLevel)}>
									<CircleProgress 
										value={badgeLevel}
										className={styles.badgeCircle}
										size={32}
									/>
									<div className={styles.info}>
										<label>Memorization</label>
										<label className={styles.hint}>
											Level of memorization for this particular verse or verse range
										</label>
										<div className={styles.data}>
											<span className={styles.value}>
												{stats.badgeLevel}
											</span>
											/
											<span className={styles.max}>
												5
											</span>
										</div>
										<div className={styles.dataSubtext}>
											{nameForLevel(stats.badgeLevel)}
										</div>
									</div>
								</div>

								<div className={clsx(styles.block, styles.progressPercent)}>
									<CircleProgress 
										value={badgeProgress}
										className={styles.progressCircle}
										size={32}
									/>

									<div className={styles.info}>
										<label>Progress</label>
										<label className={styles.hint}>
											Percent completed toward earning the next badge level
										</label>
										<div className={styles.data}>
											<span className={styles.value}>
												{Math.round( badgeProgress || 0 )}%
											</span>
											
											{/* <span className={styles.max}>
												{" "}(
													{Math.ceil(pack.verses.length * (
														(cursor.progressPercent || 0) / 100
													))}
													/	
													{pack.verses.length}
												)
											</span> */}
										</div>
										<div className={styles.dataSubtext}>
											Based on the score of practice
										</div>
									</div>
								</div>
							
							</div>
{/* 

							<div className={styles.stats}>
								<div data-stat="numVerses">
									<span>{pack.numVerses}</span>
									<label>verses</label>
								</div>
								{stats ? <>
									<div data-stat="avgComprehension">
										<span>
											{Math.round(stats.avgComprehension * 100)}%
										</span>
										<label>score</label>
									</div>
									<div data-stat="lastTested">
										<span>
											{moment(stats.lastTested).fromNow().replace('minutes','min')}
										</span>
										<label>last practiced</label>
									</div>
									<div data-stat="numTests">
										<span>
											{stats.numTests} x
										</span>
										<label>practices</label>
									</div>
									<div data-stat="totalTime">
										<span>
											{seconds2duration(stats.totalTime)} min
										</span>
										<label>total</label>
									</div>
								</> : ""}
							</div> */}

						</div>

					</> : ""}
				</>}
			</DialogContent>
			{!loading ? 
				<DialogActions className={styles.actions}>
					<Button 
						onClick={onClose}
						color="default"
						data-testtarget='cancel'
					>
						Close
					</Button>
				</DialogActions>
			:""}
		</Dialog>
	</>);
}

// function RenderContext({ context }) {
// 	const {
// 		other = {
// 			// name: 'Practice Hebrew',
// 			// id: '7',
// 			// goalHours: 0.5,
// 			// hours: 0,
// 			// goalMatchRatio: 1
// 		},
// 		match = undefined
// 	} = context ||  {};
	
// 	const affirmLower = randomAffirmation()
// 		// Make the first letter lowercase to flow with the string
// 		.replace(/^([A-Z])/, (unused, letter) => letter.toLowerCase());
	
// 	return (<>
// 		{" "}
// 		{match === 'mid' ? 
// 			<>
// 				<span role="img" aria-label="Success">✅</span> Spot on! {randomAffirmation()}
// 				{" "}
// 				<span role="img" aria-label="Warning">💡️</span> Why not invest some time in <b>{other.verbified || other.name}</b> now?
// 			</>
// 		:
// 		match === 'high' ?
// 			<>
// 				<span role="img" aria-label="Success">✅</span> Exeeded your goal, {affirmLower}
// 				{" "}
// 				<span role="img" aria-label="Warning">⚡</span> Don't forget though, you 
// 				want to also invest time in <b>{other.verbified || other.name}</b>!
// 			</>
// 		:
// 		match === 'low' ?
// 			<>️️
// 				<span role="img" aria-label="Success">↗️</span> Almost there! {randomEncouragement()}
// 			</>
// 		: Math.random() >= 0.5 ?
// 			randomAffirmation() : 
// 			randomEncouragement()
// 		}
// 	</>)
	
// }