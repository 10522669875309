import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import styles from './ProgressPage.module.scss';
// import { Link } from 'react-router-dom';

// import { randomEncouragement } from 'utils/randomAffirmation';
// import { getItemColor, computeTextColor } from 'utils/itemColorHelper';
import { NavControlService, ICONS } from 'components/PrimaryNav';
import { ServerStore } from '../../utils/ServerStore';
// import { useRemoteData } from '../../utils/useRemoteData';
import SimpleSpinner from '../../components/SimpleSpinner';
import { useRemoteData } from 'utils/useRemoteData';
import history from 'utils/history';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';

// import Avatar from '@material-ui/core/Avatar';
// import Typography from '@material-ui/core/Typography';
// import AlertIcon from '@material-ui/icons/NotificationImportant';
// import ChevronIcon from '@material-ui/icons/ChevronRight';


import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
// import VerseProgressDialog from 'components/VerseProgressDialog';

import CircleProgress from 'components/CircleProgress';
// import { round2digits } from 'utils/proctor/utils';
import seconds2duration from 'utils/seconds2duration';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import VerseBadgeLevel from 'components/VerseBadgeLevel';
import VerseProgressDialog from 'components/VerseProgressDialog';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Tooltip from '@material-ui/core/Tooltip';
// import ButtonBase from '@material-ui/core/ButtonBase';

import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { numberWithCommas } from 'utils/numberWithCommas';

// const seconds2duration = seconds => {
// 	const duration = moment.duration(seconds, 'seconds');
// 	return duration.format("h:mm:ss");
// }

export default function ProgressPage({ ...props }) {
	React.useEffect(() => {
		NavControlService.showPrimaryNav(true);
		NavControlService.setCurrentNav(NavControlService.NAV_PROGRESS);
	});

	React.useEffect(() => {
		// setStatusBarColor('#7951A9');
		setStatusBarColor('#000000'); // set to black because bar text is often white
	});

	// Hackish hook to scroll to the top once data loads
	const scrollTop = data => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 1);
		return data;
	}

	const startDate = moment
		.utc()
		.add(ServerStore.getUtcOffset(), 'minutes')
		.format("YYYY-MM-DD");

	const pointData = useRemoteData(() => ServerStore.GetUserPoints());

	// Used to render the sliding chart of days,
	// does not need to refresh every time we choose a day
	const calendarSummary = useRemoteData(() => ServerStore.GetSummary(startDate, {
		includeCalendarData: true,
	}).then(scrollTop));
	
	const [ summaryDate, setSummaryDateState ] = React.useState(startDate);
	
	const getSummary = ({ date, filterType }) => () => {
		const days = filterType === 'today' ? 1 :
			filterType === '7days' ? 7 :
			30;

		return ServerStore.GetSummary(date || summaryDate, {
			// Data for the circles
			includeGoalProgress:   true,
			goalProgressDays:      days,

			// Data for the list
			includeVerseLevels:    true,
			verseLevelDays:        days,

			// To suggest if verse list empty
			includeFavoriatePacks: true,
			
		});//.then(scrollTop);
	}

	const summary = useRemoteData(getSummary({ filterType: 'today' })); 

	const goalProgress = summary.goalProgress || {},
		// stats = summary.userStats || {},
		verses = Array.from(summary.verseLevels || []),
		calendarData = Array.from(calendarSummary.calendarData || []),
		args = summary.args || {},
		selectedDays = Array.from(goalProgress.days || []),
		favoriatePacks = Array.from(summary.favoriatePacks || []),
		emptyCtaPack = favoriatePacks && favoriatePacks.length ? 
			favoriatePacks[Math.floor(favoriatePacks.length * Math.random())] : null;

	// console.warn("fav debug:", [...favoriatePacks], {...(emptyCtaPack || {null:true})} );

	const [ selectedRow, setSelectedRow ] = React.useState();

	const [ lengthHeaderText, setLengthHeaderText ] = React.useState('Today');
	const [ lengthBodyText, setLengthBodyText ] = React.useState('today');

	const [ filterType, setFilterTypeState ] = React.useState('today');

	const updateLengthLabels = (type, date) => {
		const localDate = new Date(date || summaryDate).toLocaleDateString();

		setLengthHeaderText(type === 'today' ? 
			(isToday(date) ? 'Today' : 'on ' + localDate) :
			type === '7days' ? '- 7 Days':
			'- All'
		);

		setLengthBodyText(type === 'today' ? 
			(isToday(date) ? 'today' : 'on ' + localDate) :
			type === '7days' ? 'over the previous 7 Days':
			'all time'
		);
	}

	const setFilterType = type => {
		ServerStore.metric('app.progress_page.filter_type_changed.' + type);

		setFilterTypeState(type);
		summary.reloadData(getSummary({ filterType: type }));

		updateLengthLabels(type);
	};

	const [ chartOpen, setChartOpen ] = React.useState(false);

	const isToday = (date) => {
		return (date || summaryDate) === startDate
	}

	const onDayClicked = (date) => {
		ServerStore.metric('app.progress_page.chart.day_clicked', null, {
			date,
			filterType
		});

		setSummaryDateState(date);
		updateLengthLabels(filterType, date);
		summary.reloadData(getSummary({ date, filterType }));
	}
	
	return (<div className={styles.root}>

		<IconButton
			className={styles.settingsLink}
			onClick={() => {
				ServerStore.metric('app.progress_page.settings.clicked');
				history.push('/settings')
			}}
		>
			<ICONS.SettingsIcon/>
		</IconButton>

		<h1>{ServerStore.currentUser.name}</h1>

		{!pointData.loadDone || pointData.points > 0 ?
			<Tooltip
				title="Points are earned as you practice verses and progress in memorization levels"
			>
				<div className={styles.mainPoints}>
					<div className={styles.data}>
						<span className={styles.value}>
							{!pointData.loadDone ?
								<SimpleSpinner/>
								:
								numberWithCommas(pointData.points)
							}
						</span>
					</div>
					<div className={styles.dataSubtext}>
						Points
					</div>
				</div>
			</Tooltip>
		: ""}

		{!summary.loadDone ?
			<SimpleSpinner fullpage={true}/>
		: ""}
		{/* {true ? <> */}

			<VerseProgressDialog
				row={selectedRow}
				onCancel={() => setSelectedRow(null)}
			/>

			<div 
				className={styles.letter}
				// onClick={() => setHeaderInfoOpen(!headerInfoOpen)}
			>
				<div className={styles.text}>
					{(ServerStore.currentUser.name+"").substring(0,1)}
					<CircleProgress 
						value={Math.ceil(goalProgress.timeDayPercent || 0)}
						className={styles.badgeCircle}
						size={100}
					/>
					<CircleProgress 
						value={Math.ceil(goalProgress.versesDayPercent || 0)}
						className={styles.progressCircle}
						size={116}
					/>
				</div>
			</div>
			
			<div className={styles.headerInfo}>

				<div className={styles.circles}>
					<div className={clsx(styles.block, styles.badgeLevel)}>
						<CircleProgress 
							value={Math.ceil(goalProgress.timeDayPercent || 0)}
							className={styles.badgeCircle}
							size={32}
						/>
						<div className={styles.info}>
							<label>Time {
									args.goalProgressDays && lengthBodyText === 'all time' ? 
										`- ${args.goalProgressDays} days` :
										lengthHeaderText
								}</label>
							<label className={styles.hint}>
								Time you've spent memorizing verses {
									args.goalProgressDays && lengthBodyText === 'all time' ? 
										`${isToday() ? 'over the past' : 'over the previous'} ${args.goalProgressDays} days` :
										lengthBodyText
								}
							</label>
							<div className={styles.data}>
								<span className={styles.value}>
									{seconds2duration( (goalProgress.timeDay || 0) * 60 )}
								</span>
								/
								<span className={styles.max}>
									{goalProgress.goalTimeDay || 0}
								</span>
							</div>
							<div className={styles.dataSubtext}>
								minutes
							</div>
						</div>
					</div>
{/* 
					<Tooltip
						title="Points are earned as you practice verses and progress in memorization levels"
					>
						<div className={styles.points}>
							

							<div className={styles.data}>
								<span className={styles.value}>
									59,619
								</span>
							</div>
							<div className={styles.dataSubtext}>
								Points
							</div>
						</div>
					</Tooltip> */}

					<div className={clsx(styles.block, styles.progressPercent)}>
						<CircleProgress 
							value={Math.ceil(goalProgress.versesDayPercent || 0)}
							className={styles.progressCircle}
							size={32}
						/>

						<div className={styles.info}>
							<label>Verses {
									args.goalProgressDays && lengthBodyText === 'all time' ? 
										`- ${args.goalProgressDays} days` :
										lengthHeaderText
								}</label>
							<label className={styles.hint}>
								Number of verses that you've practiced {
									args.goalProgressDays && lengthBodyText === 'all time' ? 
										`${isToday() ? 'over the past' : 'over the previous'} ${args.goalProgressDays} days` :
										lengthBodyText
								}
							</label>
							<div className={styles.data}>
								<span className={styles.value}>
									{Math.ceil( goalProgress.versesDay || 0 )}
								</span>
								/
								<span className={styles.max}>
									{goalProgress.goalVersesDay || 0}
								</span>
							</div>
							<div className={styles.dataSubtext}>
								verses
							</div>
						</div>
					</div>
				
				</div>

				<div className={styles.filterBox}>

					<ButtonGroup color="primary" size="small">
						<Button 
							className={styles.todayBtn}
							variant={filterType === 'today' ? 'contained' : 'outlined'}
							onClick={() => {
								if(filterType === 'today') {
									ServerStore.metric('app.progress_page.chart.' + (chartOpen ? 'closed' : 'opened'));
									setChartOpen(!chartOpen);
								} else {
									setFilterType('today');
								}
							}}
						>
							{isToday() ? 'Today' : new Date(summaryDate).toLocaleDateString()}
							
							{chartOpen ? 
								<ArrowDropUpIcon/>
								:
								<ArrowDropDownIcon/>
							}
						</Button>
						<Button
							variant={filterType === '7days' ? 'contained' : 'outlined'}
							onClick={() => setFilterType('7days')}
						>
							7 Days
						</Button>
						<Button
							variant={filterType === 'all' ? 'contained' : 'outlined'}
							onClick={() => setFilterType('all')}
						>
							All
						</Button>
					</ButtonGroup>
				</div>

				<div className={clsx(
					styles.dayChart,
					chartOpen && styles.open,
				)}>
					{calendarData.map(({
						date,
						isExtraDay,
						isAfterToday,
						score,
						meta
					}, index) => <ScrollIntoViewIfNeeded
						key={date}
						active={date === summaryDate}
						className={clsx(
							styles.day,
							date === startDate && styles.today,

							// Highlight all days up until selected date if "All" filter
							...(!selectedDays.length && summary.loadDone ? [
								styles.selected,
								date === summaryDate && styles.selectedLast,
							] : []),

							// If not all filter, highlight just days used for graphs/verses
							selectedDays.includes(date) && styles.selected,
							selectedDays && selectedDays[0] === date && styles.selectedFirst,
							selectedDays && selectedDays[selectedDays.length-1] === date && styles.selectedLast,

							isExtraDay && styles.extraDay,
							isAfterToday && styles.afterToday,
							score !== undefined ? styles.hasScore : (
								isAfterToday ? "" : styles.scoreMissing
							),
							// (
							// 	getScoreClass(score)
							// 	// score > -0.25 ? styles.score1of4 :
							// 	// score > -0.5  ? styles.score2of4 :
							// 	// score > -0.75 ? styles.score3of4 :
							// 	//                 styles.score4of4
							// )
							index % 2 && styles.showLabel,
						)}
						onClick={() => !isAfterToday && 
							onDayClicked(date, { isExtraDay, isAfterToday, score, meta })
						}
						
					>
						{!isAfterToday ?
							<div 
								className={styles.score}
								style={{
									'--time-percent': meta && meta.timeDayPercent + '%',
									'--verses-percent': meta && meta.versesDayPercent + '%',
								}}
							>
								{/* {score === undefined ? '?' : Math.ceil(score * 100) + '%'} */}
							</div>
						:""}


						<label>
							{
								isExtraDay ? 
									moment(date).format('MMM D') :
									parseFloat(date.split('-')[2])
							}
						</label>
					</ScrollIntoViewIfNeeded>)}

				</div>


				{/* <div className={styles.stats}>
					<div data-stat="numVerses">
						<span>{stats.numVerses}</span>
						<label>verses</label>
					</div>
					{stats ? <>
						<div data-stat="avgComprehension">
							<span>
								{Math.round(stats.avgComprehension * 100)}%
							</span>
							<label>score</label>
						</div>
						<div data-stat="lastTested">
							<span>
								{moment(stats.lastTested).fromNow().replace('minutes','min')}
							</span>
							<label>last practiced</label>
						</div>
						<div data-stat="numTests">
							<span>
								{stats.numTests} x
							</span>
							<label>practices</label>
						</div>
						<div data-stat="totalTime">
							<span>
								{seconds2duration(stats.totalTime)} min
							</span>
							<label>total</label>
						</div>
					</> : ""}
				</div> */}

			</div>

			<div className={clsx(
				styles.verses,
				chartOpen && styles.chartOpen,
			)}>
				{goalProgress.points > 0 ?
					<Tooltip
						title="Points are earned as you practice verses and progress in memorization levels"
					>
						<div className={styles.points}>
							<div className={styles.data}>
								<span className={styles.value}>
									+{numberWithCommas(goalProgress.points)}
								</span>
							</div>
							<div className={styles.dataSubtext}>
								Points {lengthHeaderText}{
									goalProgress.goalProgressDays > 1 ?
										` (${numberWithCommas(goalProgress.avgPointsDay)} avg points/day)`
									: ''
								}
							</div>
						</div>
					</Tooltip>
				: ""}

				{summary.loadDone && verses.length <= 0 ? <>
					<div className={styles.emptyCta}>
						{isToday() ? <>
							You haven't practiced any verses today
						</> : <>
							It doesn't look like you've practiced any verses for the date(s) selected
						</>}
						
						{emptyCtaPack ? <>
							<div className={styles.ctaButtons}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => history.push('/packs/' + emptyCtaPack.id)}
								>
									{/* <ICONS.PacksIcon style={{fontSize:'1rem'}}/> */}
									Why not practice "{emptyCtaPack.name}"?
								</Button>
							</div>
						</> : ""}
					</div>
				</> : ""}
				
				<List 
					className={styles.versesList}
					dense
				>
					{verses.map(row =>
						<ListItem
							key={row.cachedRef}
							button
							onClick={() => setSelectedRow(row)}
						>
							<ListItemAvatar>
								<VerseBadgeLevel 
									verse={{ stats: row }}
								/>
							</ListItemAvatar>
							<ListItemText primary={row.cachedRef} seconday={row.verse.text}/>
						</ListItem>
					)}
				</List>
			</div>
		{/* </>} */}
	</div>)
}
