import React from 'react';
import styles from './SettingsPage.module.scss';
import clsx from 'clsx';
// import moment from 'moment';

import VersionInput from 'components/VersionInput';
import history from 'utils/history';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';

// Login logic (loads FB SDK automatically)
import FacebookLogin from 'components/FacebookLogin';

import { NavControlService } from 'components/PrimaryNav';
import { ServerStore, FB_APP_ID } from '../../utils/ServerStore';
import { useRemoteData } from '../../utils/useRemoteData';
import SimpleSpinner from '../../components/SimpleSpinner';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListSubheader from '@material-ui/core/ListSubheader';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// import DragHandleIcon from "@material-ui/icons/DragHandle";


// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// // import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';


import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import CircularProgress from '@material-ui/core/CircularProgress';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { Typography, Tooltip } from '@material-ui/core';

// import { MIN_SPINNER_MS } from '../../utils/MyVersesCoreService';
// import { defer } from '../../utils/defer';

export default function SettingsPage({ ...props }) {
	React.useEffect(() => {
		NavControlService.showPrimaryNav(true);
		NavControlService.setCurrentNav(NavControlService.NAV_SETTINGS);
	});

	React.useEffect(() => {
		// setStatusBarColor('#7951A9');
		setStatusBarColor('#000000'); // set to black because bar text is often white
	});

	const ver      = useRemoteData(() => ServerStore.appVersion());
	const settings = useRemoteData(() => ServerStore.GetSettings()); //, 'settingsChanged');

	const logoutAction = async () => {
		await ServerStore.metric("app.settings.logout_clicked").flush();
		await ServerStore.logout();
		history.push('/');
	}

	// Re-enable once FB re-approves which is waiting on Google to reapprove
	const showFbLogin = false;

	const [ fbIsConnected, setFbIsConnected ] = React.useState(ServerStore.currentUser.isFacebookUser);

	// const disconnectFb = () => {
	// 	ServerStore.unlinkFb().then(store => {
	// 		if(store) {
	// 			setFbIsConnected(false);
	// 		} else {
	// 			window.Alert("Could not update server");
	// 		}
	// 	})
	// }

	const facebookConnected = (response) => {
		if(!response.accessToken) {
			return;
		}
		ServerStore.linkFb(response.accessToken).then(store => {
			if(store) {
				ServerStore.metric("app.settings.connected_fb");
				setFbIsConnected(true);
			} else {
				window.Alert("Could not authenticate with server");
			}
		})
	}

	return (<>
		<div className={styles.root}>
			
			<h1 className={styles.banner}>Settings</h1>
			
			{!settings.loadDone ?
				<SimpleSpinner fullpage={true}/>
			: 
			<div className={styles.settingsFields}>
				<MaterialSettingsPanel remoteSettings={settings}/>

				{!fbIsConnected && showFbLogin ? <div 
					className={styles.connectFb}
				>
					<FacebookLogin
						appId={FB_APP_ID}
						callback={facebookConnected}
						render={renderProps => <>{
							renderProps.isLoading ?
								<div className="fb-state-pending">
									Loading magic sparkles...
								</div>
								:
							renderProps.hasError ? 
								<div className="fb-state-error">
									Sorry, {renderProps.error.message}
								</div>
								:
							renderProps.hasAuthResponse ? 
								<div className="fb-state-pending">
									Connecting the wires...
								</div>
							:
							<>
								<Button
									onClick={renderProps.onClick}
								>
									Connect facebook
								</Button>

								<p>Connect your FB account so you can log activities on any device and discover friends also using MyVerses!</p>
							</>
						}</>}
					/>
				</div> : ""}

				<Button 
					className={styles.btnLogout}
					onClick={logoutAction}
				>
					Logout
				</Button>
				
				<small className={clsx(styles.versionInfo, styles['text-muted'])}>
					App Version {ver.runningVer}<br/>
					{ver.runningBuildTime ? <>
						Built {ver.runningBuildTime.toLocaleDateString()}<br/>
					</> : ""}
					{/* ({moment(new Date(ver.runningBuildTime)).fromNow()})<br/> */}
					User ID {ServerStore.currentUser.id}
				</small>
			</div>}
		</div>
	</>);
}


const useStylesForSampleMaterialUISettings = makeStyles(theme => ({
	root: {
		width: '100%',
		// maxWidth: 360,
		color: 'black',
		backgroundColor: 'transparent', //theme.palette.background.paper,
	},
}));

const outerTheme = createMuiTheme({
	palette: {
	  secondary: {
		main: '#7951A9',
	  },
	},
});

const patchSettings = async (patch, options={instant: false}) => {
	if(patchSettings.tid) {
		clearTimeout(patchSettings.tid);
	}
	if(!patchSettings.patch) {
		patchSettings.patch = {};
	}
	patchSettings.patch = {
		...patchSettings.patch,
		...patch
	};

	if(options.instant) {
		return await ServerStore.UpdateSettings(patchSettings.patch);
	}
	// Use setTimeout to buffer updates
	patchSettings.tid = setTimeout(async () => {
		/*const res = */await ServerStore.UpdateSettings(patchSettings.patch);
		// console.log("[patchSettings]", res, patchSettings.patch);
	}, 1000);
};


function MaterialSettingsPanel({ remoteSettings }) {

	const classes = useStylesForSampleMaterialUISettings();
	// const remoteSettings = useRemoteData(() => ServerStore.GetSettings());

	const [ settings, setSettings ] = React.useState({});
	React.useEffect(() => {
		if(remoteSettings.loadDone) {
			if (remoteSettings.emergencyNumber === null) {
				remoteSettings.emergencyNumber = '911';
			}
			if (remoteSettings.disableRunDetection === null) {
				remoteSettings.disableRunDetection = false;
			}
			if (remoteSettings.sendTimerStartStopNotices === null) {
				remoteSettings.sendTimerStartStopNotices = false;
			}
			setSettings({ ...remoteSettings });
		}
	}, [ remoteSettings ]);

	const updateSettings = async (field, value) => {
		patchSettings({ [field]: value });
		setSettings({ ...settings, [field]: value });
	};

	const settingsPrompt = async (field, { title, type: input, hintText, min, max } = { title: "", input: 'text' }) => {
		// const value = prompt(field === 'sosPasscode' ? 'Safety PIN' : 'Your Name', settings[field]);
		const getInput = async (hintOverride) => {
			const { value } = await window.Alert({
				title, //: field === 'sosPasscode' ? 'Safety PIN' : 'Your Name',
				text: hintOverride ||
					(input === "password" ? 
						'Change your password below, click Cancel to keep it the same.'
						: 
						(hintText || '')
					),
				input: input || 'text', //: field === 'sosPasscode' ? 'number' : 'text',
				inputValue: input === 'password' ? "" : settings[field],
				showCancelButton: true,
				inputValidator: value => {
					if(!value) {
						return "You need to input something!"
					}

					if(min !== undefined && parseFloat(value) < min) {
						return("Sorry, that's too low.")
					}

					if(max !== undefined && parseFloat(value) > max) {
						return("Sorry, that's too high.")
					}
				},
			});
			return value;
		};
		const value = await getInput();
		if(value) {
			if(input === 'password') {
				title = "Confirm Password";
				const value2 = await getInput("Confirm your new password, or press Cancel to abort the change.");
				if(!value2) {
					return null;
				}
				if(value !== value2) {
					await window.Alert({
						type: 'error',
						title: 'Sorry, passwords don\'t match',
					});
					return null;
				}
			} else {
				ServerStore.metric("app.settings.changed." + field, null, {
					value
				});
			}

			updateSettings(field, value);
		}
	}

	return (
		<ThemeProvider theme={outerTheme}>
			<AppBar position="fixed" className={styles.appBar}>
				<Toolbar>
					<IconButton 
						edge="start"
						className={styles.menuButton}
						color="inherit"
						aria-label="menu"
						onClick={() => window.history.go(-1)}
					>
						<ChevronLeftIcon />
					</IconButton>
					<Typography variant="h6" className={styles.title}>
						Settings
					</Typography>
					{/* <Button color="inherit">Login</Button> */}
				</Toolbar>
			</AppBar>

			{!remoteSettings.loadDone ?
				<SimpleSpinner fullpage={true}/> 
			:<>
			<List 
				// subheader={<ListSubheader>Settings</ListSubheader>} 
				className={clsx(classes.root, styles.settingsList)}
				dense>
				{/* <ListSubheader disableSticky className={styles.myListItem}>
					Customizations
				</ListSubheader> */}
				
				<Tooltip title="Change the name of the person registered in this app">
					<ListItem button className={styles.myListItem}
						onClick={() => settingsPrompt('name', { title: 'Your Name' })}>
						<Typography variant='body2'>Your Name</Typography>
						<Typography align='right'>{settings.name}</Typography>
					</ListItem>
				</Tooltip>
				<Tooltip title="Change your email used to login">
					<ListItem  button className={styles.myListItem} 
						onClick={() => settingsPrompt('email', {
							title: 'Email',
							type: 'email'
						})}>
						<Typography variant='body2'>Email</Typography>
						<Typography align='right'>{settings.email}</Typography>
					</ListItem>
				</Tooltip>
				<Tooltip title="Change your password">
					<ListItem  button className={styles.myListItem} 
						onClick={() => settingsPrompt('password', {
							title: 'Password',
							type: 'password'
						})}>
						<Typography variant='body2'>Password</Typography>
						<Typography align='right'>{settings.password ? "********" : <i></i>}</Typography>
					</ListItem>
				</Tooltip>
				<ListItem  className={styles.myListItem}>
					<Typography variant='body2'>Preferred Version</Typography>
					<VersionInput
						className={styles.versionSelect}
						defaultValue={settings.preferredVersion}
						onChange={ver => {
							ServerStore.metric("app.settings.changed.preferred_version", null, {
								ver: ver.abbrv
							});
							updateSettings('preferredVersion', ver);
						}}
					/>
				</ListItem>
				<Tooltip title="Change your goal verses per day">
					<ListItem  button className={styles.myListItem} 
						onClick={() => settingsPrompt('goalVersesDay', {
							title: 'Goal Verses per Day',
							type: 'number',
							hintText: 'How many verses do you want to try to practice per day?',
							min: 1,
							max: 5000
						})}>
						<Typography variant='body2'>Goal Verses/Day</Typography>
						<Typography align='right'>{settings.goalVersesDay || '1'} vss/day</Typography>
					</ListItem>
				</Tooltip>
				<Tooltip title="Change your goal minutes per day">
					<ListItem  button className={styles.myListItem} 
						onClick={() => settingsPrompt('goalTimeDay', {
							title: 'Goal Minutes per Day',
							type: 'number',
							hintText: 'How many minutes do you want to try to practice per day?',
							min: 1,
							max: 5000
						})}>
						<Typography variant='body2'>Goal Minutes/Day</Typography>
						<Typography align='right'>{settings.goalTimeDay || '1'} min/day</Typography>
					</ListItem>
				</Tooltip>
				
			</List>
		</>}
		</ThemeProvider>
	);
}

