export default function nameForLevel(level) {
	return [
		"", //0
		"Beginner", //1
		"Student", //2
		"Scholar", //3
		"Scribe", //4
		"Expert", //5

		// From verse-levels db def description:
		// 1 - Beginner / no level
		// 2 - Student
		// 3 - Scholar
		// 4 - Scribe
		// 5 - Expert

	][Math.ceil(level)];
}