import MobileDetect from 'mobile-detect';
import { isPhoneGap } from './isPhoneGap';

const md = new MobileDetect(window.navigator.userAgent);

// Thanks to https://stackoverflow.com/a/41749865/1119559
// Detects if added to homescreen as a PWA
const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

// Based on https://stackoverflow.com/questions/46318395/detecting-mobile-device-notch/46362263
const hasNotch = function () {
    var proceed = false;
    var div = document.createElement('div');
    if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
        div.style.paddingBottom = 'env(safe-area-inset-bottom)';
        proceed = true;
    } else if (CSS.supports('padding-bottom: constant(safe-area-inset-bottom)')) {
        div.style.paddingBottom = 'constant(safe-area-inset-bottom)';
        proceed = true;
    }
    if (proceed) {
        document.body.appendChild(div);
        let calculatedPadding = parseInt(window.getComputedStyle(div).paddingBottom);
        document.body.removeChild(div);
        if (calculatedPadding > 0) {
            return true;
        }
    }
    return false;
};

export const mobileDetect = {
	md,
	hasNotch:
		// If not standalone and not PhoneGap'd, then
		// there likely is browser chrome above the app, so technically *we* don't "have notch",
		// even though the device may have a notch.
		(isStandalone || isPhoneGap) && 
		hasNotch()
		// (md.is('iPhone') && md.version('iPhone') >= 10.0),
};