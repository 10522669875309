import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import styles from './StatsBar.module.scss';
import seconds2duration from 'utils/seconds2duration';

export default function StatsBar({
	stats,
	numVerses,
	className
}) {


	return (<>
		<div className={clsx(styles.stats, className)}>
			{numVerses ? <>
				<div data-stat="numVerses">
					<span>
						{numVerses}
					</span>
					<label>verses</label>
				</div>
			</> : ""}
			{stats ? <>
				<div data-stat="avgComprehension">
					<span>
						{Math.round(stats.avgComprehension * 100)}%
					</span>
					<label>score</label>
				</div>
				<div data-stat="lastTested">
					<span>
						{moment(stats.lastTested).fromNow().replace('minutes','min')}
					</span>
					<label>last practiced</label>
				</div>
				<div data-stat="numTests">
					<span>
						{stats.numTests} x
					</span>
					<label>practices</label>
				</div>
				<div data-stat="totalTime">
					<span>
						{seconds2duration(stats.totalTime)} min
					</span>
					<label>total</label>
				</div>
			</>:""}
		</div>
	</>);
}