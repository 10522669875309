import React from 'react';
import clsx from 'clsx';
import history from 'utils/history';
// import moment from 'moment';
import cx from 'clsx';
import styles from './ContinuePracticeCard.module.scss';
import { getItemColor, computeTextColor } from 'utils/itemColorHelper';
import { Card, CardActionArea, Tooltip } from '@material-ui/core';
import { ServerStore } from 'utils/ServerStore';
import { START_NOW_KEY } from '../PackPage/PackPage';

export default function ContinuePracticeCard({
	pack,
	className,
	badgeText,
	badgeClass,
	badgeTitle = ''
}) {
	const onClick = async () => {
		window.localStorage.setItem(START_NOW_KEY, 'true')
		ServerStore.metric('app.continue_practice.clicked', null, {
			pack: pack.id
		});
	
		setTimeout(
			() => history.push(`/pack/${pack.id}`),
			0
		);
	}

	const cursor = (pack || {}).cursor || {};

	return <div 
		className={cx(styles.root, className, styles.variantSmall)}
	>
		<Card
			data-hasprogress={cursor.progressPercent ? "true" : "false"}
			style={ pack ? {
				'--cover-color': getItemColor(pack.imageMeta),
				'--cover-text': computeTextColor(getItemColor(pack.imageMeta)),
				'--cover-shade': computeTextColor(getItemColor(pack.imageMeta), true),
				'--progress-percent': `${cursor.progressPercent || 0}%`
			} : {} }
		>
			<CardActionArea
				onClick={onClick}
			>
				{pack && pack.imageUrl ?
					<div 
						style={{
							// backgroundImage: `url("${pack.imageUrl}")`
						}}
						className={styles.media}
					>

						<div className={styles.name}>
							{pack.name}
						</div>
					</div>
				: ""}
				{cursor.progressPercent ? 
					<div className={styles.progressPercent}/>
				: ""}
				{badgeText ? 
					<Tooltip title={badgeTitle || badgeText}>
						<div className={clsx(styles.badgeText, badgeClass)}>
							<label>
								{badgeText}
							</label>
						</div>
					</Tooltip>
				: ""}
			</CardActionArea>
		</Card>
	</div>;
	
}