import React from 'react';
import styles from './VerseBox.module.scss';
import clsx from 'clsx';
import Proctor from 'utils/proctor/Proctor';
import { ServerStore } from 'utils/ServerStore';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PlayIcon from '@material-ui/icons/PlayArrow';
// import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import VerseBadgeLevel from './VerseBadgeLevel';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

const { AnswerTypes } = Proctor.AnswerManager;

export default function VerseBox({
	proctor,
	verse: verseRef,
	onNext = () => {},
	onAttemptComplete = () => {},
	resultsLoading,
	attemptResults
}) {
	const [ answerList, setAnswerList ] = React.useState([]);
	const [ textAnswer, setTextAnswer ] = React.useState();
	const [ answerIncomplete, setAnswerIncomplete ] = React.useState(true);

	const {
		text: originalText,
		id,
	} = verseRef;

	window.proctor = proctor;

	// console.log("VerseBox, got verseRef=", verseRef);

	const answerType = AnswerTypes.FILL_IN_BLANKS;

	const [ verse, setVerse ] = React.useState();
	const [ verseId, setVerseId ] = React.useState();

	const [ keyboardMode, setKeyboardmode ] = React.useState(false);
	const [ oldKeyboardMode, setOldKeyboardMode ] = React.useState();

	const [ caretAdjustNeeded, setCaretAdjustNeeded ] = React.useState();

	const openKeyboardMode = () => {
		setCaretAdjustNeeded(true);
		setKeyboardmode(true);
	}

	const closeKeyboardMode = () => {
		setCaretAdjustNeeded(false);
		setKeyboardmode(false);
	}

	const [ hintText, setHintText ] = React.useState();
	const [ hintOpen, setHintOpen ] = React.useState();
	const [ hintsGiven, setHintsGiven ] = React.useState(0);
	const [ lowerPrompted, setLowerPrompted ] = React.useState(false);
	const [ tokenizedWords, setTokenizedWords ] = React.useState(null);

	const [ resultMode, setResultMode ] = React.useState({
		active: false,
		verseRef: {},
		success: false,
		failure: false,

		// active: true,
		// verseRef: verseRef,
		// success: true,
		// failure: false,
	});

	const verseChanged = React.useCallback(() => {
		const result = proctor.getAnswerOptions(verseRef, {
			answerType,
		});
		if(answerType === AnswerTypes.FILL_IN_BLANKS) {
			// console.warn({ verseRef, result });
			setAnswerList(result.answers.slice());
			setVerse(result.newVerse);
			setTokenizedWords(result.tokenizedWords);
		} else {
			setAnswerList(result.slice());
			setVerse(originalText);
			setTokenizedWords(null);
		};
	}, [ verseRef, proctor, answerType, originalText ]);

	// If controller changed verses, disable mode
	if(resultMode.active && resultMode.verseRef.id !== verseRef.id) {
		setResultMode({
			active: false,
		});
		setTextAnswer("");
		setHintsGiven(0);
		setLowerPrompted(false);
		setAnswerIncomplete(true);
		if(oldKeyboardMode) {
			openKeyboardMode();
		} else {
			closeKeyboardMode();
		}
	}

	React.useEffect(() => {
		if(!originalText) {
			return;
		}
		
		if(!answerList.length || id !== verseId) {
			setVerseId(id);
			verseChanged();
		}
	}, [ verseChanged, id, verseId, answerList, originalText ]);

	const answerHint = () => {
		if(hintOpen) {
			return setHintOpen(false);
		}

		// Use proctor namespace to keep consistency in metrics
		ServerStore.metric('app.proctor.answer.hint');

		const badgeLevel = (verseRef.stats && verseRef.stats.badgeLevel) || 1;
		console.log(`[answerHint] `, { badgeLevel, hintsGiven, lowerPrompted, verseRef });
		if(badgeLevel > 1
			&& hintsGiven > 0
			&& lowerPrompted !== badgeLevel) {
			setLowerPrompted(badgeLevel);
			setHintsGiven(0);

			ServerStore.metric('app.proctor.answer.hint.easy_button_prompt', null, {
				currentBadgeLevel: badgeLevel
			});
			
			const promptResult = window.confirm("It looks like you might not remember verse, and that's okay!!\n\nDo you want me to make it a bit easier?\n\n(Click OK to make it easier, or click CANCEL to stay at the current level.)");

			if(promptResult) {
				// Lower LOCALLY, NOT POSTED TO SERVER
				// It will only be recorded on the server if they finish this attempt
				// via the LogAttempt routine flow
				verseRef.stats.badgeLevel = badgeLevel - 1;

				// Reset state to start of the question
				verseChanged();

				ServerStore.metric('app.proctor.answer.hint.easy_button_prompt.accepted', null, {
					newBadgeLevel: verseRef.stats.badgeLevel
				});

				return;
			}
		} else {
			setHintsGiven(hintsGiven + 1);
		}
		
		// Deduct from "correct" later
		proctor.attemptManager.gaveHint();

		const tok = tokenizedWords;
		// const firstBlank = tok.find(x => x.blank && !x.blankText);
		// const hint = firstBlank && firstBlank.text;

		const firstBlankIndex = tok.findIndex(x => x.blank && !x.blankText);
		const hintWordsMin = 3; // minimum non-stop words
		const hintBuffer = [];
		
		let nonStopCount = 0;
		tok.slice(firstBlankIndex).forEach((token, idx) => {
			const { text, pre, post, isStopword } = token;
			
			if(!isStopword) {
				nonStopCount ++;
			}
			
			if(nonStopCount < hintWordsMin) {
				hintBuffer.push(`${pre}${text}${post}`);
			}

			console.log(`* [idx=${idx} / nonStopCount=${nonStopCount}] text=${text}, isStopword=${isStopword}`, hintBuffer);
		});

		console.log({ firstBlankIndex, hintBuffer, tok });

		const hint = hintBuffer.length
			? (firstBlankIndex === 0 ? '' : '…') + hintBuffer.join('') + '…'
			: null;

		setHintOpen(true);
		if(!hint) {
			setHintText({ error: "No hints available" });
		} else {
			setHintText({ text: hint });
			setTimeout(() => setHintOpen(false), 5000);
		}
	}

	const closeHint = () => setHintOpen(false);

	const nextAction = (success, originalVerse, correctAnswer) => {
		setAnswerList([]);
		onNext(success, originalVerse, correctAnswer);
	}

	const tryUndo = () => {
		if(resultMode.active) {
			return;
		}
		
		if(answerType === AnswerTypes.FILL_IN_BLANKS) {
			const {
				tokenizedWords,
				updatedVerse, 
				updatedAnswerList,
				sanatizedAnswer
			} = proctor.undoLastTryAnswer(verseRef);

			setAnswerList(updatedAnswerList.slice())
			setVerse(updatedVerse);
			setTokenizedWords(tokenizedWords);
			setTextAnswer(sanatizedAnswer.replace(/__.*/g, ''));
		}
	}
	
	const tryAnswer = (verseRef2, possible, idx) => {
		closeHint();

		// console.warn("[VerseBox] (tryAnswer) received verseRef:", verseRef2);
		const res = proctor.tryAnswer(verseRef2, possible, idx);

		// console.log("[VerseBox] tryAnswer res=", res);

		const { 
			good, 
			incomplete, 
			updatedVerse, 
			updatedAnswerList,
			tokenizedWords,
			sanatizedAnswer
		} = res;

		if(updatedVerse) {
			setVerse(updatedVerse);
		}
		
		if(tokenizedWords) {
			setTokenizedWords(tokenizedWords);
		}

		if(updatedAnswerList) {
			setAnswerList(updatedAnswerList.slice())
		}
		
		if(sanatizedAnswer) {
			setTextAnswer(sanatizedAnswer.replace(/__.*/g, ''));
		}

		setAnswerIncomplete(!!incomplete);

		if(incomplete) {
			return;
		}

		if (onAttemptComplete) {
			onAttemptComplete();
		}

		// nextAction(good ? true : false, verseRef);
		setOldKeyboardMode(keyboardMode);
		closeKeyboardMode();
		setResultMode({
			active: true,
			verseRef: verseRef,
			success: !!good,
			failure:  !good,
		});
		
		// const data = answerList.find(a => a.answer === possible);
		// if(data) {
		// 	data.good = good;
		// 	data.bad = bad;
		// } else {
		// 	throw new Error("Weird, didn't find 'possible' var in list of answers");
		// }

		// // Must make a copy or no re-render
		// setAnswerList(answerList.slice());

		// if(good) {
		// 	nextAction(true);
		// }
	}

	// const processKey = event => {
	// 	if(event.which === 8) {
	// 		return tryUndo();
	// 	}
	// 	// const keyCode = event.which || event.keyCode || 0;
	// 	const keyName = String.fromCharCode(event.charCode);
	// 	// console.log("Pressed:", keyName)

	// 	const num = parseInt(keyName);
	// 	if(!isNaN(num) && num >= 1 && num <= answerList.length) {
	// 		const idx = num - 1,
	// 			answer = answerList[idx].text;
	// 		// console.log("Pressed key:", num,", trying answer:", answer);
	// 		setTimeout( () => tryAnswer(verseRef, answer, idx), 100);
	// 	}
	// }

	// React.useEffect(() => {
	// 	document.addEventListener("keydown", processKey, false);
	// 	return () => document.removeEventListener("keydown", processKey, false);
	// });

	// This effect toggles the '.overflows' class on our question box.
	// By default, questions are `justify-content: center`
	// which works great for small verses - centers them vertically in the space.
	// However, long verses or small spaces would overflow, requiring scrolling.
	// Problem is, that when scrolling with 'center' alignment,
	// the flexbox never shows the start of the content, so content goes above start,
	// and you can't scroll up to it. So here, we just toggle a class in our SCSS
	// that if it's .overflows, then it sets `justify-content: flex-start` to fix that.
	const refQuestionBox = React.useRef();
	const [ overflows, setOverflows ] = React.useState(false);
	React.useEffect(() => {
		const onResize = () => {
			if(!refQuestionBox.current) {
				return;
			}
			const el = refQuestionBox.current,
				overflows = el.scrollHeight > el.clientHeight;
			
			// el.classList.toggle(styles.overflows, overflows);
			setOverflows(overflows);
		}
		// Run right away after render
		setTimeout(onResize, 0);
		const int = setInterval(onResize, 1000);

		// Run again if window resizes / device rotates / whatever
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
			clearInterval(int);
		}
	});

	// console.log("[VerseBox] rendering verse:", verse);//, verseRef);
	const RenderTokenizedWords = ({tok}) => {
		const firstIdx = tok.findIndex(x => x.blank && !x.blankText);
		const firstTextIdx = tok.findIndex(x => !x.hasTextValue);
		const rawTextMode = tok.some(x => x.hasTextValue);
		let firstActiveIndx = rawTextMode ? firstTextIdx : firstIdx;
		if(rawTextMode && firstActiveIndx < 0) {
			firstActiveIndx = tok.length - 1;
		}

		return (<>{tok.map(({ text, pre, post, blank, blankText }, idx) => {
			const m = blankText && blankText.match(/<<([^>]+)>>\[([+x])(?::([^\]]+))?\]/);
			let out;
			if(m) {
				out = <span className={clsx(
						'Proctor-attemptedAnswer',
						m[2] === 'x' ? '--bad' : '--good'
					)}>
						{m[2] === 'x' ? <>
							<span className='--got-word'>{m[1]}</span>
							<span className='--expected-word'>{m[3]}</span>
						</> : m[1]}
					</span>
			} else {
				const realBlank = new Array(text.length).fill('_').join('');
				out = blank ? 
					<ScrollIntoViewIfNeeded 
						elementType='span'
						active={idx === firstActiveIndx}
					>
						<span className={clsx((idx === firstIdx) && styles.activeBlank)}>
							{(blankText || realBlank)}
						</span>
					</ScrollIntoViewIfNeeded>
				: rawTextMode ?
					<ScrollIntoViewIfNeeded 
						elementType='span'
						active={idx === firstActiveIndx}
					>
						<span className={clsx((idx === firstActiveIndx) && styles.activeText)}>
							{text}
						</span>
					</ScrollIntoViewIfNeeded>
				: text;
			}

			return <React.Fragment key={idx}>{pre}{out}{post}</React.Fragment>;
		})}</>);
	}

	const closeResults = () => {
		nextAction(resultMode.success, resultMode.verseRef);
	};

	const textAnswerChanged = ev => {
		closeHint();

		const text = ev.target.value;
		setTextAnswer(text);

		// const res = proctor.answerManager.textAnswerExperiment(verseRef, text);

		const res = proctor.tryAnswer(verseRef, { text, rawTextMode: true });
		
		// console.log("[VerseBox] tryAnswer res=", res);
		
		const { 
			good, 
			incomplete, 
			updatedVerse, 
			updatedAnswerList,
			tokenizedWords
		} = res;

		if(updatedVerse) {
			setVerse(updatedVerse);
		}
		
		if(tokenizedWords) {
			setTokenizedWords(tokenizedWords);
		}

		if(updatedAnswerList) {
			setAnswerList(updatedAnswerList.slice())
		}

		setAnswerIncomplete(!!incomplete);

		if(incomplete) {
			return;
		}

		// Load data here, but show in submitTextAnswer
		setResultMode({
			active:   false,
			verseRef: verseRef,
			success: !!good,
			failure:  !good,
		});

		// console.log("VERSE DONE, stopping testing for now")
	}

	const submitTextAnswer = () => {
		if(answerIncomplete) {
			// console.log("Cannot submit incomplete answer");
			// return false;
			setTextAnswer("");
			return;
		}

		if (onAttemptComplete) {
			onAttemptComplete();
		}

		setOldKeyboardMode(keyboardMode);
		closeKeyboardMode();
	
		// nextAction(good ? true : false, verseRef);
		setResultMode({
			...resultMode,
			active: true,
		});
	}

	// https://stackoverflow.com/questions/10158190/how-to-set-cursor-at-the-end-in-a-textarea-by-not-using-jquery
	const moveCaretToEnd = el => {
		if (typeof el.selectionStart == "number") {
			el.selectionStart = el.selectionEnd = el.value.length;
		} else if (typeof el.createTextRange != "undefined") {
			el.focus();
			var range = el.createTextRange();
			range.collapse(false);
			range.select();
		}
	}

	const inputRef = React.useRef();
	
	React.useEffect(() => {
		if(caretAdjustNeeded && inputRef.current) {
			setCaretAdjustNeeded(false);

			// Delay a little to see if that helps safari
			setTimeout(() => {
				const input = inputRef.current.querySelector('textarea');
				if(input) {
					moveCaretToEnd(input);
				} else {
					console.warn("Could not find input in:", inputRef.current);
				}
			}, 100);
		}
	}, [ caretAdjustNeeded ]);

	//
	//
	// Render below, no more hooks
	//
	//

	if(!verse) {
		return <b></b>;
	}

	if(!originalText) {
		return <b><i>Invalid verseRef</i></b>;
	}

	function RenderAttemptResult({
		title,
		field,
		changeField,
		isPercent = true
	}) {

		const perc = val => !isPercent ? val : Math.round(val * 100),
			percentSign = isPercent ? '%' : '',
			change = field === 'pointsUp' ? 0 : parseFloat(attemptResults.levelChanges[changeField]);
		
		if(!attemptResults || !attemptResults.levelChanges) {
			return <></>;
		}

		return (<div className={clsx(styles.RenderAttemptResult, styles[field])}
			data-up={change > 0 ? 'up' : 'down'}
		>
			<div className={styles.title}>
				{title}
			</div>
			<div className={styles.value}>
				{field === "badgeLevel" ? 
					attemptResults.newBadgeLevel || attemptResults.badgeLevel
				:
					<>{field === 'pointsUp' ? '+' : ''}{perc(attemptResults[field])}{percentSign}</>
				}
			</div>
			<div className={styles.change}
				data-val={change !== 0 ? perc(change) + percentSign : ''}
			>
				{!isNaN(change) && change !== 0 ? <>
					(
						{change > 0 ? '+' : ''}
						{perc(change)}{percentSign}
					)
				</> : <>&nbsp;</>}
			</div>
		</div>)
	}

	function AttemptResults({ results }) {
		const [ anim, setAnim ] = React.useState(false);

		React.useEffect(() => {
			const tid = setTimeout(() => setAnim(true), 100);
			return () => clearTimeout(tid);
		})

		if(!results || !results.levelChanges) {
			return <></>;
		}

		return (<div className={clsx(styles.attemptResults, 
			anim && styles.animResults
		)}>
			<div className={styles.content}>
				{/* <RenderAttemptResult
					title={"Accuracy"}
					field={"accuracy"}
					changeField={"avgAccuracy"}
				/>
				<RenderAttemptResult
					title={"Score"}
					field={"comprehension"}
					changeField={"avgComprehension"}
				/>
				<RenderAttemptResult
					title={"Difficulty"}
					field={"difficulty"}
					changeField={"avgDifficulty"}
				/> */}
				<RenderAttemptResult
					title={"Level"}
					field={"badgeLevel"}
					changeField={"badgeLevel"}
					isPercent={false}
				/>
				<RenderAttemptResult
					title={"Points"}
					field={"pointsUp"}
					isPercent={false}
				/>
			</div>
		</div>);
	}

	return (<>
		<div className={clsx(
			styles.root,
			answerType,
			resultMode.active && styles.resultMode,
			resultMode.active && resultMode.failure && styles.resultBad,
			keyboardMode && styles.keyboardMode,
		)}
			onClick={() => {
				if(resultMode.active && !resultsLoading) {
					closeResults();
				}
			}}
		>
			<div className={clsx(
				styles.hint,
				hintOpen && styles.open,
				hintText && hintText.error && styles.error
			)}
				onClick={closeHint}
			>
				{hintText ? <span>
					{!hintText.error ? 
						<label>Hint:</label>
					: ""}
					{hintText.error || hintText.text}
				</span> : ""}
			</div>
			
			<div
				className={clsx(
					styles.question,
					overflows && styles.overflows,
				)}
				onClick={tryUndo}
				ref={refQuestionBox}
			>
				<div className={clsx(
					styles.result
				)}>
					{resultMode.success ?
						<CheckCircleOutlineIcon/>
					: resultMode.failure ?
						<HighlightOffIcon/>
					: ""}
				</div>

				
				
				<div className={styles.content}>
					<div className={styles.ref}>
						<span className={styles.text}>
							{verseRef.ref}
							<VerseBadgeLevel 
								verse={verseRef}
								className={styles.circles}
							/>
						</span>
					</div>
					{tokenizedWords ? 
						<RenderTokenizedWords tok={tokenizedWords}/> 
						: verse
					}
					{overflows ? <>
						<div className={styles.bottomRef}>
							{verseRef.ref}
						</div>
					</> : ""}
				</div>
			</div>
			<div className={clsx(
				styles.answers
			)}>
				<div className={styles.keyboardBox}>
					{keyboardMode ? <>
						<TextField
							// label={<>Answer</>}
							multiline
							autoFocus
							fullWidth
							rowsMax={4}
							ref={inputRef}
							value={textAnswer}
							onChange={textAnswerChanged}
							className={styles.textAnswer}
							helperText={<>
								Type your answer or use your keyboards's <i>voice-to-text</i> button to <b>speak</b> the verse.
							</>}
							InputLabelProps={{
								shrink: true
							}}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton
										onClick={answerIncomplete ? 
											closeKeyboardMode :
											submitTextAnswer
										}
										className={clsx(!answerIncomplete && styles.highlight)}
									>
										{answerIncomplete ?
											<CloseIcon/>
											:
											<SendIcon/>
										}
									</IconButton>

									<IconButton
										onClick={answerHint}
										disabled={!answerIncomplete}
									>
										<EmojiObjectsIcon/>
									</IconButton>
								</InputAdornment>,
							}}
						/>
					</> :
						<Button
							onClick={openKeyboardMode}
						>
							<KeyboardIcon/>
							<span className={styles.label}>
								Type or speak your answer
							</span>
						</Button>
					}

					{keyboardMode ? ""
						// <IconButton
						// 	onClick={answerHint}
						// 	disabled={!answerIncomplete}
						// >
						// 	<EmojiObjectsIcon/>
						// </IconButton>
					:
						<Button
							onClick={answerHint}
							disabled={!answerIncomplete}
						>
							<EmojiObjectsIcon/>
							<span className={styles.label}>Hint</span>
						</Button>
					}
					
				</div>
				<div className={styles.content}>
					{answerList.map(
						(
							{ text, good, bad, used } = {}, 
							idx
						) => 
						text && <button // yes, using the default button, shockingly enough 
							// Yes, I know idx is bad form but answers could be duplicated
							key={idx}
							data-keynum={idx+1} // for rendering keyboard hint
							className={clsx(
								styles.answerButton,
								good && styles.good,
								bad && styles.bad,
								used && styles.used,
							)}
							onClick={() => used ? tryUndo() : tryAnswer(verseRef, text, idx)}
						>
							{text}
						</button>
					)}
				</div>
			</div>

			{/* attemptResults = {
				accuracy:      0.9,
				comprehension: 0.78,
				difficulty:    0.12,

				badgeLevel:    1,
				newBadgeLeveL: 2,

				levelChanges: {
					badgeLevel:       1,
					avgAccuracy:      0.1,
					avgComprehension: 0.1,
					avgDifficulty:    -0.1,
				}
			}; */}
			<AttemptResults results={attemptResults}/>

			<div className={styles.resultClose}>
				
				<Fab 
					// onClick={closeResults}
					className={clsx(styles.fab, resultsLoading && styles.resultsLoading)}
					variant="extended"
					aria-label="add"
				>
					<PlayIcon/>
					<label>Next</label>
				</Fab>
				
				{/* <Button 
					className={styles.nextButton}
					onClick={() => nextAction(false)}
					color="primary"
					variant="contained"
				>
					Next 
					<KeyboardArrowRight style={{fontSize: '.5rem'}}/>
				</Button> */}
			</div>

			{/* <Button 
				className={styles.nextButton}
				onClick={() => nextAction(false)}
			>
				Skip
			</Button> */}
		</div>
	</>)
}