import React from 'react';

// This component based on:
// - https://codepen.io/Mamboleoo/pen/JjdXPgR
// - https://css-tricks.com/playing-with-particles-using-the-web-animations-api/
export default function Sparkles({
	type = 'shadow',
	children
}) {
	function pop (el) {
		if(!el) {
			return;
		}
		let amount = 30;
		switch (type) {
			case 'shadow':
			case 'line':
			default:
				amount = 60;
				break;
		}
	
		const bbox = el.getBoundingClientRect();
		const x = bbox.left + bbox.width / 2;
		const y = bbox.top + bbox.height / 2;
		for (let i = 0; i < amount; i++) {
			// We call the function createParticle `amount` times
			// We pass the coordinates of the button for x & y values
			createParticle(x, y, type);
		}
	}

	function createParticle (x, y, type) {
		const particle = document.createElement('div');
		document.body.appendChild(particle);

		particle.style.position = 'fixed';
		particle.style.top = 0;
		particle.style.left = 0;
		particle.style.opacity = 0;
		particle.style.pointerEvents = 'none';
		particle.style.backgroundRepeat = 'no-repeat';
		particle.style.backgroundSize = 'contain';
		particle.style.zIndex = 9999;
		
		let width = Math.floor(Math.random() * 30 + 8);
		let height = width;
		let destinationX = (Math.random() - 0.5) * 300;
		let destinationY = (Math.random() - 0.5) * 300;
		let rotation = Math.random() * 520;
		let delay = Math.random() * 200;
		
		switch (type) {
			case 'square':
				// particle.style.background = `hsl(${Math.random() * 90 + 270}, 70%, 60%)`;
				particle.style.background = `rgba(118,76,161,${Math.random()})`;
				particle.style.border = '1px solid white';
				break;
			case 'emoji':
				particle.innerHTML = ['❤','🧡','💛','💚','💙','💜','🤎'][Math.floor(Math.random() * 7)];
				particle.style.fontSize = `${Math.random() * 24 + 10}px`;
				width = height = 'auto';
				break;
			case 'mario':
				particle.style.backgroundImage = 'url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/127738/mario-face.png)';
				break;
		
			case 'line':
				particle.style.background = 'black';
				height = 1;
				rotation += 1000;
				delay = Math.random() * 1000;
				break;
			case 'shadow':
			default:
				const color = `rgba(118,76,161,${Math.random()})`;
				particle.style.boxShadow = `0 0 ${Math.floor(Math.random() * 10 + 10)}px ${color}`;
				particle.style.background = color;
				particle.style.borderRadius = '50%';
				width = height = Math.random() * 5 + 4;
				break;
		}
		
		particle.style.width = `${width}px`;
		particle.style.height = `${height}px`;
		const animation = particle.animate([
			{
				transform: `translate(-50%, -50%) translate(${x}px, ${y}px) rotate(0deg)`,
				opacity: 1
			},
			{
				transform: `translate(-50%, -50%) translate(${x + destinationX}px, ${y + destinationY}px) rotate(${rotation}deg)`,
				opacity: 0
			}
		], {
			duration: Math.random() * 1000 + 3000,
			easing: 'cubic-bezier(0, .9, .57, 1)',
			delay: delay
		});
		animation.onfinish = removeParticle;

		//console.log("Created:", { particle, animation });
	}

	function removeParticle (e) {
		if (e &&
			e.srcElement &&
			e.srcElement.effect &&
			e.srcElement.effect.target) {
			e.srcElement.effect.target.remove();
		}
	}
	
	// Animation doesn't work well in Cordova, so disable for now
	if(window.isPhoneGap) {
		return children;
	}

	return (<span ref={pop}>{children}</span>)
}