import React from 'react';
import styles from './HomePage.module.scss';
export function SimpleBanner({ style }) {
	return (<>
		<div className={styles.banner + " SimpleBanner-root"} style={style}>
			<span className={styles.d}>M</span>
			<span className={styles.ecidr}>y</span>
			<span className={styles.d}>V</span>
			<span className={styles.ecidr}>erses</span>
		</div>
	</>);
}
