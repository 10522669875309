import React from 'react';
import clsx from 'clsx';
import styles from './CircleProgress.module.scss';

// Much of this component originally based on:
// https://codepen.io/JMChristensen/pen/Ablch
export default function CircleProgress({ value: progress = 0, size = 100, className }) {

	// var $circle = $('#svg #bar');
	// size = size / 2;
	const radius = size/2 - 3; // reduce radius to allow space for stroke
	const circumference = Math.PI * (radius*2);

	const barRef = React.useRef();
	React.useEffect(() => {
		let value = parseFloat(progress);
		
		if (isNaN(value)) {
			value = 0; 
			barRef.current.style.strokeDashoffset = 0;
		} else {
			value = Math.max(0, Math.min(100, value));

			const percent = ((100 - value) / 100) * circumference;
			
			barRef.current.style.strokeDashoffset = percent;
			window.bar = barRef.current;
		}
	});

	return (<div 
		className={clsx(styles.CircleProgress, className, 'CircleProgress-root')}
		style={{
			width: `${size}px`,
			height: `${size}px`,
		}}
	>
		<svg 
			width={size}
			height={size}
			viewport={`0 0 ${size} ${size}`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				r={radius}
				cx={size/2}
				cy={size/2}
				fill="transparent"
				// stroke-dasharray="1" 
				//565.48"
				strokeDashoffset="0">
			</circle>
			<circle
				className={clsx(styles.bar, 'CircleProgress-bar')}
				ref={barRef}
				r={radius}
				cx={size/2}
				cy={size/2}
				fill="transparent"
				strokeDasharray={circumference}
				strokeDashoffset="0">
			</circle>
		</svg>
	</div>)
}
