import { shuffle } from './utils'

/**
 * SequenceManager is responsible for the order of verses in a given Test.
 * 
 * It must:
 * 
 * - Determine the number of verses for this test (possibly min? TBD? set?)
 * - Determine the verses in the test (possibly arbitrary?)
 * - Determine the first verse
 * - Determine the next verse (based on outcome of last one? or just next in sequence?)
 * - Determine when the sequence is done (reached end? certain amount right?)
 */

// Why 22? 22 letters in hebrew
export const ARBITRARY_MAX_QUESTIONS = 22;

const TEST_TYPES = ['complete','shuffled','subset','difficulty'];

export default class SequenceManager {
	constructor(proctor) {
		this.proctor = proctor;
	}

	async init() {
		// ...
	}
	
	async startSequence(testType = 'complete') {
		if(!testType) {
			if(process.env.NODE_ENV === 'test') {
				testType = 'complete';
			} else {
				testType = TEST_TYPES[Math.floor(Math.random() * TEST_TYPES.length)];
			}
		}

		this._importStateFromCursor();
		
		this._applyTestType(testType);
		// this.verseList = this.verseList.slice(0, ARBITRARY_MAX_QUESTIONS);

		return testType;
	}

	_importStateFromCursor() {
		const packCursor = this.proctor.packCursor || {},
			cursorState = packCursor.state || {},
			verseRefAttempts = cursorState.verseRefAttempts || {};

		this.verseList = this.proctor.packVerses;
		this.currentVerseIdx = 0;
		this.startingIndex = 0;

		if(Object.keys(verseRefAttempts).length > 0) {
			// Put in order so all attempted verses are at the start
			// this.verseList = this.verseList.sort((a, b) => 
			// 	(verseRefAttempts[b] ? 1:0) - (verseRefAttempts[a] ? 1:0)
			// );

			const seen = this.verseList.filter(v => verseRefAttempts[v.cachedRef]),
				notSeen = this.verseList.filter(v => !verseRefAttempts[v.cachedRef]);
			
			

			// // Find first non-attempted verse
			// this.currentVerseIdx = this.verseList.findIndex(
			// 	verse => {
			// 		const res = !verseRefAttempts[verse.cachedRef]
			// 		console.log(` * findIndex: ${verse.cachedRef} res: ${res}`);
			// 		return res;
			// 	}
			// );

			this.verseList = seen.concat(notSeen);
			this.currentVerseIdx = seen.length;

			// For getFirstVerse
			this.startingIndex = this.currentVerseIdx;

			// console.log("_importStateFromCursor: found index:", this.currentVerseIdx, ", sorted verse list:", this.verseList, verseRefAttempts, packCursor);

			// console.log("_importStateFromCursor: found index:", this.currentVerseIdx, {seen, notSeen, verseRefAttempts}, packCursor);
		} else {
			// console.log("_importStateFromCursor: no verseRefAttempts in cursor:", packCursor);
		}
	}

	changeSequenceMode(testType) {
		this._applyTestType(testType);
	}

	_applyTestType(testType) {
		if(!TEST_TYPES.includes(testType)) {
			throw new Error("Invalid test type: " + testType + ", must be one of: " + TEST_TYPES.join(' | '));
		}

		const currentIdx = this.currentVerseIdx || 0,
			completedVerses = currentIdx > 0 ? 
				(this.verseList || []).slice(0, currentIdx)
				: [],
			completedMap = {};

		completedVerses.forEach(v => completedMap[v.id] = v);

		const proctorList = this.verseList
			.slice()
			.filter(v => !completedMap[v.id]);

		// console.log("_applyTestType: ", { proctorList, completedMap, completedVerses, currentIdx })

		// const testType = 'complete';
		if(testType === 'complete') {
			this.verseList = proctorList;
		} else
		if(testType === 'shuffled') {
			this.verseList = shuffle(proctorList);
		}
		else
		if(testType === 'subset') {
			const pop = proctorList;
			const startIdx = Math.floor(Math.random() * pop.length * .5);
			const endIdx   = Math.ceil(Math.random() * pop.length * .5) + startIdx + 2;
			this.verseList = shuffle(pop.slice(startIdx, endIdx));
		} else
		if(testType === 'difficulty') {
			// Smallest avgComprehension first
			this.verseList = proctorList.sort(
				(a, b) => (a.stats || {}).avgComprehension - (b.stats || {}).avgComprehension
			);
		}
		
		// Put completed verses at the start so currentIdx gets the start of the new type
		this.verseList = completedVerses.concat(this.verseList);
	}

	getVerseList() {
		return this.verseList;
	}

	getFirstVerse() {
		const firstIdx = this.startingIndex || 0;
		const nextVerse = this.verseList[firstIdx];
		this.currentVerseIdx = firstIdx;

		return { 
			firstVerse: nextVerse, 
			verseNum: firstIdx,
			totalVerses: this.verseList.length,
			progressPercent: Math.round(firstIdx / this.verseList.length * 100),
		};
	}

	numVerses() {
		if(!this.verseList) {
			return 0;
		}

		return this.verseList.length;
	}

	getNextVerse(loop=false) {
		let nextIdx = this.currentVerseIdx + 1;
		if(nextIdx >= this.numVerses()) {
			if(!loop) {
				return { end: true };
			}

			nextIdx = 0;
		}

		const nextVerse = this.verseList[nextIdx];
		this.currentVerseIdx = nextIdx;

		return { 
			nextVerse, 
			verseNum: nextIdx,
			totalVerses: this.verseList.length,
			progressPercent: Math.round(nextIdx / this.verseList.length * 100),
		};
	}

	gotoVerseIndex(nextIdx) {
		if(nextIdx < 0 || nextIdx >= this.numVerses()) {
			return { end: true };
		}

		const nextVerse = this.verseList[nextIdx];
		this.currentVerseIdx = nextIdx;

		return { 
			nextVerse, 
			verseNum: nextIdx,
			totalVerses: this.verseList.length,
			progressPercent: Math.round(nextIdx / this.verseList.length * 100),
		};
	}

	hasPrevVerse() {
		let prevIdx = this.currentVerseIdx - 1;
		if(prevIdx < 0) {
			return false;
		}

		return true;
	}

	hasNextVerse() {
		let nextIdx = this.currentVerseIdx + 1;
		if(nextIdx >= this.numVerses()) {
			return false;
		}

		return true;
	}

	getPrevVerse(loop=false) {
		let prevIdx = this.currentVerseIdx - 1;
		if(prevIdx < 0) {
			if(!loop) {
				return { end: true };
			}

			prevIdx = this.numVerses() - 1;
		}

		const prevVerse = this.verseList[prevIdx];
		this.currentVerseIdx = prevIdx;

		return { 
			prevVerse, 
			verseNum: prevIdx,
			totalVerses: this.verseList.length,
			progressPercent: Math.round(prevIdx / this.verseList.length * 100),
		};
	}

	async destroy() {
		// ...
	}
}