import firebase from 'firebase/app';
import 'firebase/messaging';
import { ServerStore } from './ServerStore';

const firebaseConfig = {
    apiKey: "AIzaSyAr0uDy70LLfpAxagbb8yF2-77Cd83U0FE",
    authDomain: "myverses-f5973.firebaseapp.com",
    databaseURL: "https://myverses-f5973.firebaseio.com",
    projectId: "myverses-f5973",
    storageBucket: "myverses-f5973.appspot.com",
    messagingSenderId: "293551070525",
    appId: "1:293551070525:web:57a77fcefaef97ae147954",
    measurementId: "G-GGKQYGJ3V3"
};

function initFirebase() {
	// Initialize Firebase
	if(!firebase.apps.length) {
		firebase.initializeApp(firebaseConfig);
	}

	return firebase;
}

class FirebaseManager {
	static handle = null;

	static async plugin(opts) {
		this.handle = new FirebaseManager(opts);
		this.handle.init();

		return this.handle;
	}

	constructor({ onMessage, onMessageClick, onPermissionNeeded }) {
		this.onMessage          = onMessage;
		this.onMessageClick     = onMessageClick;
		this.onPermissionNeeded = onPermissionNeeded;

		console.log("Starting FirebasePushPlugin")

		// Check on boot to see if the service worker left a present for us in the URL...
		if(window.location.hash.match(/^#\/?alert:/)) {
			// Our service worker prefixes the data.click_action from alerts received 
			// while our page is ***CLOSED***.
			// This means that the page was NOT active, NOT OPEN.
			// If our page WAS OPEN, we would have receive a message post (lower in this file) with a type == 'notificationclick'.
			// The ONLY time we will (should...) get here is if our service worker couldn't find an open page
			// so here we must extract the click action from the URL and then fire onMessageClick, indicating a background click.

			const click_action = window.location.hash.replace(/^#\/?alert:/, '');

			onMessageClick && onMessageClick({
				// Note: this is the ONLY property of the alert we emulate here.
				// If we find we need more data from the alert (like the ID, etc)
				// we must modify our service worker to encode it in the URL accordingly and then we 
				// must update this code above to extract it and put it here in this hash.
				click_action, 

				// Used by PushNotifyService to handle properly
				clickedInBackground: true,

				// Add flag so our logging metrics know where this came from
				fromFirebaseServiceWorkerBackground: true,
			});
		} else {

		}
	}

	async init() {
		this.firebase = initFirebase();

		// if(this.onPermissionNeeded)
		// 	await this.onPermissionNeeded();

		if (!firebase.messaging.isSupported()) {
			console.warn("[FirebaseManager] Firebase push messaging not supported on this browser");
			return;
		}

		this.messaging = this.firebase.messaging();

		const requestPermission = async () => {

			let error;
			await this.messaging.requestPermission().catch(e => error = e);
			if(error) {
				console.error("[FirebasePushPlugin.requestPermission] error requesting permission:", error);
				return false;
			}

			return true;
		}

		const getToken = async () => {
			// Get Instance ID token. Initially this makes a network call, once retrieved
			// subsequent calls to getToken will return from cache.
			let error;
			const token = await this.messaging.getToken().catch(e => error = e);
			if(error) {
				console.error("[FirebasePushPlugin.getToken] error getting token:", error);
				return false;
			} 

			if(!token) {
				console.warn("[FirebasePushPlugin.getToken] no token available, requesting permission");

				ServerStore.metric("app.firebase_push_plugin.need_token.defer");
				
				// Allow our PushNotifyService to block permission request until it has informed the user
				if(this.onPermissionNeeded) {
					// console.warn("[FirebasePushPlugin.getToken] Waiting onPermissionNeeded hook...");
					await this.onPermissionNeeded();
				}

				ServerStore.metric("app.firebase_push_plugin.need_token.unlock");

				// console.warn("[FirebasePushPlugin.getToken] hooks cleared, prompting ....");

				if(await requestPermission()) {
					ServerStore.metric("app.firebase_push_plugin.need_token.accept");
					return getToken();
				}

				ServerStore.metric("app.firebase_push_plugin.need_token.reject");

				return false;
			} else {

				ServerStore.metric("app.firebase_push_plugin.has_token");

				// Now has to be done AFTER getting token
				// See instructions at https://firebase.google.com/docs/cloud-messaging/js/client#configure_web_credentials_with_fcm
				// Yes, I hardcoded the projectId here as a dummy-check for when I copy this code to another project
				if(firebaseConfig.projectId === "myverses-f5973") {
					this.messaging.usePublicVapidKey(
						'BBxAAh7gSGEyA_80xmjm8eCEyFTkOY-aQyfuNKVP2JWWvp62gRG9i_46p2QKLR6g2I7TtWOTHrCe12Yjh2XRJZw'
					);
				} else {
					console.warn("Your project config is out of date, update the VAPID key in FirebasePushPlugin around line 125, look for 'usePublicVapidKey'. The firebase config this project was built with looks to be for a different project than expected.")
				}
			}

			// console.warn("[FirebasePushPlugin.getToken] got token:", token);

			return token;
		}
		
		// Callback fired if Instance ID token is updated.
		this.messaging.onTokenRefresh(async () => {
			let error;
			const token = this.messaging.getToken().catch(e => error = e);
			if(error) {
				console.warn('[FirebasePushPlugin.onTokenRefresh] Unable to retrieve refreshed token ', error);

				ServerStore.metric("app.firebase_push_plugin.token_refresh.failure", null, { error });
				return;
			}

			ServerStore.metric("app.firebase_push_plugin.token_refresh.success");

			// console.log('[FirebasePushPlugin.onTokenRefresh] Token refreshed.');

			// Send Instance ID token to app server.
			ServerStore.storePushToken(token);
		});
		
		// Handle incoming messages. Called when:
		// - a message is received while the app has focus
		// - the user clicks on an app notification created by a service worker
		//   `messaging.setBackgroundMessageHandler` handler.
		this.messaging.onMessage(payload => {
			// console.log('[FirebasePushPlugin.onMessage] **TODO** Handle message received: ', payload);

			this.onMessage && this.onMessage(payload.data);
		});

		// Handle messages clicked IN BACKGROUND - display of background messages is handled in public/firebase-messaging-sw.js
		if('serviceWorker' in navigator){
			// Handler for messages coming from the service worker
			navigator.serviceWorker.addEventListener('message', event => {
				// console.log("[FirebasePushPlugin] ServiceWorker Client Received Message: ", event.data);
				const msg = event.data;

				if(msg.type === "notificationclick") {
					this.onMessageClick && this.onMessageClick(msg.raw);
				}
			});
		}

		const token = await getToken();
		if(token) {
			ServerStore.metric("app.firebase_push_plugin.need_token.success");
			ServerStore.storePushToken(token);
			return true;
		} else {
			console.warn("[FirebasePushPlugin.initMessaging] no token received, probably won't get push messages")
			ServerStore.metric("app.firebase_push_plugin.null_token");
			return false;
		}
	}
}


export function initFirebasePushPlugin(opts) {
	if (process.env.NODE_ENV === 'production' ||
		process.env.REACT_APP_STAGING === 'true') {
		FirebaseManager.plugin(opts);
	}
}