import { percentHelper, avgHelper, round2digits } from './utils';
import { ServerStore } from './Proctor';

/**
 * TestManager is responsible for data management around starting/ending
 * tests, including on-client summary computation, etc
 * 
 * It does not care about the exact verses, sequence, or anything.
 * It just needs the list of attempts from the AttemptManager for this test.
 * 
 */

export default class TestManager {
	constructor(proctor) {
		this.proctor = proctor;
	}

	async init() {
		// ...
	}

	async startTest(testType) {
		this.attempts = [];
		this.startTime = new Date();
		this.finished = false;
		// Not used right now ...
		this.testType = testType;

		if(process.env.NODE_ENV !== 'test') {
			this.testRef = await ServerStore.StartTest({
				packId: this.proctor.packId,
				testType,
				startTime: this.startTime,
				numCompleted: 0,
				totalVerses: this.proctor.sequenceManager.numVerses(), 
			});

			// console.log("[startTest]", testType, this.verseList)
			// console.log("Started test on server, ref:", this.testRef);
		}

		return testType;
	}

	addAttempt(attempt) {
		if(!attempt) {
			console.warn("No attempt provided, not doing anything");
			return;
		}
		
		this.attempts.push(attempt);

		if(process.env.NODE_ENV !== 'test') {
			if(!this.attemptPromises) {
				this.attemptPromises = [];
			}

			const badgeLevel = attempt.verse &&
				attempt.verse.stats &&
				attempt.verse.stats.badgeLevel;
			
			// Note: we are intentionally NOT awaiting LogAttempt so 
			// we don't hold up the flow of the quiz but we WILL
			// await it in finishTest() to make sure we don't finish the
			// test before all attempts are logged
			const promise = ServerStore.LogAttempt({
				...attempt,
				// Server will set datetime for UTC
				testId: this.testRef.id,
				linkId: attempt.verse.link.id,
				...(attempt.verse.isRange ? {
					verseId:    attempt.verse.verse.startId,
					endVerseId: attempt.verse.verse.endId,
				} : {
					verseId: attempt.verse.id,
				}),
				badgeLevel // in case the user made it easier...
			});

			// Store the promise so we can make sure all attempts
			// are stored at end of test
			this.attemptPromises.push(promise);

			return promise;
		}
	}

	getAttempts() {
		return this.attempts;
	}

	async finishTest(summary) {
		if(this.finished) {
			return;
		}

		this.finished = true;
		
		// Note: The "Finished" flag is NOT passed here to computeSummary
		// because the only way we "know" it was finished is if
		// the "PlayPackPage" completes all verses and then
		// does a computeSummary(true). So, to get the test as truely
		// "finished", we must complete it thru PlayPackPage.
		// Otherwise, calling finishTest() (no args),
		// will still finish the test, but not set the "finished" flag
		if(!summary)
			summary = this.computeSummary();

		const test = {
			pack: this.proctor.packId,
			...summary,
			isFinished: true
		}

		if(process.env.NODE_ENV !== 'test') {
			if(this.attemptPromises && this.attemptPromises.length) {
				await Promise.all(this.attemptPromises);
			}

			const { pointsUp } = await ServerStore.EndTest({
				testId: this.testRef.id,
				...summary
			});

			test.pointsUp = pointsUp;

			// console.log("Stored final test on server as:", stored);
		} else {
			test.pointsUp = 10; // just for testing TBD
		}

		// Dirty the cache
		this.proctor.overallSummaryCache = null;

		// console.log("[finishTest] stored test result:", test);

		return test;
	}

	computeSummary(finished=false) {
		if(!this.attempts.length) {
			return {}
		}

		// console.warn("TestManager.computeSummary: finished=", finished);

		const list = this.attempts || [],
			// Completed vs Total Verses
			completed = list.length,
			total     = this.proctor.sequenceManager.numVerses(),
			
			// Averge out a few fields
			avgDifficulty    = percentHelper(avgHelper(list, 'difficulty')),
			avgAccuracy      = percentHelper(avgHelper(list, 'accuracy')),
			avgComprehension = percentHelper(avgHelper(list, 'comprehension')),

			// Same thing we calculate server side via SQL,
			// but just do it for the attempts here on the client for
			// quick display
			pointsUp = list.reduce((sum, val) => {
				let badgeLevel = parseFloat((((val || {}).verse || {}).stats || {}).badgeLevel || 0);
				if(isNaN(badgeLevel) || !badgeLevel) {
					badgeLevel = 1;
				}

				let avgComprehension = parseFloat(val.comprehension);
				if(isNaN(avgComprehension)) {
					avgComprehension = 0;
				}

				return sum += Math.round(
					badgeLevel * avgComprehension * 10
				)
			}, 0),

			// Data from last attempt
			lastAttempt = list[list.length-1],
			{ 
				difficulty: lastDifficulty,
				goodCount:  lastSuccess
			} = lastAttempt,

			// Overall test length and avg time per verse completed
			{ startTime } = this,
			endTime       = new Date(),
			timeLength    = endTime - startTime,
			avgTime       = round2digits(timeLength / completed / 1000),

			// Build summary object
			summary = {
				numCompleted: completed,
				totalVerses: total,
				startTime,
				endTime,
				timeLength,
				avgTime,
				avgAccuracy,
				avgDifficulty,
				avgComprehension,
				pointsUp,
				lastDifficulty: Math.round(lastDifficulty * 100),
				lastSuccess: !!lastSuccess,
				isFinished: finished
			};

		// Debug ...
		console.log(`[computeSummary] `, summary, { list, avgComprehension });

		return summary;
	}

	async destroy() {
		// ...
	}
}