import React from 'react';

import styles from './PackPage.module.scss';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ShareDialog({
	shareUrl,
	onCancel,
	onSave,
	pack
}) {
	const onClose = () => onCancel && onCancel();
	return (<Dialog 
		className={styles.Sharedialog}
		open={!!shareUrl} 
		onClose={onClose}
	>
		<DialogTitle>
			Share {pack.name}
		</DialogTitle>
		<DialogContent className={styles.fields}>
			<TextField
				type="text"
				label="Link"
				defaultValue={shareUrl}
				fullWidth
				// disabled
				ref={ref => ref && ref.querySelector('input').select()}
			/>
			<FormHelperText>
				This URL has already been <b>copied to your clipboard</b> so you can easily share it in other apps
			</FormHelperText>
		</DialogContent>
		<DialogActions className={styles.actions}>
			<Button 
				onClick={onClose}
				color="default"
				data-testtarget='cancel'
			>
				Cancel
			</Button>
			<Button 
				onClick={onSave}
				color="primary"
				data-testtarget='save'
			>
				Thanks
			</Button>
		</DialogActions>
	</Dialog>);
}