
// UA matching
const ua = {
	Android: /Android/ig.test(navigator.userAgent),
	iOS:     /AppleWebKit/.test(navigator.userAgent) && /Mobile\/\w+/.test(navigator.userAgent)
};

export const setStatusBarColor = color => {
	if(typeof(color) === 'number') {
		color = '#' + color.toString(16);
	}

	if(window.StatusBar) {
		const sb = window.StatusBar;
		// PG has problems in iOS ....wierd...
		// if(ua.iOS && !window.isPhoneGap()) {
		// 	sb.overlaysWebView(true);
		// 	document.body.paddingTop = '20px';
		// } else {
			try {
				sb.styleLightContent(); // TBD if this works
			} catch(ex) {
				console.warn("Exception trying to set light content flag:", ex);
			}
			
			if(ua.iOS) {
				// sb.overlaysWebView(false);
				// document.body.paddingTop = '0px';
				sb.styleDefault(); // TBD if this works 
			} else {
				sb.backgroundColorByHexString(color);
			}
		// }
	} else
	// Set HTML5 mobile theme color
	if(color) {
		try {
			const headerThemeElm = document.head && document.head.querySelector("[name=theme-color]");
			if (headerThemeElm) {
				headerThemeElm.content = color;
				// console.log("[setStatusBarColor] new color:", color,", elm:", headerThemeElm);
			}
		} catch(e) {
			// eval('console').warn("Error setting theme-color ", color, ": ", e, " (probably Safari just hates us)");
		}
	}

}