import { server } from './ServerUtil';
import gtag from './GoogleAnalytics';
// import { Cat, Level } from './models';
import { EventEmitter } from 'events';
import * as Sentry from '@sentry/browser';
import { DeviceInfo } from './DeviceInfo';
// import { avg } from './geom';
import { isPhoneGap } from './isPhoneGap';
// import moment from 'moment';
import { writeUpgradeCache } from './cordova-fs-helpers';

import io from 'socket.io-client';

// Load for ad conversion tracking
import './trackingPixels';

// NOTE: We include buildTime from a .json file instead of a simple .txt file because
// webpack will embed the JSON data at build time and so the bundle will end up with
// "buildTime={...}". If we had used a ".txt" file - while they are easier to generate
// in package.json, instead webpack would have given us a static assset URL, e.g.
// "buildTime='./static/assets/build-time.txt'" or something like that. We could fetch that,
// yes, but the purpose of the buildTime var is to indicate when the RUNNING SOFTWARE
// was built, NOT whatever is on the server. So, by embedding JSON in the bundle,
// we will "freeze" the time the bundle was built in stone inside the bundle itself,
// as buildTime.date (see package.json for how this file is generated)
import buildTime from './build-time.json';
// import { defer } from './defer';

// import { DEMO_LOG, DEMO_ACTIVITIES } from './mockData';

// Disable for now until we set this up for this project
// const Sentry = null;

// Disable until setup
const mixpanel = null;

// Only set to true for development
const SERVERLESS_TESTING = false; //(process.env.NODE_ENV === 'production') ? false : true;
// Keys to use for local storage to simulate persistance in SERVERLESS_TESTING

const DISCLAIMER_KEY = '@myverses/justUpdated';

// Alert test url:
// https://localhost:3000/#/alert:#myverses:eyJldmVudCI6ImFsZXJ0LnJlbWluZGVyRXZlbnQifQ%3D%3D

// Must match what's used on the server - this is for MyVerses on FB
export const FB_APP_ID = "2715959175301680";

// for reuse elsewhere, such as LoginScene
export { gtag };

// For local storage of auth token
const TOKEN_KEY     = '@myverses/token',
	storeToken = token => {
		// console.warn(" *** SET TOKEN: ", token);
		if(token !== null && token !== undefined && (token+"").trim()) {
			window.localStorage.setItem(TOKEN_KEY, token);
		} else {
			window.localStorage.removeItem(TOKEN_KEY, token);
		}
	},
	getToken = () => {
		const token = window.localStorage.getItem(TOKEN_KEY);

		// console.warn(" *** GET TOKEN: ", token);
		return token;
	};

// For common definition
export const LOGIN_EVENT = "login-event";

// For common definition
export const APP_PAUSED_EVENT  = "app-paused-event";
export const APP_RESUMED_EVENT = "app-resumed-event";

// Hide Josiah from MixPanel (still log metrics to server though)
const HIDE_USER_ID_FROM_MIXPANEL = 3;


export class ServerStore {
	// Random delay used to simulate network congestion / slow server response times
	static async randomTestingDelay() {
		// Never leave this on in production again...
		if (process.env.NODE_ENV === 'production' ||
			process.env.REACT_APP_STAGING === 'true') {
			return;
		}

		// Only delay in testing
		await new Promise(resolve => setTimeout(() => resolve(), 1500 * Math.random() + 650));
	}


	// Patch with info incase we boot before the script in index.html boots
	static isPhoneGap = isPhoneGap;

	// static currentAccount = null;
	static currentUser    = null;

	static _events = new EventEmitter();
	static on(event, callback) {
		this._events.on(event, callback);
	}

	static off(event, callback) {
		this._events.off(event, callback);
	}

	static emit(event, data) {
		this._events.emit(event, data);
	}

	// static models() {
	// 	return { Cat, Level };
	// }

	static server() { return server };

	static getUtcOffset() {
		// getTimezoneOffset returns a negative number when there is a POSITIVE
		// UTC offset. WHY???
		return -1 * (new Date()).getTimezoneOffset();
		// return -300;
	}

	static async WelcomeDone(packet) {
		await this.autoLogin();
		const updatedProfile = await this.server().post('/api/WelcomeDone', packet);
		this.currentUser = updatedProfile;
		return updatedProfile;
	}


	static async GetSettings() {
		const result = await  this.autoLogin({ disableAutoSignup: true });
		// console.log("[GetSettings] autoLogin result:", result);
		if(!result) {
			return {}
		}
		// await randomTestingDelay();
		return this.server().get('/api/GetSettings');
		// await new Promise(resolve => setTimeout(() => resolve(), 2000));
		// return this._fakeSettings || (this._fakeSettings = {
		// 	name: 'Foobar',
		// 	cellPhone: '7652150511', // for re-login
		// 	sosPasscode: '', // stored hashed on server
		// });
	}

	static async UpdateSettings(settings={
		name: "",
		cellPhone: "",
	}) {
		// this.emit('settingsUpdated');

		console.log("[UpdateSettings] posting:", settings);
		await  this.autoLogin({ anonymousName: settings.name });
		// await randomTestingDelay();
		const profile = await this.server().post('/api/UpdateSettings', settings);
		this.currentUser = profile;
		return profile;
		// Object.assign(this._fakeSettings, settings);

		// return { set: true };
	}

	
	static async _wrap(method, endpoint, ...args) {
		if(!await this.autoLogin()) {
			return null;
		}

		return this.server()[method]('/api/' + endpoint, ...args);
	}

	static _get    = (endpoint, data={}, opts={ autoRetry: true }) => 
		this._wrap('get',
			endpoint,
			data || {},
			// Enable autoRetry by default on all GET requests
			{ autoRetry: true, ...(opts|| {}) }
		);
	static _post   = (endpoint, ...args) => this._wrap('post',   endpoint, ...args);
	static _delete = (endpoint, ...args) => this._wrap('delete', endpoint, ...args);

	static async ProcessUserSharedText(text) {
		return this._post('ProcessUserSharedText', { text })
	}

	static async GetUserPoints() {
		return this._get('GetUserPoints');
	}

	static async GetUserPacks() {
		return this._get('GetUserPacks')
	}

	static async GetPublicPacks() {
		return this._get('GetPublicPacks')
	}

	static async SearchPacks({ 
		search,
		$limit,
		$skip,
		onlyUserEditablePacks
	}={}) {
		return await this._get('SearchPacks', {
			search,
			$limit: $limit || 100,
			$skip,
			onlyUserEditablePacks
		});
	}

	static async GeneralSearchQuery({
		search,
		$limit,
		$skip,
		version,
		oldVersion
	}) {
		return await this._get('GeneralSearchQuery', {
			search,
			$limit: $limit || 100,
			$skip,
		});
	}

	static async GetTrainingPack() {
		// await randomTestingDelay();
		return this._get('GetTrainingPack');
	}

	static async FollowPack(pack) {
		return this._post('FollowPack', pack.id ? pack : { id: pack });
	}

	static async CreatePack(args) {
		return this._post('CreatePack', args);
	}

	static async UpdatePack(args) {
		return this._post('UpdatePack', args);
	}

	static async DeletePack(pack) {
		return this._post('DeletePack', pack.id ? pack : { id: pack })
	}

	static async SearchUnsplash(terms, options = {}) {
		return this._get('SearchUnsplash', { terms, options })
	}

	static async RandomUnsplash(terms, options = {}) {
		return this._get('RandomUnsplash', { terms, options });
	}

	static async SendUnsplashMetrics(meta) {
		return this._post('SendUnsplashMetrics', meta);
	}

	static async GetPack(pack, version) {
		const args = pack.id ? pack : { id: pack };
		if(version) {
			args.versionId = version.id ? version.id : version;
		}
		return this._get('GetPack', args);
	}

	static async GetSinglePracticePack({ ref, version=null, idOnly=false }) {
		return this._get('GetSinglePracticePack', { ref, version, idOnly });
	}

	static async GetVerse({ ref, version=null }) {
		return this._get('GetVerse', { ref, version });
	}

	static async GetVerses({ 
		search,
		$limit,
		$skip,
		book,
		chapter,
		verse,
		language,
		version,
		oldVersion
	}={}) {
		const { data, parsedRef } = await  this._get('GetVerses', {
			search: search || '',
			$limit: $limit || 10,
			$skip: $skip || 0,
			book,
			chapter,
			verse,
			language,
			version: version || '',
			oldVersion: oldVersion || ''
		});
		if (data) {
			data.parsedRef = parsedRef || null;
		}
		return data;
	}

	static async DownloadVerse({
		version,
		book,
		chapter,
		verse
	}) {
		return await this._post('DownloadVerse', {
			versionId: version.id ? version.id : version,
			bookId: book.id ? book.id : book,
			chapter,
			verse
		});
	}

	static async GetVersions({ 
		search,
		$limit,
		$skip,
		language,
		code
	}={}) {
		const { data } = await this._get('GetVersions', {
			search,
			$limit: $limit || 100,
			$skip,
			language,
			code
		});
		return data;
	}

	static async GetBooks({ 
		search,
		$limit,
		$skip
	}={}) {
		const { data } = await this._get('GetBooks', {
			search: search || '',
			$limit: $limit || 66,
			$skip: $skip || 0
		});
		return data;
	}

	static async AddPackVerse({ packId, verseId, endVerseId, notes, splitRange }) {
		await this.randomTestingDelay();
		return this._post('AddPackVerse', { packId, verseId, endVerseId, notes, splitRange })
	}

	static async UpdatePackVerseNotes({ linkId, notes }) {
		await this.randomTestingDelay();
		return this._post('UpdatePackVerseNotes', { linkId, notes });
	}

	static async RemovePackVerse({ linkId }) {
		await this.randomTestingDelay();
		return this._post('RemovePackVerse', { linkId });
	}

	static async CreateVerse({ packId=null, ...args }) { 
		await this.randomTestingDelay();
		return this.CreateVerse({ packId, ...args });
	}

	static async UpdateVerse({ id, ...args }) {
		await this.randomTestingDelay();
		return this._post('UdpateVerse', { id, ...args });
	}

	static async DeleteVerse(verse) {
		await this.randomTestingDelay();
		return this._post('DeleteVerse', verse.id ? verse : { id: verse });
	}

	static async StartTest({ packId, ...data }) {
		await this.randomTestingDelay();
		return this._post('StartTest', { packId, ...data });
	}

	static async LogAttempt({ testId, verseId, ...data }) {
		await this.randomTestingDelay();
		return this._post('LogAttempt', { testId, verseId, ...data });
	}

	static async EndTest({ testId, ...data }) {
		await this.randomTestingDelay();
		return this._post('EndTest', { testId, ...data });
	}

	static async RestartCursor({ packId }) {
		await this.randomTestingDelay();
		return this._post('RestartCursor', { packId });
	}

	static async GetPackSummary(pack) {
		await this.randomTestingDelay();
		return this._get('GetPackSummary', { packId: pack.id || pack });
	}

	static async GetPackVerseStats({ packId, sortDirection, numResults = 3 }) {
		await this.randomTestingDelay();
		return this._get('GetPackVerseStats', { packId, sortDirection, numResults });
	}

	static async GetSummary(date = new Date(), opts = { 
		// see server for all opts
	}) {
		await this.randomTestingDelay();

		// Tell the server where we're calling from
		const offset = { utcOffset: this.getUtcOffset() };

		// console.log("[GetSummary] opts:", opts);
		
		return this._get('GetSummary', { date, ...offset, ...opts });
	}

	static async GetLastTest({ contextualized = false }) {
		await this.randomTestingDelay();

		const offset = { utcOffset: this.getUtcOffset() };
		return this._get('GetLastTest', { contextualized, ...offset });
	}

	static async countMetric(metric, value=1) {
		
		this.metric(metric + '.count', value, {}, true/*dontSendToMixpanel*/);

		// For now, just dumps to mixpanel and fakes it (must sum() serverside later) in local metric
		if (mixpanel) {
			if(this.currentUser && this.currentUser.id === HIDE_USER_ID_FROM_MIXPANEL)
				return;
			
			mixpanel.people.increment(metric, value);

			// // special-case spending count for
			// // logging as shown in https://developer.mixpanel.com/docs/javascript#section-tracking-revenue
			// // This metric is currently logged in MarketUtils in BuyItemButton.processPurchaseToken
			// if (metric === 'game.count.dollars_spent') {
			// 	mixpanel.people.track_charge(value);
			// }
		}
	}

	static metric(metric, value, data={}, dontSendToMixpanel=false) {
		(this.metrics || (this.metrics = [])).push({
			// NB user, cat, and level all applied server-side to this item
			// based on the auth token and cat state in the db
			datetime: new Date(),
			epoch:    Date.now(),
			
			metric,
			value,
			data,
		});
		this._touchMetricInterval();

		// Upload to mixpanel as well
		if (mixpanel && !dontSendToMixpanel) {
			if(!this.currentUser || this.currentUser.id !== HIDE_USER_ID_FROM_MIXPANEL) {
				let props;
				if((value !== undefined && value !== null) || Object.keys(data || {}).length > 0) {
					props = { value, ...(data || {})};
				}

				mixpanel.track(metric, props);
			}
		}

		gtag('event', metric);

		return {
			flush: this._flushMetrics || (this._flushMetrics = this.postMetrics.bind(this)),
		};
	}

	static _touchMetricInterval() {
		if(this._metricInterval)
			return;

		this._metricInterval = setInterval(() => {
			this.postMetrics();
		}, 1000);
	}

	
	static async postMetrics(keepalive=false) {

		if(SERVERLESS_TESTING) {
			return;
		}

		const metrics = (this.metrics || []);
		if(metrics.length > 0) {
			const deviceInfo = await this.deviceInfo();

			// Make a copy and then reset .metrics instead of resetting after tx
			// because the tx is async and doesn't block the rest of the program,
			// so metrics could be added (and then lost) during the tx if we waited
			// to reset after the post finished.
			const batch = metrics.slice();
			this.metrics = [];
			// If not logged in yet, post to an unauth'd route
			const preAuth = ServerStore.currentUser ? '' : '/pre';
			// NB: Not using { autoRetry: true } arg on server.post
			// because we just catch errors and re-buffer the metrics for later posting
			// at the next call of the _metricInterval interval timer
			await server.post('/metrics' + preAuth, { 
				deviceId: deviceInfo.deviceId, 
				batch
			}, { 
				// Options in this hash passed directly to fetch()
				// Per https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch:
				// 		The keepalive option can be used to allow the request to outlive the page. 
				// 		Fetch with the keepalive flag is a replacement for the Navigator.sendBeacon() API. 
				// Since we could be calling postMetrics() in onbeforeonload (or other page-ending circumstances),
				// this ensures that the metrics hit the server.
				// We have to use fetch() instead of sendBeacon() because we need headers
				// to contain our auth data so the correct user is tracked with the metrics as well (if logged in)
				keepalive
			}).catch(error => {
				// Put metrics back on the stack if an error occurred
				this.metrics.unshift(...batch);
				console.warn("Error posting metrics to server:", error);
			});
		}
	}
	
	static async authenticated(authData) {
		// console.log("[authenticated] authData=", authData, typeof(authData), authData.length )
		
		this.authData = authData;
		server.setToken(authData.token);

		// Only init if changed
		if(!this.currentUser || this.currentUser.id !== authData.user.id) {

			// this.currentAccount = authData.account;
			this.currentUser = authData.user;

			// Update GoogleAnalytics with userId
			gtag('set', { 'user_id': this.currentUser.id }); // Set the user ID using signed-in user_id.

			// Update Sentry with user data
			this._setupSentry(authData.user);

			// Update MixPanel with user data
			this._setupMixpanel(authData.user);
			
			// Count this metric
			this.countMetric('app.user.login');

			// Send user's timezone to the server
			const utcOffset = this.getUtcOffset();
			if(this.currentUser.utcOffset !== utcOffset) {
				// Server needs this for offline data reminders
				await ServerStore.UpdateSettings({ utcOffset });
			}

			// Connect the socket.io instance
			// TODO reenable when we actually NEED the socket, right now it's not used
			// this._connectSocket();

			// Notify anyone listening
			this.emit(LOGIN_EVENT, this.currentUser);
		}

		return this;
	}

	static _setupSentry(user) {
		if(!Sentry)
			return;

		Sentry.configureScope(scope => {
			const { name, id, email } = user,
				sentryId = '#'+id+':'+name;

			scope.setUser({ email, id: sentryId });
		});
	}

	static async _setupMixpanel(user) {
		if(mixpanel) {
			const { name, id, email } = user,
				sentryId = '#'+id+':'+name;

			const deviceInfo = await this.deviceInfo();

			mixpanel.identify(sentryId);
			mixpanel.people.set({ 
				name,
				email,
				deviceBrand: deviceInfo.brand,
				deviceClass: deviceInfo.deviceClass,
			});
		}
	}

	static async linkFb(accessToken) {
		// Store for future auth without asking FB
		storeToken(accessToken);

		let updatedUser;
		try {
			updatedUser = await server.post('/user/link_fb', { accessToken }, { autoRetry: true })
			if(updatedUser.error)
				throw updatedUser;
			if(!updatedUser)
				throw new Error("No response from server method");
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(updatedUser.error) {// || confirmation.length) {
			console.warn("Error logging in: ", updatedUser);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		this.currentUser = updatedUser;
		this._setupSentry(updatedUser);

		return this;
	}

	static async unlinkFb() {
		const deviceInfo = await this.deviceInfo();
		// Store for future auth without asking FB
		storeToken(deviceInfo.deviceId);


		let updatedUser;
		try {
			updatedUser = await server.post('/user/unlink_fb', { deviceInfo }, { autoRetry: true })
			if(updatedUser.error)
				throw updatedUser;
			if(!updatedUser)
				throw new Error("No response from server method");
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(updatedUser.error) {// || confirmation.length) {
			console.warn("Error logging in: ", updatedUser);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		this.currentUser = updatedUser;
		
		return this;
	}

	static async login(accessToken) {
		const deviceInfo = await this.deviceInfo();

		// Store for future auth without asking FB
		storeToken(accessToken);

		if(SERVERLESS_TESTING) {
			console.warn("Hit login while SERVERLESS_TESTING - what called this...? Probably going to fail..");
			return null;
		}

		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { accessToken, deviceInfo }, { autoRetry: true })
			// console.warn("confirmation is null or confirmation.error:", confirmation);
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {// || confirmation.length) {
			console.warn("Error logging in: ", confirmation);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Token' });

		ServerStore.metric('app.login.token');

		return this;
	}

	static async loginApple(apple) {
		const deviceInfo = await this.deviceInfo();

		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { apple, deviceInfo }, { autoRetry: true })
			// console.warn("confirmation is null or confirmation.error:", confirmation);
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {// || confirmation.length) {
			console.warn("Error logging in: ", confirmation);
			return null;
		}

		// Store for future re-login
		storeToken(confirmation.user.fbAccessToken);

		// console.warn("[login:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Apple' });

		ServerStore.metric('app.login.apple');

		return this;
	}

	static async anonymousLogin(anonymousName) {
		const deviceInfo = await this.deviceInfo();
		
		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { anonymousLogin: true, anonymousName, deviceInfo });
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging:", e);
			return null;
		}

		// console.warn("We got this:", confirmation);

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {
			console.warn("Cannot anonymousLogin:", confirmation);
			return null;
		}

		// Store for future re-login
		storeToken(confirmation.user.fbAccessToken);

		// console.warn("[tryTakeoverCode:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Anonymous' });

		ServerStore.metric('app.login.anonymous');

		return this;
	}

	static async loginEmailPass({ email, password }) {
		const deviceInfo = await this.deviceInfo();
		
		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { email, password, deviceInfo });
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging:", e);
			return null;
		}

		// console.warn("We got this:", confirmation);

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {
			console.warn("Cannot login with email/password:", confirmation);
			return null;
		}

		// Store for future re-login
		storeToken(confirmation.user.fbAccessToken);

		// console.warn("[tryTakeoverCode:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Email' });

		ServerStore.metric('app.login.email');

		return this;
	}
	
	static async tryTakeoverCode(takeoverCode) {
		const deviceInfo = await this.deviceInfo();
		
		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { takeoverCode, deviceInfo }, { autoRetry: true });
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging:", e);
			return null;
		}

		// console.warn("We got this:", confirmation);

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {
			console.warn("Cannot tryTakeoverCode:", confirmation);
			return null;
		}

		// Store for future re-login
		storeToken(confirmation.user.fbAccessToken);

		// console.warn("[tryTakeoverCode:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Takeover' });

		ServerStore.metric('app.login.takeover');

		return this;
	}


	static async logout() {
		if (this._socket) {
			this._teardownInternalSocketListeners();
			this._socket.disconnect();
		}

		this.authData       = null;
		this.currentUser    = null;
		server.setToken(null);
	
		// window.localStorage.removeItem(TOKEN_KEY);
		storeToken();

		// console.log("[ServerStore] logout: done, stored token now: ", getToken());

		return this;
	}

	static async autoLogin(options={anonymousName: null, disableAutoSignup: false}) {
		if(SERVERLESS_TESTING) {
			// console.warn("* Hit autologin in SERVERLESS_TESTING, probably will fail")
			return this.currentUser;
		}

		if(!this.currentUser) {
			// console.log("[ServerStore] autoLogin: no current user, at start, token: ", getToken(), ", option.disableAutoSignup=", options.disableAutoSignup);
			// By marshalling calls with a pendingPromise,
			// we make sure we only login once and wait for other logins to finish
			// in case we call autoLogin() before another call to autoLogin() finishes
			return this.autoLogin.pendingPromise ?
				   this.autoLogin.pendingPromise :
				(  this.autoLogin.pendingPromise = new Promise(async resolve => {
					if(!await this.attemptAutoLogin()) {
						if(options.disableAutoSignup) {
							// console.warn("[ServerStore] autoLogin: attemptAutoLogin failed, resolving null");
							resolve(null);
						}

						// console.warn("[ServerStore] autoLogin: disableAutoSignup NOT set, going to anonymousLogin");

						const res = await this.anonymousLogin(options.anonymousName);
						if(!res) {
							throw new Error("AutoLogin failed:" + JSON.stringify(res));
						}
						
						this.autoLogin.pendingPromise = null;
						resolve(res);
					} else {
						// console.log("[ServerStore] autoLogin: attemptAutoLogin succeeded, returning");
						this.autoLogin.pendingPromise = null;
						resolve(this.currentUser);
					}
				}));
		}

		// console.log("[ServerStore] autoLogin: has current user already...");
		return this.currentUser;
	}

	static async loginIfNeeded() {
		if (this.currentUser) {
			return this.currentUser;
		}
		
		return await this.attemptAutoLogin();
	}

	static async attemptAutoLogin(token) {
		if(!token)
			token = getToken();
		// console.log("[ServerStore] attemptAutoLogin: got token:", token); 
		if(token) {
			// console.log("[ServerStore] attemptAutoLogin: logging in ...");
			return await ServerStore.login(token);
		}

		return false;
	}

	static async deviceInfo() {
		return   this._cachedDeviceInfo ||
				(this._cachedDeviceInfo  = await DeviceInfo.getDeviceInfo());
	}

	static async storePushToken(token) {
		const deviceInfo = await this.deviceInfo();

		// POST to the server
		// No need to await the result, this is a write-only action
		server.post('/user/store_push_token', { deviceInfo, token }, { autoRetry: true });

		// Make return obviously explicit 
		return null;
	}

	static _cachedServerVer;
	/**
	 * Fetches latest build version from server and compares to the version this code was built with
	 *
	 * @static
	 * @returns {object}  {serverVer: string, runningVer: string, needsUpdated: bool}
	 * @memberof ServerStore
	 */
	static async appVersion({ disableCache=false }={ disableCache:false }) {

		const deviceInfo = await this.deviceInfo();

		// Note: We check ver against front end, not API host. 
		// Front end (powered by Netlify) will have the /version.json, NOT the API server.
		const verCheckHost = this.isPhoneGap ? (
			process.env.REACT_APP_STAGING === 'true'
				? 'https://staging--myverses.netlify.app'
				: 'https://myverses.app'
		) : '';

		let versionFetchFailed = false;

		const runningVer     = process.env.REACT_APP_GIT_REV,
			runningBuildTime = buildTime.date;

		if(disableCache)
			this._cachedServerVer = null;

		const serverVer  =
			this._cachedServerVer ? 
			this._cachedServerVer : 
			this._cachedServerVer = await fetch(verCheckHost + '/version.json')
				.then(data => data.json())
				.catch(()  => versionFetchFailed = true);
				
		const packet = {
			deviceInfo,
			runningVer,
			runningBuildTime: new Date(runningBuildTime),
			serverVer:       versionFetchFailed ? '(unknown)' : serverVer.ver,
			serverBuildTime: versionFetchFailed ? '(unknown)' : new Date(serverVer.buildTime),
			server: verCheckHost,
		};

		try {
			packet.needsUpdated = versionFetchFailed ? false :
				serverVer.ver !== runningVer &&
				!isNaN(packet.serverBuildTime.getTime()) &&
				!isNaN(packet.runningBuildTime.getTime()) &&
				packet.serverBuildTime > packet.runningBuildTime;
		} catch(ex) {
			console.warn("Error checking needsUpdated flag:", ex);
		}

		if(!this._printedVersion && (this._printedVersion = true))
			// if(window.isPhoneGap)
			// 	console.log("[ServerStore.appVersion] " + JSON.stringify(packet));
			// else
				console.log("[ServerStore.appVersion]", packet);

		return packet;
	}

	static _connectSocket() {
		// const url = 
		// 	(process.env.NODE_ENV === 'production') ?
		// 		this.server().urlRoot :
		// 		'http://localhost:4040';
		const url = this.server().urlRoot;
		const socket = io(url + '?token=' + this.server().token, {
			// change if there's a different mount point, must match server/app.js 'path:' config for socket.io
			path: '/socket',
			// transports: ['websocket', 'polling'],
		});

		this._socket = socket;

		// // TODO: setup events
		// socket.on('connect', function(socket){
		// 	console.warn("[ServerStore._connectSocket] Socket connected!")
		// });

		// socket.on('test message', function(msg){
		// 	console.log('socket test message: ', msg);
		// 	socket.emit('test message', { thanks: true })
		// });

		// if onSocketEvent called before _connectSocket,
		// handlers accumulate in _socketHandlerBacklog
		if (this._socketHandlerBacklog) {
			this._socketHandlerBacklog.forEach(({ event, callback }) => {
				socket.on(event, callback);
			})
		}

		this._setupInternalSocketListeners();
	}

	static _setupInternalSocketListeners() {
		const lx = (this._internalSocketListenerCache = {});
		[
			'sosCleared',
			'sosTriggered',
			'timerStarted',
			'timerStopped',
			'timerExpireChanged'
		].forEach(eventName =>
			this._socket.on(eventName, lx[eventName] = 
				data => this.emit(eventName, data)
			));
	}

	static _teardownInternalSocketListeners() {
		if(!this._internalSocketListenerCache)
			return;
		Object.keys(this._internalSocketListenerCache).forEach(key => 
			this._socket.off(key, this._internalSocketListenerCache[key])
		);
	}

	// For external modules to listen for updates
	static socket() {
		return this._socket;
	}

	static onSocketEvent(event, callback) {
		if (!this._socket) {
			if(!this._socketHandlerBacklog)
				this._socketHandlerBacklog = [];
			this._socketHandlerBacklog.push({ event, callback });
		} else {
			this._socket.on(event, callback);
		}
	}

	// Define this just for parity
	static offSocketEvent(event, callback) {
		if(!this._socket)
			return;
		this._socket.off(event, callback);
	}

	// First boot stuff - check for updates, etc
	// Called by LearnThingsCoreservice
	static async internalBoot() {
		// Clear the timeout set in index.html since we have entered the app safely
		clearTimeout(window.autoupgradeDeadmanTimeout);
		
		const ver = await ServerStore.appVersion().then(ver => {
			// console.log({ver});

			// Console code is copied from PIXI's "sayHello()" routine
			if (!window.isPhoneGap && !!window.chrome) {
				var args = [
					'\n%c %c %c === myverses ' + ver.runningVer + " ===  %c  %c  Made with love by Josiah Bryan <josiahbryan@gmail.com> https://myverses.app/  %c %c \u2665%c\u2665%c\u2665 \n\n", 
					'background: #FFFAFF; padding:5px 0;', 
					'background: #FFFAFF; padding:5px 0;', 
					'color: #fff; background: #90335a; padding:5px 0;', 
					'color: #fff; background: #FFFAFF; padding:5px 0;', 
					'color: #fff; background: #4b235d; padding:5px 0;', 
					'color: #fff; background: #4b235d; padding:5px 0;', 
					'color: #4b235d; background: #fff; padding:5px 0;', 
					'color: #29235C; background: #fff; padding:5px 0;', 
					'color: #FFFAFF; background: #fff; padding:5px 0;'
				];
				window.console.log.apply(console, args);
			} else if (window.console) {
				window.console.log('=== myverses ' + ver.runningVer + ' ===  Made with love by Josiah Bryan <josiahbryan@gmail.com> https://myverses.app/');
			}

			if(ver.needsUpdated) {
				window.console.log(" ** Version is different on the server (" + ver.serverVer + "), consider reloading or updating ...\n\n");
			}

			return ver;
		});
		
		// First-touch metric (no user, but records deviceId)
		const device = await ServerStore.deviceInfo(); // already cached...
		ServerStore.metric('app.booted.' + device.brand); // categorize by brand

		// For easy debugging externally using console
		window.downloadAppManifestUpgrades = url => this.downloadAppManifestUpgrades(url);
		window.updateFrom = url => {
			window.localStorage.setItem(DISCLAIMER_KEY, 'updated');
			this.downloadAppManifestUpgrades(url);
			window.location.reload();
		}

		this.checkForUpdates(ver);
	}

	static async checkForUpdates(ver) {
		if(!ver)
			ver = await ServerStore.appVersion({ disableCache: true });

		if(ver.needsUpdated) {
			// Justify the reason for this upgrade to metrics
			// Note: the .flush to make sure it is logged before we trigger the update
			await ServerStore.metric('app.upgrade.needed', null, JSON.parse(JSON.stringify(ver))).flush();

			// Used to show DISCLAIMER on loading
			window.localStorage.setItem(DISCLAIMER_KEY, 'updated');
			
			if(window._pgStarted) {
				const ATTEMPT_VER_KEY = '@myverses/pg-serverVer',
					ATTEMPT_COUNT_KEY = '@myverses/pg-serverVerAttemptCount';

				let latestUpdatedVerAttempted = window.localStorage.getItem(ATTEMPT_VER_KEY),
					latestUpdatedVerAttemptedCount = parseFloat(window.localStorage.getItem(ATTEMPT_COUNT_KEY));

				if(latestUpdatedVerAttempted !== ver.serverVer) {
					window.localStorage.setItem(ATTEMPT_COUNT_KEY,
						latestUpdatedVerAttemptedCount = 0
					);
				} else {
					if(isNaN(latestUpdatedVerAttemptedCount))
						latestUpdatedVerAttemptedCount = 0;

					window.localStorage.setItem(ATTEMPT_COUNT_KEY,
						latestUpdatedVerAttemptedCount ++
					);
				}

				// Store version just atempted so we coan count attempts
				window.localStorage.setItem(ATTEMPT_VER_KEY, ver.serverVer);

				// By using localStorage to store the version we attempted to upgrade,
				// when we hit this particular block again (assuming it's the same ver),
				// we allow 3 attempts to get it boot, then we continue booting like normal.
				// This is different than the deadman's timeout because this guards against
				// failures in index.html to register updates and prevents a boot cycle lock-in.
				// If a new ver comes out, then the latestUpdatedVerAttempted will not match ver.serverVer again,
				// and latestUpdatedVerAttemptedCount will be reset in that case and we can try with the new ver.
				if(latestUpdatedVerAttemptedCount < 3) {
					// Download asset-manifest from frontend server and store upgrade paths in localStorage
					const success = await this.downloadAppManifestUpgrades();
					if(success) {

						// Await .flush to ensure metrics are dumped to server before reload
						await ServerStore.metric('app.upgraded.phonegap', null, ver).flush();

						// // Notify user
						// if(window.confirm("MyVerses has been updated to version " + ver.serverVer.toUpperCase() + ", press OK to download update"))
						// 	// Reload to get index.html to boot new files straight from server
							window.location.reload();
					} else {
						console.warn("No success downloading upgrades, allowing boot to continue");
					}
				} else {
					// Notify server of failsafe failure
					ServerStore.metric('app.upgraded.phonegap.rejected.reboot_lock_failsafe', null, {
						latestUpdatedVerAttemptedCount,
						latestUpdatedVerAttempted,
						ver
					});
				}
	
			} else {

				// Clear service worker caches
				window.caches && window.caches.keys().then(keys => { 
					keys.forEach(key => {
						// if(key.includes("temp")) {
							console.log("Removing cache for new version update from ", key);
							window.caches.delete(key);
						// }
					})
				});
				
				// Await .flush to ensure metrics are dumped to server before reload
				await ServerStore.metric('app.upgraded.browser', null, ver).flush();

				// // Notify user
				// if(window.confirm("myverses game has been updated to version " + ver.serverVer.toUpperCase() + ", press OK to download update"))
				// 	// Reload page
					window.location.reload();
			}
		} 
	}


	// cacheAliases used for handling development server - in dev, the chunk is 0.js, in prod it's 1.js
	static async downloadAppManifestUpgrades(manifestServerOverride=null, cacheAliases={"0.js":"1.js"}) {
		if(!window.isPhoneGap) {
			console.warn("ServerStore detected it's not running in cordova, cannot write to filesystem for the upgrade cache, so nothing to do.");
			return false;
		}
		// download asset-manifest, extract main.js/main.css, and chunks:
		// "main.css": "./static/css/main.f309e651.chunk.css",
		// "main.js": "./static/js/main.6e8268ac.chunk.js",
		// "static/css/1.8866556e.chunk.css": "./static/css/1.8866556e.chunk.css",
		// "static/js/1.d455662b.chunk.js": "./static/js/1.d455662b.chunk.js",
		// Store in app-boot-upgrades

		const cache = {},
			manifestServer = manifestServerOverride ? manifestServerOverride : 
				process.env.NODE_ENV === 'production'
					? 'https://myverses.app' :
				process.env.REACT_APP_STAGING === 'true'
					? 'https://staging--myverses.netlify.app'
					: 'https://' + window.location.hostname + ':3000',
			manifestFile   = '/asset-manifest.json',
			manifestUrl    = [manifestServer, manifestFile, '?_=', Date.now()].join(''),
			manifest       = (await fetch(manifestUrl)
				.then(body => body ? body.json() : {})
				.then(json => json && json.files ? json.files : json) // new format?
				.catch(err => {
					console.warn("Error downloading manifest:", err);
					return {};
				})) || {};
			
		console.log("[ServerStore.downloadAppManifestUpgrades] received manifest:", manifest);

		Object.keys(manifest).forEach( key => {
			const file = manifest[key];

			// This regex takes something like "./static/js/main.6e8268ac.chunk.js" and returns:
			// m[1] = "main"
			// m[2] = "css"
			const m = file.match(/static\/(?:.*\/)?([^/]+?)(?:\.[^.]+)?\.chunk\.(js|css)$/);
			if(m) {
				const fileKey = m[1];
				const fileType = m[2];
				const storageKey = `${fileKey}.${fileType}`;

				cache[storageKey]  =  file.startsWith('http') ? file :
					manifestServer + (file.startsWith('./')   ? file.replace(/^.\//, '/') : file);

				// Alias if specified
				if(cacheAliases[storageKey])
					cache[cacheAliases[storageKey]] = cache[storageKey];
					
			} else {
				// console.warn("[installUpdates] Unable to match regex with:" + file);
			}
		});

		console.log("[ServerStore.downloadAppManifestUpgrades] Storing upgrade list:", cache); //, manifest); // + JSON.stringify(cache));

		// window.localStorage.setItem("app-boot-upgrades", JSON.stringify({ urls: cache, files: {} }));
		const { success, error, url } = await writeUpgradeCache({ urls: cache, files: {} })
		console.log("[ServerStore.downloadAppManifestUpgrades] write file results: ", { success, error, url });
		return success;
	}
}

window.store = ServerStore;
