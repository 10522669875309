import React from 'react';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';
import nameForLevel from 'utils/nameForBadgeLevel';
import { ServerStore } from 'utils/ServerStore';
import styles from './PackPage.module.scss';

import { getItemColor, computeTextColor } from 'utils/itemColorHelper';
import { round2digits } from 'utils/proctor/utils';
import seconds2duration from 'utils/seconds2duration';
import moment from 'moment';


import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ButtonBase, Fab, Tooltip } from '@material-ui/core';

import MoreMenu from 'components/MoreMenu';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import UseThisPackIcon from '@material-ui/icons/Favorite';
import SettingsIcon from '@material-ui/icons/Settings';
import ShareIcon from '@material-ui/icons/Share';
import UnfollowIcon from '@material-ui/icons/Block';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

import ReactMarkdown from 'react-markdown';


import CircleProgress from 'components/CircleProgress';

export default function HeaderCard({ 
	pack,
	onStart,
	followFab,
	onSettings,
	onUnfollow,
	onShare,
	onRestart
}) {
	const match640 = useMediaQuery('(min-width:640px)');

	const numFollowers = pack.userFollows.numFollowers - (pack.userIsOwner ? 1 :0);

	const stats = pack.userStats,
		cursor = pack.cursor || {};
		// cursor = {
		// 	progressPercent: 33
		// };

	const moreActions = [];
	if(pack.userFollows.isFollowing) {
		moreActions.push({
			text: 'Unfollow',
			icon: UnfollowIcon,
			onClick: onUnfollow,
		});
	}

	if(pack.userCanEdit && (pack.userIsAdmin || pack.userIsOwner)) {
		moreActions.push({
			text: 'Pack Settings',
			icon: SettingsIcon,
			onClick: onSettings,
		});
	}

	function Content({ pack }) {
		const [ headerInfoOpen, setHeaderInfoOpenState ] = React.useState();
		const setHeaderInfoOpen = flag => {
			setHeaderInfoOpenState(flag);
			ServerStore.metric('app.pack_page.header_info.' + (flag ? 'opened' : 'closed'));
		};

		return (<div className={clsx(headerInfoOpen && styles.headerInfoOpen)}>
			<Tooltip
				title="Return to your list of verse packs"
			>
				<IconButton
					aria-haspopup="true" 
					className={styles.backBtn}
					// onClick={() => history.push('/home')}
					onClick={() => window.history.go(-1)}
				>
					<ChevronLeftIcon fontSize="small"/>
				</IconButton>
			</Tooltip>

			<div className={styles.followInfo}>
				{/* {!pack.userIsOwner ? <> */}
				<div className={styles.createdBy}>
					By <b>{
						pack.userIsOwner ? 
							"You" :
							pack.userFollows.createdBy.name
					}</b>
				</div>
				{numFollowers > 0 ? <>
					<div className={styles.dot}/>
					
					<div className={styles.numFollows}>
						<b>{pack.userFollows.numFollowers}</b> followers
					</div>
				</>: ""}
			</div>
			
			<div className={styles.actionMenu}>
				<IconButton
					onClick={onShare}
				>
					<ShareIcon fontSize="small"/>
				</IconButton>

				{moreActions && moreActions.length ?
					<MoreMenu
						tooltip="Edit this verse collection's settings, such as name, photo, and sharing"
						className={styles.moreActions}
						actions={moreActions}/>
				: "" }
			</div>

			{/* {pack.userCanEdit && (pack.userIsAdmin || pack.userIsOwner) ?
				<MoreMenu
					tooltip="Edit this verse collection's settings, such as name, photo, and sharing"
					className={styles.moreMenu}
					actions={[{
						text: 'Pack Settings',
						icon: SettingsIcon,
						onClick: onSettings,
					}, {
						text: 'Share',
						icon: ShareIcon,
						onClick: onShare,
					}
				]}/>
			: 
				<MoreMenu
					tooltip="Share this pack with your friends or unfollow"
					className={styles.moreMenu}
					actions={pack.userFollows.isFollowing ? [{
							text: 'Unfollow',
							icon: UnfollowIcon,
							onClick: onUnfollow,
						}, {
							text: 'Share',
							icon: ShareIcon,
							onClick: onShare,
						}
					] : [{
							text: 'Share',
							icon: ShareIcon,
							onClick: onShare,
						}
				]}/>
			} */}
			
			{pack.imageUrl ? <>
				<div 
					className={styles.coverPhoto}
					// onClick={() => headerInfoOpen && setHeaderInfoOpen(false)}
					style={{
						// backgroundImage: `url("${pack.imageUrl}")`
					}}
				/>
				<div className={styles.coverColor}/>
			</>:""}

			{pack && pack.name ? 
				<ButtonBase 
					className={styles.letter}
					onClick={() => setHeaderInfoOpen(!headerInfoOpen)}
				>
					<div className={styles.text}>
						{(pack.name+"").substring(0,1)}
						<CircleProgress 
							value={((stats ? stats.badgeLevel : 0) / 5) * 100}
							className={styles.badgeCircle}
							size={100}
						/>
						{stats ? <>
							<CircleProgress 
								value={cursor.progressPercent || 0}
								className={styles.progressCircle}
								size={116}
							/>
							{/* <CircleProgress value={stats.avgComprehension * 100}
								className={styles.outerCircle2}
								size={132}
							/> */}
						</> : ""}
					</div>
				</ButtonBase>
			:""}

			<Tooltip
				title="What do the circles mean?"
			>
				<IconButton
					aria-haspopup="true" 
					className={styles.openHeaderInfo}
					onClick={() => setHeaderInfoOpen(true)}
				>
					<HelpOutlineIcon fontSize="small"/>
				</IconButton>
			</Tooltip>

			<Tooltip
				title="Close overview"
			>
				<IconButton
					aria-haspopup="true" 
					className={styles.closeHeaderInfo}
					onClick={() => setHeaderInfoOpen(false)}
				>
					<CloseIcon fontSize="small"/>
				</IconButton>
			</Tooltip>

			<div className={styles.headerInfo}>
				
				<h1>{pack.name}</h1>

				<div className={styles.circles}>
					<div className={clsx(styles.block, styles.badgeLevel)}>
						<CircleProgress 
							value={((stats ? stats.badgeLevel : 0) / 5) * 100}
							className={styles.badgeCircle}
							size={32}
						/>
						<div className={styles.info}>
							<label>Memorization Level</label>
							<label className={styles.hint}>
								Average of the levels of all the verses in this pack
							</label>
							<div className={styles.data}>
								<span className={styles.value}>
									{round2digits( (stats ? stats.badgeLevel : 0) )}
								</span>
								/
								<span className={styles.max}>
									5
								</span>
							</div>
							<div className={styles.dataSubtext}>
								{nameForLevel(stats ? stats.badgeLevel : 0)}
							</div>
						</div>
					</div>

					<div className={clsx(styles.block, styles.progressPercent)}>
						<CircleProgress 
							value={cursor.progressPercent || 0}
							className={styles.progressCircle}
							size={32}
						/>

						<div className={styles.info}>
							<label>Progress</label>
							<label className={styles.hint}>
								Percent completed this time through the list of verses
							</label>
							<div className={styles.data}>
								<span className={styles.value}>
									{Math.round( cursor.progressPercent || 0 )}%
								</span>
								
								{/* <span className={styles.max}>
									{" "}(
										{Math.ceil(pack.verses.length * (
											(cursor.progressPercent || 0) / 100
										))}
										/	
										{pack.verses.length}
									)
								</span> */}
							</div>
							<div className={styles.dataSubtext}>
								{Math.ceil(pack.verses.length * (
									(cursor.progressPercent || 0) / 100
								))}
								/	
								{pack.verses.length} verses
							</div>
						</div>
					</div>
				
				</div>


				<div className={styles.stats}>
					<div data-stat="numVerses">
						<span>{pack.numVerses}</span>
						<label>verses</label>
					</div>
					{stats ? <>
						<div data-stat="avgComprehension">
							<span>
								{Math.round(stats.avgComprehension * 100)}%
							</span>
							<label>score</label>
						</div>
						<div data-stat="lastTested">
							<span>
								{moment(stats.lastTested).fromNow().replace('minutes','min')}
							</span>
							<label>last practiced</label>
						</div>
						<div data-stat="numTests">
							<span>
								{stats.numTests} x
							</span>
							<label>practices</label>
						</div>
						<div data-stat="totalTime">
							<span>
								{seconds2duration(stats.totalTime)} min
							</span>
							<label>total</label>
						</div>
					</> : ""}
				</div>

			</div>


			<div className={styles.textContentWrap}>
				<h1>{pack.name}</h1>
				<ReactMarkdown source={pack.description}/>
			</div>

			{pack.userFollows.isFollowing ? 
				<>
				{pack.verses && pack.verses.length ?
					<div 
						className={styles.fabWrap}
						style={{
							'--progress-percent': `${cursor.progressPercent || 0}%`
						}}
						data-hasprogress={cursor.progressPercent ? "true" : "false"}
					>
						<Fab 
							onClick={onStart}
							className={styles.fab}
							variant="extended"
							aria-label="add"
						>
							{cursor.progressPercent ?
								<>
									<SlowMotionVideoIcon/>
									<label>
										Continue Practicing
										<span className={styles.percent}>
											({cursor.progressPercent}% done)
										</span>
									</label>
								</>
								:
								<>
									<PlayCircleFilledIcon/>
									<label>Practice Verses</label>
								</>
							}
						</Fab>

						{cursor.progressPercent ? <>
							<ButtonBase
								onClick={onRestart}
								className={styles.restartPack}
							>
								<SkipPreviousIcon style={{fontSize:'.825rem'}}/>
								<label>Restart from beginning</label>
							</ButtonBase>
						</> : ""}
					</div>
				:""}
				</>
			:
				<>
					<div className={clsx(styles.fabWrap, styles.followFabWrap)}>
						<Fab 
							onClick={followFab}
							className={clsx(styles.fab, styles.followFab)}
							variant="extended"
							aria-label="add"
						>
							<UseThisPackIcon/>
							<label>Use Verse Pack</label>
						</Fab>
					</div>

				</>
			}
			

			
{/* 
			<div className={styles.stats}>
				<div data-stat="numVerses">
					<span>{pack.numVerses}</span>
					<label>verses</label>
				</div>
				{stats ? <>
					<div data-stat="avgComprehension">
						<span>
							{Math.round(stats.avgComprehension * 100)}%
						</span>
						<label>score</label>
					</div>
					<div data-stat="lastTested">
						<span>
							{moment(stats.lastTested).fromNow().replace('minutes','min')}
						</span>
						<label>last practiced</label>
					</div>
					<div data-stat="numTests">
						<span>
							{stats.numTests} x
						</span>
						<label>practices</label>
					</div>
					<div data-stat="totalTime">
						<span>
							{seconds2duration(stats.totalTime)} min
						</span>
						<label>total</label>
					</div>
				</> : ""}
			</div> */}
			
		</div>);
	}

	if(!match640) {
		return (<div 
			className={styles.fullBleedTitleCard}
			style={{
				'--pack-color': getItemColor(pack, pack.imageMeta),
				'--pack-text': computeTextColor(getItemColor(pack)),
				'--pack-text2': computeTextColor(getItemColor(pack), false, 0.625),
				'--cover-color': getItemColor(pack.imageMeta),
				'--cover-text': computeTextColor(getItemColor(pack.imageMeta)),
			}}
		>
			<Content pack={pack}/>
		</div>);
	}

	return (<Card 
		className={styles.containedTitleCard}
		style={{
			'--pack-color': getItemColor(pack, pack.imageMeta),
			'--pack-text': computeTextColor(getItemColor(pack)),
			'--cover-color': getItemColor(pack.imageMeta),
			'--cover-text': computeTextColor(getItemColor(pack.imageMeta)),
		}}
	>
		<Content pack={pack}/>
	</Card>);
};

