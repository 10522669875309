import React from 'react';
import clsx from 'clsx';
import styles from './PrimaryNav.module.scss';
import { withRouter } from 'react-router-dom';

import { ServerStore } from 'utils/ServerStore';
import history from 'utils/history';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import SearchIcon   from '@material-ui/icons/Search';
import PacksIcon    from '@material-ui/icons/ViewCarousel';
// import ProgressIcon from '@material-ui/icons/EmojiEvents';
import SettingsIcon from '@material-ui/icons/Settings';
import ProgressIcon from '@material-ui/icons/DataUsage';

// Export for use elsewhere, like OnBoarding or the LogPage
export const ICONS = {
	SearchIcon,
	PacksIcon,
	ProgressIcon,
	SettingsIcon
}

/**
 * NavControlService service is envisioned as an importable
 * service other pages can import to change the nav that is highlighted
 * programatically. (Note: Just changes the indicator, NOT the current page).
 * Also can show/hide the nav (e.g. for onboarding, etc)
 */
export class NavControlService {
	static patchEmpty = patch => NavControlService.EMPTY_STATE_REF.current = {
		...NavControlService.EMPTY_STATE_REF.current,
		...patch
	};

	static EMPTY_STATE_REF = { current: {}, patch: NavControlService.patchEmpty };
	static stateRef = NavControlService.EMPTY_STATE_REF;

	static NAV_SEARCH    = 0;
	static NAV_PACKS     = 1;
	static NAV_PROGRESS  = 2;
	static NAV_SETTINGS  = 3;

	static navDestinations = [
		'/search',
		'/home',
		'/progress',
		'/settings',
	];

	static showPrimaryNav(visible = true) {
		this.stateRef.patch({ visible });
		NavControlService.patchEmpty({ visible });

		const className = 'PrimaryNav-visible';
		if(visible) {
			document.body.classList.add(className);
		} else {
			document.body.classList.remove(className);
		}
	}

	static isPrimaryNavVisible() {
		return !!this.stateRef.current.visible;
	}

	static getNavDestination(idx) {
		return this.navDestinations[idx];
	}

	static setCurrentNav(navIndex, navigate=false) {
		if(navIndex === undefined
			|| isNaN(navIndex)
			|| navIndex < 0
			|| navIndex >= NavControlService.navDestinations.length
		) {
			throw new Error("Invalid navIndex: " + navIndex);
		}
		// console.warn("setCurrentNav:", { navIndex })
		this.stateRef.patch({ navIndex });
		NavControlService.patchEmpty({ navIndex });
		
		if (navigate) {
			history.push(this.getNavDestination(navIndex));
			// I've seen sometimes (e.g. clicking alerts from background)
			// that the scrollTop is partway down the page after loading.
			// This is to fix that
			setTimeout(() => {
				window.scrollTo(0,0);
				document.body.scrollTop = 0;
			}, 500);
		}
	}

	static getCurrentNav() {
		return this.stateRef.current.navIndex || undefined;
	}

	static setUnreadMessagecount(unreadMessageCount) {
		this.stateRef.patch({
			unreadMessageCount
		})
	}

	static getUnreadMessageCount() {
		return this.stateRef.current.unreadMessageCount || 0;
	}
}

// Just for debugging
if(process.env.NODE_ENV !== 'production') {
	window.NavControlService = NavControlService;
}

export default withRouter(function PrimaryNav({
	history
}) {
	const [ state, setState ] = React.useState({
		navIndex: NavControlService.getCurrentNav(),
		visible: NavControlService.isPrimaryNavVisible(),
		unreadMessageCount: NavControlService.getUnreadMessageCount()
	});

	const patchState = (patch = {}) => {
		setState({ ...state, ...patch });
	}

	React.useEffect(() => {
		const visible = NavControlService.isPrimaryNavVisible();
		const navIndex = NavControlService.getCurrentNav() || 0;

		NavControlService.stateRef = {
			current: state,
			patch:   patchState
		}

		if(state.visible !== visible) {
			patchState({ visible });
		}

		if(state.navIndex !== navIndex ) {
			patchState({ navIndex });
			// console.warn("Patching, ", { navIndex, state: state.navIndex });
		} else {
			// console.warn("Not patching, states match", { navIndex, state: state.navIndex });
		}

		return () => {
			NavControlService.stateRef = NavControlService.EMPTY_STATE_REF;
		}
	});

	if(!state || !state.visible) {
		return <></>;
	}

	return (<>
		<BottomNavigation
			value={state.navIndex}
			onChange={(event, navIndex) => {
				NavControlService.setCurrentNav(navIndex);
				const nav = NavControlService.getNavDestination(navIndex);
				if(nav) {
					ServerStore.metric("app.primary_nav.navigated." + nav.replace('/',''));
					history.push(nav);
				}
			}}
			showLabels
			className={clsx(
				styles.root,
				state.visible && styles.visible
			)}
		>
			<BottomNavigationAction label="Search"   icon={<SearchIcon />} />
			<BottomNavigationAction label="Packs"    icon={<PacksIcon />} />
			<BottomNavigationAction label="Progress" icon={<ProgressIcon />} />
			{/* <BottomNavigationAction label="Settings" icon={<SettingsIcon />} /> */}
		</BottomNavigation>
	</>)
});