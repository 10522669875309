// import React from 'react';
import { 
	createFillInBlankText,
	tokWordsToText,
	tokenize,
	shuffle
} from './utils';
import stringSimilarity from 'string-similarity';

import FAKE_VERSE_LIST_FOR_FILLER from './FakeVerseListForFiller';

// console.log({ FAKE_VERSE_LIST_FOR_FILLER });

/**
 * AnswerManager is responsible for options and answers during an attempt.
 * 
 * - Must provide options (good and bad) for an attempt
 * - Must decide if the attempted answer is a valid answer 
 */

// This is accessible externally as Proctor.AnswerManager.AnswerTypes
const AnswerTypes = {
	FROM_MEMORY: 'FROM_MEMORY', // not used yet
	FILL_IN_BLANKS: 'FILL_IN_BLANKS', // what it says
	MULTIPLE_CHOICE: 'MULTIPLE_CHOICE', // what it says
	HALF_CHUNKS: 'HALF_CHUNKS', // split the verse in half, complete first/2nd half
};

export default class AnswerManager {
	constructor(proctor) {
		this.proctor = proctor;
	}

	async init() {

	}

	async destroy() {
		// ...
	}

	getAnswerOptions(verse, {
		answerType = AnswerTypes.FILL_IN_BLANKS,
		numOptions = 2, // only relevant for MULTIPLE_CHOICE
		testMocks = {
			randomBlankIndices: null, // ex: [0,1],
		}
	} = {}) {
		// Reset
		this.fillInBlanks = false;

		// Leftover state
		if(verse._correctAnswer) {
			delete verse._correctAnswer;
		}
		
		// Default to FILL_IN_BLANKS
		if(!answerType) {
			// Allow verse to set a type if not specified at runtime
			// Note: This isn't present on the db schema right now,
			// this is just present from when we imported learnthings 
			// incase we want to implement
			if(verse.metaType) {
				answerType = verse.metaType;
			} else {
				answerType = AnswerTypes.FILL_IN_BLANKS;
			}
		}

		// So that tryAnswer knows what to expect
		this.currentAnswerType = answerType;

		// The simplest answer type: The have to do the whole
		// answer from memory - no UI yet
		if(answerType === AnswerTypes.FROM_MEMORY) {
			return {
				answers: [
					{ answer: verse.text }
				]
			}
		}

		// The rest of the answer types (at the moment)
		// need "bad answers" to mix in with the good answers,
		// so get the list of other verses in this sequence to mix/match
		const verseList = this.proctor.sequenceManager.getVerseList() || [];
		const otherVerses = verseList.filter(q => 
			q.group      === verse.group &&
			q.isReversed === verse.isReversed && 
			q.id         !== verse.id
		);

		// One of the most complex answer types...
		if(answerType === AnswerTypes.FILL_IN_BLANKS) {
			const badgeLevel = (verse.stats && verse.stats.badgeLevel) || 1,
				maxBlanks = badgeLevel <= 1 ?
					(
						badgeLevel <= 1 ?  5 :
							undefined
						// badgeLevel <= 2 ?  8 :
						// badgeLevel <= 3 ? 12 : 12
					) : undefined,
				maxBlanksPercent = badgeLevel > 1
					? (
						badgeLevel === 2
							? 0.25 :
						badgeLevel === 3
							? 0.50 :
						badgeLevel === 4
							? 0.50
							: 1.00
					) 
					: undefined;

			console.log(`[AnswerManager.getAnswerOptions] verse: ${verse.cachedRef}, badgeLevel=${badgeLevel}`, { maxBlanks, maxBlanksPercent });

			const { 
				words,
				text: newVerse
			} = createFillInBlankText(verse.text, {
				maxBlanksPercent,
				maxBlanks
			});
			
			const requiredAnswers = words
				.filter(x => x.blank)
				.map(x => x.text);

			const otherList = otherVerses && otherVerses.length ?
				otherVerses : FAKE_VERSE_LIST_FOR_FILLER;
			
			const badAnswers = shuffle(otherList)
				.slice(0, 3) // max other verses to include
				.map(x => 
					createFillInBlankText(x.text)
						.words
						.filter(row => row.blank)
						.map(row => row.text)
						// By joining, we're basically returning a string
						// to the outer map,
						// which makes it easy to merge all the verses
						// by joining again with a space,
						// then splitting that one large string
						// on spaces which is basically making it into 
						// individual words again
						.join(' ')
				)
				.join(' ')
				.split(/\s+/)
				.slice(0, requiredAnswers.length);
			
			// Mix up the answers into an object
			const answers = [
				...requiredAnswers,
				...badAnswers
			].sort((a,b) => {
				const x1 = a.toLowerCase(),
					  x2 = b.toLowerCase();
				return x1 > x2 ? +1 : x1 < x2 ? -1 : 0
			})
			.map(answer => { return { text: answer } });

			// Hold internal state for this attempt for fill-in-the-blanks
			this.fillInBlanks = {
				numBlanksRemaining: requiredAnswers.length,
				requiredAnswers,
				words,
				attemptedAnswers: [],
				badAnswers,
				answers,
				verse,
				textToAttempt: newVerse
			};

			return {
				tokenizedWords: words,
				newVerse,
				answers
			}
		}

		// Empty-set fail here because FILL_IN_BLANKS can work if no other verses
		// but multiple choices requires other verses
		if(!otherVerses.length) {
			return [];
		}

		// Half chunks is similar to MULTIPLE_CHOICE in that 
		// only one choice (not multiple blanks), but instead of offering
		// the whole text as the choice, we offer half the text and return new text
		// as the prompt
		if(answerType === AnswerTypes.HALF_CHUNKS) {
			const getHalves = (verseText, useFirstHalf) => {
				const halfPoint = Math.floor(verseText.length / 2),
					firstSpaceAfterHalf = verseText.indexOf(' ', halfPoint),
					start = verseText.slice(0, firstSpaceAfterHalf),
					end   = verseText.slice(firstSpaceAfterHalf + 1);

				const ellipses = '…';
				if(useFirstHalf) {
					return { answer: start + ellipses, question: ellipses + end };
				} else {
					return { answer: ellipses + end, question: start + ellipses };
				}
			};

			const useFirstHalf = Math.random() >= 0.5;

			const { question, answer } = getHalves(verse.text, useFirstHalf);
			
			// Hijack the verse to store our chosen answer for checking later
			verse._correctAnswer = answer;

			const smallSet = shuffle(otherVerses).slice(0, numOptions || 2);
			const answers = shuffle(
				[ answer, ...smallSet.map(({ text }) => 
					getHalves(text, useFirstHalf).answer
				) ]
			).filter( x => x).map(text => {
				return { text }
			});

			return {
				newVerse: question,
				answers
			}
		}

		// Default to MULTIPLE_CHOICE
		const smallSet = shuffle(otherVerses).slice(0, numOptions || 2);
		const answers = shuffle(
			[ verse.text, ...smallSet.map(({ answer }) => answer) ]
		).filter( x => x).map(text => {
			return { text }
		});

		return { answers };
	}

	// The interactive utility to revise our verse with words chosen
	// (correct or not)
	updateFillInBlanksState(answerIndex, rawText) {
		const tok = rawText !== undefined ? tokenize(rawText) : null;

		let {
			words,
			attemptedAnswers,
			answers
		} = this.fillInBlanks;

		// Our list of answers chosen by the user already
		attemptedAnswers = attemptedAnswers.slice();
		
		// For indicating if the choices was correct
		const required = Array.from(this.fillInBlanks.requiredAnswers || []);
		let nextRequired = required.shift();

		const wordsClone = JSON.parse(JSON.stringify(words));

		let lastAttemptedWasCorrect = undefined;

		// Provided by the UI
		if(answerIndex !== undefined) {
			const info = answers[answerIndex];
			if(info) {
				info.used = true;
			}
		} else {
			// Will be reset below - this is used in the UI to show if 
			// answer has been chosen already
			answers.forEach(info => info.used = false);
		}

		this.fillInBlanks.numBlanksRemaining = 0;

		const accuracy = { correct: 0, total: 0 };

		const normalizeWord = word => word
			.toString()
			.toLowerCase()
			.replace('’',"'");

		const derivedAttemptedAnswers = [];

		// Used for matching rawText to expected non-blank words
		let tokCursor = 0;
		const similar = (a,b) => stringSimilarity.compareTwoStrings(a,b);

		wordsClone.forEach((row, idx) => {
			// const tokValue = tok && idx < tok.length ? tok[idx] : null;

			// This simple algorithim works by "using" the token from the rawText
			// only when it finds one that matches the expected non-blanked word.
			// So if the prompt is:
			//   For God so loved the _____ ...
			// And user types:
			//   But For God so very much loved the world ...
			// The algo would basically do this:
			//   For -> (but) for
			//   God -> god
			//   So  -> so
			//   Loved -> (very much) loved
			//   the  -> the
			//   ____ -> world
			// Where above the parens indicate the algo just skipped those words
			// until it matched the next rawText token to the expected token (or hit end of 
			// the rawText input, whichever comes first)
			//
			// NOTE: The fuzzy matching and auto-skipping of words ONLY
			// is applied when there is not a blank (____) in the promp text.
			// If the promp text needs an exact word, the user must put the exact
			// word (case INsensitive)
			//
			let tokValue;
			if(tok) {
				// console.log(`[AnswerManager/ufibs] (${idx}:${row.text}) starting tokCursor:`, tokCursor);

				let done;
				while(!done && tokCursor < tok.length) {
					if(row.blank) {
						tokValue = tok[tokCursor];

						done = true;
						// console.log(`[AnswerManager/ufibs] (${idx}:${row.text}) cursor at ${tokCursor}: row.blank, so using tokValue.text=`, tokValue.text);
					} else {
						const tmpTok = tok[tokCursor];
						const match = similar(
							normalizeWord(row.text),
							normalizeWord(tmpTok.text)
						);
						// Magic number to allow for basic mispellings,
						// like loved==loves, etc
						if(match >= 0.75) {
							done = true;
							tokValue = tok[tokCursor];

							// console.log(`[AnswerManager/ufibs] (${idx}:${row.text}) cursor at ${tokCursor}: match=${match}, so using tokValue.text=`, tokValue.text);
						} else {
							// console.log(`[AnswerManager/ufibs] (${idx}:${row.text}) cursor at ${tokCursor}: NO MATCH because match=${match}, NOT using tmpTok.text=`, tmpTok.text);
						}
					}

					// if(!done) {
					// 	console.log(`[AnswerManager/ufibs] (${idx}:${row.text}) cursor at ${tokCursor}: NO MATCH, SKIPPING token:`, tok[tokCursor]);
					// }

					tokCursor ++;
				}

				// console.log(`[AnswerManager/ufibs] (${idx}:${row.text}) ENDED with cursor at ${tokCursor} and tokValue object is`, tokValue);
			}

			row.hasTextValue = rawText && tokValue;
			
			// This index was one that had a word removed...
			if(row.blank) {
				accuracy.total ++;

				// const atp = attemptedAnswers.slice();//debug only

				const word = (rawText && tokValue) ? tokValue.text : attemptedAnswers.shift();
				if(word && (!tok || (rawText && tokValue))) {
					derivedAttemptedAnswers.push(word);

					// Find the answer ref so we can update the .used flag
					if(answerIndex === undefined) {
						const info = answers.find(d => d.text === word && !d.used);
						if(info) {
							info.used = true;
						}
					}

					// console.log(`- debug: [idx=${idx}] rawText={'${rawText}'}, word=`, word, { tokValue, attemptAnswers: atp })

					// For updating the output display string
					const correctWord = normalizeWord(nextRequired),
						correctChoice = correctWord === normalizeWord(word);
					// console.log(`? correctChoice:${correctChoice}, nextRequired=${nextRequired}, word=${word}`);
					nextRequired = required.shift();

					// Replace this with JSX in consumers of this class
					row.blankText = `<<${word}>>[${correctChoice ? '+':'x:' + correctWord}]`;

					if(correctChoice) {
						accuracy.correct ++;
					}

					lastAttemptedWasCorrect = correctChoice;
				} else {
					this.fillInBlanks.numBlanksRemaining ++;

					row.blankText = '';
				}
			}
		});

		if (accuracy.totalBlanks) {
			accuracy.ratio = accuracy.correct / accuracy.total;
		}

		// Necessary for raw-text merging
		this.fillInBlanks.attemptedAnswers = derivedAttemptedAnswers;

		// console.dir(wordsClone, { depth: 4})

		const newVerse = tokWordsToText(wordsClone);

		// This is the text used in the tryAnswer() to match strings
		this.fillInBlanks.textToAttempt = newVerse.replace(
			// This removes our tagged attempt words from matching,
			// that way our tags dont affect string similarity
			/<<([^>]+)>>\[[^\]]+\]/g,
			'$1'
		);

		// console.log(newVerse, this.fillInBlanks, wordsClone);
		
		// For updated UI
		return {
			tokenizedWords: wordsClone,
			updatedVerse: newVerse,
			updatedAnswerList: answers,
			lastAttemptedWasCorrect,
			accuracy,
			sanatizedAnswer: this.fillInBlanks.textToAttempt
		}
	}

	// Just pop the last answer and update state
	undoLastTryAnswer(verse) {
		if(!verse.id) {
			throw new Error("No ID on first arg");
		}

		if(this.fillInBlanks) {
			if (this.fillInBlanks.attemptedAnswers.length) {
				this.fillInBlanks.attemptedAnswers.pop();
			}

			const state = this.updateFillInBlanksState();

			// console.log("After undoLastTryAnswer(), state:", state);
			return state;
		}

		// console.warn("Unable to undoLastTryAnswer(), unknown meta type:", verse.metaType);
		
		return {
			undoNotSupported: this.currentAnswerType
		};
	}

	// Given the TEXT of the answer (.text),
	// see if it matches. If FILL_IN_BLANKS, may return {incomplete:true}
	// if blanks remaining, otherwise gives {bad:false|true, good:true|false} results
	tryAnswer(verse, possibleAnswer, answerIndex = undefined) {
		if(!verse.id) {
			throw new Error("No ID on first arg");
		}

		const answerType = this.currentAnswerType;

		let returnData = {};

		let matched;
		if(answerType === AnswerTypes.MULTIPLE_CHOICE) {
			matched = possibleAnswer === verse.text;
		} else 
		if(answerType === AnswerTypes.HALF_CHUNKS) {
			matched = possibleAnswer === verse._correctAnswer;
		} else
		if(answerType === AnswerTypes.FROM_MEMORY) {
			const SIMILARITY_REQUIRED = 0.90;

			// Split and re-join the Correct Answer so it more closely matches
			// the format the possible answer (answerToAttempt) will be in
			const correctAnswer = verse.text
				.toLowerCase()
				.replace(/[^a-z]/g, '');
			
			const attemptAnswer = possibleAnswer
				.toLowerCase()
				.replace(/[^a-z]/g, '');
				
			const similarity = stringSimilarity.compareTwoStrings(
				attemptAnswer,
				correctAnswer
			);

			console.log("Attempting answer FROM_MEMORY, similarity:", similarity, { answer: correctAnswer, attempted: attemptAnswer }) 

			matched = similarity > SIMILARITY_REQUIRED;
		} else {
			const fibState = this.fillInBlanks;
			const rawTextMode = possibleAnswer.rawTextMode;

			if (fibState.numBlanksRemaining > 0 || rawTextMode) {
				if (possibleAnswer.text === undefined) {
					fibState.attemptedAnswers.push(possibleAnswer);
				}

				// console.log("AnswerManager: at start, fibState:", { ...fibState });

				const { 
					updatedVerse,
					updatedAnswerList,
					tokenizedWords,
					lastAttemptedWasCorrect,
					accuracy,
					sanatizedAnswer
				} = this.updateFillInBlanksState(answerIndex, 
					possibleAnswer.rawTextMode && possibleAnswer.text
				);

				// console.log("AnswerManager: after updateFillInBlanksState, fibState:", { ...fibState });


				if(fibState.numBlanksRemaining > 0) {
					const result = {
						incomplete: true,
						updatedVerse,
						updatedAnswerList,
						tokenizedWords,
						lastAttemptedWasCorrect,
						rawTextMode,
						accuracy,
						sanatizedAnswer
					}

					// console.warn("Attempt not complete, returned new result:", result, this.fillInBlanks);
					return result;
				} else {
					// console.log("+ Answer complete, falling thru to comparrison")
					returnData = {
						updatedVerse,
						updatedAnswerList,
						tokenizedWords,
						lastAttemptedWasCorrect,
						rawTextMode,
						accuracy,
						sanatizedAnswer
					};
				}
			}

			const SIMILARITY_REQUIRED = 0.99;

			// Split and re-join the Correct Answer so it more closely matches
			// the format the possible answer (textToAttempt) will be in
			const correctAnswer = verse.text
				// .split(/[\s:]/)
				// .join('')
				.toLowerCase()
				.replace(/[^a-z]/g, '');
			
			const attemptAnswer = fibState.textToAttempt
				.toLowerCase()
				.replace(/[^a-z]/g, '');
				
			const similarity = stringSimilarity.compareTwoStrings(
				attemptAnswer,
				correctAnswer
			);

			// console.log("Attempting answer, similarity:", similarity, { answer: verse.text, attempted: this.fillInBlanks.textToAttempt }) 

			// console.log("Attempting answer, similarity:", similarity, { answer: correctAnswer, attempted: attemptAnswer, returnData }) 


			matched = similarity > SIMILARITY_REQUIRED;
		}
		
		return { bad: !matched, good: !!matched, ...(returnData || {}) };
	}
}

AnswerManager.AnswerTypes = AnswerTypes;
