import React from 'react';
import styles from './VerseListCard.module.scss';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MoreMenu from 'components/MoreMenu';
import StatsBar from 'components/StatsBar';
import VerseBadgeLevel from 'components/VerseBadgeLevel';
import { ServerStore } from 'utils/ServerStore';

export default function VerseListCard({ verse, userCanEdit, onRemoveVerse, style, version }) {
	const match640 = useMediaQuery('(min-width:640px)');

	const openNativeChapter = () => {
		const url = verse.chapterUrl;
		ServerStore.metric('app.verse_card.read_chapter.clicked.native', null, {
			url
		});
		if (window.cordova &&
			window.cordova.InAppBrowser) {
			window.cordova.InAppBrowser.open(url, '_system');
		} else {
			window.open(url, '_system');
		}
	}, chapterOpenProps = window.isPhoneGap ? {
		onClick: openNativeChapter,
	} : { 
		component: 'a',
		rel: 'nofollow noreferrer',
		target: '_blank',
		href: verse.chapterUrl,
	};
	
	return (<>
		<Card
			style={style || {}}
			className={clsx(
				styles.root,
				match640 && styles.match640,
				verse.stats && styles.hasStats,
			)}
		>
			{userCanEdit ?
				<MoreMenu actions={[{
					...chapterOpenProps,
					text: <>Read Complete Chapter</>
				}, {
					onClick: onRemoveVerse,
					text: <>Remove Verse</>
				}]}/>
			: 
				<MoreMenu actions={[{
					...chapterOpenProps,
					text: <>Read Complete Chapter</>
				}]}/>
			}

			<VerseBadgeLevel 
				verse={verse}
				className={styles.circles}
			/>
			
			<CardContent>
				<Typography 
					variant="h6"
					component="h2"
					className={styles.ref}
				>
					{verse.ref || verse.cachedRef}
				</Typography>
				<Typography 
					variant="body2" 
					color="textSecondary" 
					component="p"
					className={styles.text}
				>
					{verse.text}
				</Typography>

				{verse.stats ? 
					<StatsBar stats={verse.stats}/>
				: ""}
			</CardContent>
		</Card>
	</>)
}