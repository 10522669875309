import { ServerStore } from "./ServerStore";
import { defer } from './defer';

export class GpsLogger {

	start = async (/*currentUser*/) => {
		if(this._started)
			return;
		this._started = true;

		console.log("[GpsLogger.start]");
		// Only pauses first boot (or if they never give consent, we never resolve)
		const consent = await this.getUserConsent();
		if(!consent)
			return;

		// Boot appros plugins once consent is given
		if(window.isPhoneGap && window.BackgroundGeolocation)
			await this.connectNativeLogger();
		else
			await this.connectBrowserLogger();
	}

	stop = async () => {
		if(!this._started)
			return;
		this._started = false;
		
		console.log("[GpsLogger.stop]");

		if(window.isPhoneGap && window.BackgroundGeolocation)
			this.stopWatchingNativeLocation();
		else
			this.stopWatchingBrowserLocation();
	}

	getUserConsent = async () => {
		const CONSENT_KEY = 'myverses-location-informed-consent';
		const consentStatus = window.localStorage.getItem(CONSENT_KEY);

		if(consentStatus === 'no') {
			return false;
		}

		if(consentStatus === 'yes') {
			return true;
		}

		await this.onPermissionNeeded();

		// onPermissionNeeded ONLY returns IF the user clicks Allow.
		// If they click NO, it never resolves.
		// So...we can safely set this to yes...
		window.localStorage.setItem(CONSENT_KEY, 'yes');
		return true;
	}

	// Used first time we need GPS to inform user as to WHY we want GPS
	onPermissionNeeded = async () => {
		// if(this.isDestroyed)
		// 	return;
			
		// The game could define this callback ...
		if(this.permissionNeededCallback &&
			await this.permissionNeededCallback()) {

			// Only return (allow request) if permissionNeededCallback returns true,
			// if returns a falsy value, dont return and delay via defer() (possibly forever)
			return;
		}

		if (this._permission) 
			return this._permission;

		this._permission = defer();

		// The getUserConsent() function will await this function,
		// so block until this deferred promise is resolved
		await this._permission;
	}

	// At some point, our game can check if permission is needed,
	// show a UI prompt, and if user agrees, then it will call permissionGiven() ...
	async ifPermissionNeeded(callback) {

		// We have not yet came online and tried to get permission, so store callback
		if(!this._permission && callback) {
			this.permissionNeededCallback = callback;
		}

		// We've already tried to get permission,
		// so execute callback and resolve deferred promise (if callback returns true)
		if(this._permission 
			&& callback 
			&& await callback()) {
			this.permissionGiven();
			return false;
		}

		return true;
	}

	// This will resolve the blocking promise given in onPermissionNeeded().
	// Firebase will be waiting on this promise to resolve before actually showing the native notify permission request
	permissionGiven() {
		this._permission.resolve();
		this._permission = null;
	}

	async connectNativeLogger() {
		const { BackgroundGeolocation } = window;
		if(!BackgroundGeolocation) {
			console.warn("[connectNativeLogger] BackgroundGeolocation not found on window, using browser logger instead, but won't work in background probably");
			return this.connectBrowserLogger();
		}

		const server = ServerStore.server(),
			deviceInfo = await ServerStore.deviceInfo(),
			sensor = `${deviceInfo.deviceClass}.${deviceInfo.appType}`,
			startPromise = defer();

		// Config below based on https://github.com/mauron85/cordova-plugin-background-geolocation#example
		// Options documented here https://github.com/mauron85/cordova-plugin-background-geolocation#api
		BackgroundGeolocation.configure({
			locationProvider:   BackgroundGeolocation.RAW_PROVIDER, //ACTIVITY_PROVIDER,
			desiredAccuracy:    BackgroundGeolocation.HIGH_ACCURACY,
			stationaryRadius:   1,
			distanceFilter:     2,
			startForeground:    false,
			// TODO: User our own icon here
			notificationTitle:  'MyVerses is keeping you safe',
			notificationText:   'You started a timer',
			notificationIconColor: '#FFFAFF',
			//MyVerses tracks your location and speed to see if you might be in danger and to log your location for your emergency contacts when sending an SOS message',
			debug:              false,
			interval:           1000,
			fastestInterval:    500,
			activitiesInterval: 1000,
			startOnBoot:        true,
			stopOnTerminate:    false,
			url:                server.urlRoot + '/myverses/store_gps_location',
			syncUrl:            server.urlRoot + '/myverses/store_gps_location',
			syncThreshold:      1,
			httpHeaders: {
				// We should have token by now because
				// connectNativeLogger() only called by LOGIN_EVENT
				'Authorization': server.token,
			},
			// customize post properties
			postTemplate: {
				lat:      '@latitude',
				lng:      '@longitude',
				accuracy: '@accuracy',
				// Added to attempt server-side walk/run/drive detection
				speed:    '@speed',
				altitude: '@altitude',
				bearing:  '@bearing',
				// built above from deviceInfo()
				sensor
			}
		});

		BackgroundGeolocation.checkStatus(function(status) {
			console.log('[INFO] BackgroundGeolocation service is running', status.isRunning);
			console.log('[INFO] BackgroundGeolocation services enabled', status.locationServicesEnabled);
			console.log('[INFO] BackgroundGeolocation auth status: ' + status.authorization);

			// you don't need to check status before start (this is just the example)
			if (!status.isRunning) {
				BackgroundGeolocation.start(); //triggers start on start event
			}

			BackgroundGeolocation.getCurrentLocation(async location => {

				// Store first location
				const { latitude: lat, longitude: lng, accuracy, speed } = location;
				await ServerStore.StoreGpsLocation({ lat, lng, accuracy, speed });

				startPromise.resolve({ location });
			}, error => {
				startPromise.resolve({ error });
			})
		});

		BackgroundGeolocation.on('error', error => {
			console.error('[GpsLogger] Error from native plugin:', error);
		});

		BackgroundGeolocation.on('location', location => {
			// Not needed here, but DOES WORK - the post URL above takes care of things

			// // handle your locations here
			// // to perform long running operation on iOS
			// // you need to create background task
			// BackgroundGeolocation.startTask(async taskKey => {

			// 	console.log('[GpsLogger] Received location from native plugin, posting to server:', location);

			// 	// execute long running task
			// 	// eg. ajax post location
			// 	const { latitude: lat, longitude: lng, accuracy, speed } = location;
			// 	await ServerStore.StoreGpsLocation({ lat, lng, accuracy, speed });

			// 	// IMPORTANT: task has to be ended by endTask
			// 	BackgroundGeolocation.endTask(taskKey);
			// });
		});

		// Activity unused atm
		// BackgroundGeolocation.on('activity', activity => {
		// 	// handle your locations here
		// 	// to perform long running operation on iOS
		// 	// you need to create background task
		// 	BackgroundGeolocation.startTask(async taskKey => {

		// 		console.log('[GpsLogger] Received activity from native plugin:', activity);

		// 		// // execute long running task
		// 		// // eg. ajax post location
		// 		// const { latitude: lat, longitude: lng, accuracy } = location;
		// 		// await ServerStore.StoreGpsLocation({ lat, lng, accuracy });

		// 		// IMPORTANT: task has to be ended by endTask
		// 		BackgroundGeolocation.endTask(taskKey);
		// 	});
		// });

		return startPromise;

	}

	async stopWatchingNativeLocation() {
		const { BackgroundGeolocation }  = window;
		if(!BackgroundGeolocation) {
			return this.stopWatchingBrowserLocation();
		}

		BackgroundGeolocation.stop();
	}

	async connectBrowserLogger() {
		this._startPromise = defer();
		if(window.location.protocol !== "https:") {
			console.warn("[GpsLogger.connectBrowserLogger] Not HTTPS, not geolocating");
			return { error: "Not HTTPS" };
		}
		
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.startWatchingBrowserLocation, this.browserLocationError);
		} else {
			if(window.location.protocol === "https:")
				console.error("[GpsLogger.connectBrowserLogger] Running HTTPS but your browser does not appear support Geolocation, won't log current position");
			else
				console.warn("[GpsLogger.connectBrowserLogger] Not running HTTPS and didn't get the geolocation API, so geolocation won't work");
		}

		// Set a failsafe timeout on the promise so we return quickly
		setTimeout(() => {
			if (this._startPromise) {
				this._startPromise.resolve();
				this._startPromise = null;
			}
		}, 200);

		return this._startPromise;
	}

	startWatchingBrowserLocation = (position) => {
		this.browserLocationReceived(position);
		this.watchTid = navigator.geolocation.watchPosition(
			this.browserLocationReceived,
			this.browserLocationError,
			{ enableHighAccuracy: true, maximumAge: 1000 }
		);
	}

	stopWatchingBrowserLocation() {
		if (this.watchTid) {
			navigator.geolocation.clearWatch(this.watchTid);
			this.watchTid = null;			
		}
	}

	browserLocationReceived = (position) => {
		const [ lat, lng, accuracy ] = [
			position.coords.latitude,
			position.coords.longitude,
			position.coords.accuracy,
		];

		if(this._startPromise) {
			console.log("[GpsLogger.browserLocationReceived]", { lat, lng, accuracy });			
			ServerStore.StoreGpsLocation({ lat, lng, accuracy });
			this._startPromise.resolve({ location: { lat, lng, accuracy }});
			this._startPromise = null;
		} else {
			
			// debounce updates to server to approx ever 1/4th second
			clearTimeout(this._logTid);
			this._logTid = setTimeout( async () => {
				console.log("[GpsLogger.browserLocationReceived]", { lat, lng, accuracy });			
				ServerStore.StoreGpsLocation({ lat, lng, accuracy });
			}, 250);
		}
	}

	browserLocationError = (error) => {
		console.warn("[GpsLogger.browserLocationError]", error);
		if (this._startPromise) {
			this._startPromise.resolve({ error });
			this._startPromise = null;
		}
	}
}

// Start the logger
GpsLogger.instance = new GpsLogger();