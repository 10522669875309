const list = `
	Romans 6:23	For the wages of sin is death, but the gift of God is eternal life in Christ Jesus our Lord.
	Romans 3:23	For all have sinned and fall short of the glory of God.
	John 3:16-17	For God so loved the world that He gave His one and only Son, that whoever believes in Him shall not perish but have eternal life. For God did not send His Son into the world to condemn the world, but to save the world through Him.
	Genesis 1:1	In the beginning God created the heavens and the earth.
	Revelation 3:20	Here I am! I stand at the door and knock. If anyone hears my voice and opens the door, I will come in and eat with him, and he with me.
	John 14:6	Jesus answered, “I am the way and the truth and the life. No one comes to the father except through me.”
	Ephesians 2:8-9	For it is by grace you have been saved, through faith—and this not from yourselves, it is the gift of God—not by works, so that no one can boast.
	2 Corinthians 5:17	Therefore, if anyone is in Christ he is a new creation; the old has gone, the new has come!
	Romans 8:28	And we know that in all things God works for the good of those who love him, who have been called according to his purpose.
	Isaiah 40:30-31	Even youths grow tired and weary, and young men stumble and fall; but those who hope in the Lord will renew their strength. They will soar on wings like eagles; they will run and not grow weary, they will walk and not be faint.
	Romans 8:38-39	For I am convinced that neither death nor life, neither angels nor demons, neither the present nor the future, nor any powers, neither height nor depth, nor anything else in all creation, will be able to separate us from the love of God that is in Christ Jesus our Lord.
	Matthew 11:28-30	Come to me, all you who are weary and burdened, and I will give you rest. Take my yoke upon you and learn from me, for I am gentle and humble in heart ,and you will find rest for your souls. For my yoke is easy and my burden is light.
	Psalm 27:1	The Lord is my light and my salvation—whom shall I fear? The Lord is the stronghold of my life—of whom shall I be afraid?
	Jeremiah 29:11	“For I know the plans I have for you,” declares the Lord, “plans to prosper you and not to harm you, plans to give you hope and a future.”
	Hebrews 13:8	Jesus Christ is the same yesterday and today and forever.
	2 Peter 3:9	The Lord is not slow in keeping His promise, as some understand slowness. He is patient with you, not wanting anyone to perish, but everyone to come to repentance.
	Lamentations 3:22-23	Because of the Lord’s great love we are not consumed, for his compassions never fail. They are new every morning; great is your faithfulness.
	2 Corinthians 12:9	But he said to me, “My grace is sufficient for you, for my power is made perfect in weakness.” Therefore I will boast all the more gladly about my weaknesses, so that Christ’s power may rest on me.
	2 Corinthians 4:18	So we fix our eyes not on what is seen, but on what is unseen. For what is seen is temporary, but what is unseen is eternal.
	Psalm 37:4-5	Delight yourself in the Lord and he will give you the desires of your heart. Commit your way to the Lord, trust in him and he will do this.
	Proverbs 3:5-6	Trust in the Lord with all your heart and lean not on your own understanding; in all your ways acknowledge him, and he will make your paths straight.
	Philippians 4:13	I can do all things through Christ who strengthens me.
	Galatians 2:20	I have been crucified with Christ and I no longer live, but Christ lives in me. The life I live in the body, I live by faith in the Son of God, who loved me and gave himself for me.
	James 1:22	Do not merely listen to the word, and so deceive yourselves. Do what it says.
	Colossians 3:23	Whatever you do, work at it with all your heart, as working for the Lord, not for men.
	1 Corinthians 15:58	Therefore, my dear brothers, stand firm. Let nothing move you. Always give yourselves fully to the work of the Lord, because you know that your labor in the Lord is not in vain.
	James 4:7	Submit yourselves, then, to God. Resist the devil, and he will flee from you.
	Luke 16:13	No servant can serve two masters. Either he will hate the one and love the other, or he will be devoted to the one and despise the other. You cannot serve both God and Money.
	1 John 4:7-8	Dear friends, let us love one another, for love comes from God. Everyone who loves has been born of God and knows God. Whoever does not love does not know God, because God is love.
	Galatians 5:22-23	But the fruit of the Spirit is love, joy, peace, patience, kindness, goodness, faithfulness, gentleness and self-control. Against such things there is no law.
	Hebrews 12:1-2	Therefore, since we are surrounded by such a great cloud of witnesses, let us throw off everything that hinders and the sin that so easily entangles, and let us run with perseverance the race marked out for us. Let us fix our eyes on Jesus, the author and perfecter of our faith, who for the joy set before Him endured the cross, scorning its shame, and sat down at the right hand of the throne of God.
	Philippians 4:6,7	Do not be anxious about anything, but in everything, by prayer and petition, with thanksgiving, present your requests to God. And the peace of God, which transcends all understanding, will guard your hearts and your minds in Christ Jesus.
	Acts 1:8	But you will receive power when the Holy Spirit comes on you; and you will be my witnesses in Jerusalem, and in all Judea and Samaria, and to the ends of the earth.
	Romans 12:1-2	Therefore, I urge you, brothers, in view of God’s mercy, to offer your bodies as living sacrifices, holy and pleasing to God—this is your spiritual act of worship. Do not conform any longer to the pattern of this world, but be transformed by the renewing of your mind. Then you will be able to test and approve what God’s will is—his good, pleasing and perfect will.
	1 Thessalonians 5:18	Give thanks in all circumstances, for this is God’s will for you in Christ Jesus.
	Psalm 19:14	May the words of my mouth and the meditation of my heart be pleasing in your sight, O Lord, my Rock and my Redeemer.
	2 Timothy 3:16	All Scripture is God-breathed and is useful for teaching, rebuking, correcting, and training in righteousness.
	Psalm 119:105	Your word is a lamp to my feet and a light for my path.
	Psalm 119:11	I have hidden your word in my heart that I might not sin against you.
	Hebrews 4:16	Let us then approach the throne of grace with confidence, so that we may receive mercy and find grace to help us in our time of need.
	1 John 1:9	If we confess our sins, he is faithful and just and will forgive us our sins and purify us from all unrighteousness.
	James 5:16	Therefore confess your sins to each other and pray for each other so that you may be healed. The prayer of a righteous man is powerful and effective.
	1 Corinthians 10:13	No temptation has seized you except what is common to man. And God is faithful; he will not let you be tempted beyond what you can bear. But when you are tempted, he will also provide a way out, so that you can stand up under it.
	Micah 6:8	He has showed you, O man, what is good. And what does the Lord require of you? To act justly and to love mercy and to walk humbly with your God.
	Matthew 25:40	The King will reply, “I tell you the truth, whatever you did for one of the least of these brothers of mine, you did for me.”
	Matthew 28:19-20	Therefore go and make disciples of all nations, baptizing them in the name of the Father and of the Son and of the Holy Spirit, and teaching them to obey everything I have commanded you. And surely I am with you always, to the very end of the age.
	Matthew 5:16	In the same way, let your light shine before men, that they may see your good deeds and praise your Father in heaven.
	Ephesians 6:12	For our struggle is not against flesh and blood, but against the rulers, against the authorities, against the powers of this dark world and against the spiritual forces of evil in the heavenly realms.
`.split(/\n/)
	.map(x => x.trim())
	.filter(x => x)
	.map(x => x.split(/\t+/))
	.map(([cachedRef, text]) => {
		return {
			cachedRef: cachedRef.trim(),
			text: text.trim()
		}
	})
	.filter(x => x.cachedRef && x.text);

export default list;